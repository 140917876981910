import { catchError } from 'rxjs/operators';
import { WebClientUtil } from '../../util/WebClientUtil';
import { API_ENDPOINT } from '../../../../config';
import { Observable, of } from 'rxjs';
import { ToolService } from './ToolService';
export class ToolServiceImpl implements ToolService {
  private baseUrl = API_ENDPOINT.versionTrackingURL;
  createToolOnGroup(body: any): Observable<any> {
    const url = `${this.baseUrl}/tool/addToolWithGroupId`;
    return WebClientUtil.postObject(url, body);
  }
  getAllTools(): Observable<any> {
    const url = `${this.baseUrl}/tool`;
    return WebClientUtil.getObject(url);
  }

  getOneTool(id: string) {
    const url = `${this.baseUrl}/tool/${id}`;
    return WebClientUtil.getObject(url);
  }
  updateTool(id: string, newModel: any) {
    const url = `${this.baseUrl}/tool/${id}`;
    return WebClientUtil.putObject(url, newModel);
  }
  getAllToolsByGroupId(id: string): Observable<any> {
    const url = `${this.baseUrl}/tool/group/${id}`;
    return WebClientUtil.getObject(url);
  }
  deleteTool(id: string): Observable<any> {
    const url = `${this.baseUrl}/tool/${id}`;
    return WebClientUtil.deleteObject(url);
  }
  createOrGetExistingToolOnGroup(body: any): Observable<any> {
    const url = `${this.baseUrl}/tool/addOrGetExistingToolWithGroupId`;
    return WebClientUtil.postObject(url, body);
  }
  searchTool(body: any): Observable<any> {
    const url = `${this.baseUrl}/tool/searchTool/name`;
    return WebClientUtil.postObject(url, body);
  }
  getToolWidthProductionVersion(): Observable<any> {
    const url = `${this.baseUrl}/tool/getToolWidthProductionVersion/all`;
    return WebClientUtil.getObject(url);
  }
  getToolProductionRDVNVersion(): Observable<any> {
    const url = `${this.baseUrl}/tool/getToolProductionRDVNVersion/all`;
    return WebClientUtil.getObject(url);
  }
  checkIfToolExistInGroup(body: any): Observable<any> {
    const url = `${this.baseUrl}/tool/search/checkIfToolExistInGroup`;
    return WebClientUtil.postObject(url, body);
  }
  checkIfToolNameExistInTool(body: any): Observable<any> {
    const url = `${this.baseUrl}/tool/searchToolName/checkIfToolNameExistInTool`;
    return WebClientUtil.postObject(url, body);
  }
  checkIfFWExistInTool(body: any): Observable<any> {
    const url = `${this.baseUrl}/tool/searchFW/checkIfFWExistInTool`;
    return WebClientUtil.postObject(url, body);
  }
}
