import { createMuiTheme } from '@material-ui/core';
import { responsiveFontSizes } from '@material-ui/core/styles';
import palette from './palette';
import typography from './typography';
import overrides from './overrides';

let theme = createMuiTheme({
  props: {
    MuiButtonBase: {
      // The properties to apply
      // disableRipple: true, // No more ripple, on the whole application 💣!
    },
  },
  palette,
  typography,
  overrides,
});
theme = responsiveFontSizes(theme);
export default theme;
