import React from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import List from '@material-ui/core/List';
import { NavLink } from 'react-router-dom';
import { serviceFactory } from '../config/ServiceFactory';
import * as toolActions from '../../../redux/actions/toolActions';
import * as groupActions from '../../../redux/actions/groupActions';
import { connect } from 'react-redux';
import { setError } from '../util/alert.util';
import equal from 'fast-deep-equal';
import './HorizontalNavbar.scss';

class HorizontalNav extends React.Component<any, any> {
  constructor(props) {
    super(props);
    const { navBarData, group, tool } = this.props;
    this.state = {
      resultData: (navBarData.data && navBarData.data.data) || [],
      tool: tool || {},
      group: group || {},
      value: null,
      setAnchorEl: null,
      anchorEl: null,
    };
    this.getParamsInfo();
  }
  static defaultProps = {
    group: {},
    tool: {},
    navBarData: {},
    value: {},
  };
  private readonly groupService = serviceFactory.getGroupService();
  private readonly toolService = serviceFactory.getToolService();
  getParamsInfo = () => {
    const { match } = this.props;
    if (
      (match && match.path === '/version-tracking/tool/:id') ||
      match.path === '/input-version-tracking/tool/:id'
    ) {
      const id = match.params.id;
      this.toolService.getOneTool(id).subscribe((res) => {
        if (res.response && res.response.data.error) {
          // @todo: re-verify server error status again
          setError(res, this.setState);
        } else {
          const tool = res.data.data;
          this.props.dispatch(
            toolActions.selectTool({
              ...tool,
              title: tool.toolName,
              id: tool._id,
            })
          );
        }
      });
    } else if (match && match.path === '/version-tracking/group/:id') {
      const id = match.params.id;
      this.groupService.getOneGroupTool(id).subscribe((res) => {
        if (res.response && res.response.data.error) {
          // @todo: re-verify server error status again
          setError(res, this.setState);
        } else {
          const group = res.data.data;
          this.props.dispatch(
            groupActions.selectGroup({
              ...group,
              title: group.groupName,
              id: group._id,
            })
          );
        }
      });
    }
  };
  componentDidUpdate(prevProps) {
    if (!equal(this.props.navBarData, prevProps.navBarData)) {
      this.componentDidMount();
    }
    if (!equal(this.props.tool, prevProps.tool)) {
      this.componentDidMount();
    }
    if (!equal(this.props.group, prevProps.group)) {
      this.componentDidMount();
    }
  }

  componentDidMount() {
    this.groupService.getAllGroupWithTool().subscribe((res) => {
      if (res.status !== 200 && res.status !== 201) {
        // setError(res, this.setState);
      } else {
        this.setState({ resultData: res.data.data });
      }
    });
  }

  handleChange = (event, value) => {
    event.stopPropagation();
    const { history } = this.props;
    if (value) {
      if (value.isGroup) {
        history.push(`/version-tracking/group/${value.id}`);
        this.props.dispatch(groupActions.selectGroup(value));
      } else {
        history.push(`/version-tracking/tool/${value.id}`);
        this.props.dispatch(toolActions.selectTool(value));
      }
    }
  };

  // handleFilter = (options: any, state: any) => {
  //   return options;
  // };

  render = () => {
    const { match, group, tool, location } = this.props;
    const data = localStorage.getItem('userInfo');
    const userInfo = JSON.parse(data);

    return (
      <nav
        className='custom-navbar'
        style={{
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <div
          style={{
            display: 'flex',
          }}
        >
          <NavLink
            isActive={(match1, location1) => {
              return location1.pathname.startsWith('/release-schedule');
            }}
            activeClassName='nav-link-active'
            className='nav-link'
            to='/release-schedule'
          >
            Release Tracking
          </NavLink>

          {userInfo?.role === 'Admin_Role' && (
            <NavLink
              isActive={(match1, location1) => {
                return location1.pathname.startsWith('/group/');
              }}
              activeClassName={'nav-link-active'}
              className='nav-link'
              to='/group/add-newgroup'
            >
              Groups
            </NavLink>
          )}

          {userInfo?.role === 'Admin_Role' && (
            <NavLink
              isActive={(match1, location1) => {
                return location1.pathname.startsWith('/tool');
              }}
              activeClassName='nav-link-active'
              className='nav-link'
              to='/tool/add-newtool'
            >
              Tools
            </NavLink>
          )}
          {userInfo?.role === 'Admin_Role' && (
            <NavLink
              isActive={(match1, location1) => {
                return location1.pathname.startsWith('/role');
              }}
              activeClassName='nav-link-active'
              className='nav-link'
              to='/role/add-newrole'
            >
              User Roles
            </NavLink>
          )}
          {userInfo?.role === 'Admin_Role' && (
            <NavLink
              isActive={(match1, location1) => {
                return location1.pathname.startsWith('/groupUser');
              }}
              activeClassName='nav-link-active'
              className='nav-link'
              to='/groupUser/add-newgroupuser'
            >
              User Groups & User Roles
            </NavLink>
          )}
          {userInfo?.role === 'Admin_Role' && (
            <NavLink
              isActive={(match1, location1) => {
                return location1.pathname.startsWith('/user');
              }}
              activeClassName='nav-link-active'
              className='nav-link'
              to='/user/addonGroupUser'
            >
              User Groups & Users
            </NavLink>
          )}
          <NavLink
            isActive={(match1, location1) => {
              return location1.pathname.startsWith('/componentTool');
            }}
            activeClassName='nav-link-active'
            className='nav-link'
            to='/componentTool'
          >
            Tool Component
          </NavLink>
          <NavLink
            isActive={(match1, location1) => {
              return location1.pathname.startsWith('/mastercomponent');
            }}
            activeClassName='nav-link-active'
            className='nav-link'
            to='/mastercomponent'
          >
            Master Component
          </NavLink>
        </div>
        <List
          disablePadding
          style={{
            padding: '8px 16px',
          }}
          className='custom-linkitem'
          component='nav'
        >
          <Autocomplete
            value={
              (match &&
                match.path === '/version-tracking/group/:id' &&
                this.props.group) ||
              this.props.tool
            }
            className='custom-autocomplete'
            onChange={this.handleChange}
            style={{ maxWidth: 300, minWidth: 300 }}
            options={flattenOptions(this.state.resultData)}
            getOptionLabel={(option) => option.title}
            renderOption={(option) => (
              <React.Fragment>
                <span className={option.isStyle}>
                  {option.isGroup ? '' : '---'}
                  {option.title}
                </span>
              </React.Fragment>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                label='Please select a Group/Tool'
                variant='outlined'
                fullWidth
                inputProps={{
                  ...params.inputProps,
                  autoComplete: 'off',
                }}
                margin='dense'
                InputLabelProps={{ shrink: true }}
              />
            )}
          />
        </List>
      </nav>
    );
  };
}

const flattenOptions = (options: any) => {
  let list = [];
  for (const option of options) {
    if (option.groupName) {
      list.push({
        title: option.groupName,
        id: option._id,
        isGroup: true,
        isStyle: 'nameGroup',
      });
      list = [...list, ...flattenOptions(option.tools)];
    } else if (option.toolName) {
      list.push({
        title: option.toolName,
        id: option._id,
        isGroup: false,
        isStyle: 'nameTool',
      });
    }
  }
  return list;
};
const mapStateToProps = (state, ownProps) => {
  // state is from store
  return {
    navBarData: state.navBar, // this.props.navBarData
    tool: state.tool.currentTool,
    group: state.group.currentGroup,
  };
};
export default connect(mapStateToProps)(HorizontalNav);
