const defaultState = {
  currentToolIds: [],
  currentGroup: null
}

export default function groupReducer(state = defaultState, action) {
  switch (action.type) {
    case 'SELECT_GROUP_FROM_SEARCH_BAR':
      return { ...state, currentGroup: action.group };
    case 'STORE_ROLE_BY_TOOL_IDS':
      return {...state, currentToolIds: action.toolIds };
    default:
      return state;
  }
}
