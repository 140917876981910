import { serviceFactory } from '../../components/common/config/ServiceFactory';

export function reloadSearchToolBar(data) {
  return {
    type: 'RELOAD_SEARCH_TOOLBAR',
    data,
  };
}

export function fetchGroupAndToolData() {
  const groupService = serviceFactory.getGroupService();
  return dispatch => {
    return groupService
      .getAllGroupWithTool()
      .toPromise()
      .then(res => {
        dispatch(reloadSearchToolBar(res));
      })
      .catch(error => {
        throw error;
      });
  };
}

export function fetchGroupGroupNameData() {
  const groupService = serviceFactory.getGroupService();
  return dispatch => {
    return groupService
      .getAllGroupTool()
      .toPromise()
      .then(res => {
        dispatch(reloadSearchToolBar(res));
      })
      .catch(error => {
        throw error;
      });
  };
}
