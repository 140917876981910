import { catchError } from 'rxjs/operators';
import { WebClientUtil } from '../../util/WebClientUtil';
import { API_ENDPOINT } from '../../../../config';
import { Observable, of } from 'rxjs';
import { MakeService } from './MakeService';
export class MakeServiceImpl implements MakeService {
  private baseUrl = API_ENDPOINT.versionTrackingURL;
  getallMake(): Observable<any> {
    const url = `${this.baseUrl}/make`;
    return WebClientUtil.getObject(url);
  }
  checkIfMakeExist(body: any): Observable<any> {
    const url = `${this.baseUrl}/make/searchMake/checkIfMakeExist`;
    return WebClientUtil.postObject(url, body);
  }
}
