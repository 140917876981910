import React from 'react';
import MUIDataTable from 'mui-datatables';
import { Link } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import { TableRow, TableCell } from '@material-ui/core';
import './toolComponent.scss';
import { serviceFactory } from '../../common/config/ServiceFactory';
import { ToolComponentService } from '../../common/service/toolComponent/ToolComponentService';
import * as toolActions from '../../../redux/actions/toolActions';
import * as groupActions from '../../../redux/actions/groupActions';
import { connect } from 'react-redux';
import EditIcon from '@material-ui/icons/Edit';
import DelIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import { Button, CircularProgress } from '@material-ui/core';
import ResultInfoDialog from '../../common/component/Dialog/ResultInfoDialog';
import AlertDialog from '../../common/component/Dialog/AlertDialog';
class FunctionToolComp extends React.Component<any, any> {
  tableRef = React.createRef();
  constructor(props) {
    super(props);
    this.toolComponentService = serviceFactory.getToolComponentService();
    this.state = {
      toolcomp: [],
      openConfirmDeleteToolComp: false,
      openResultDialog: false,
      resultInfo: '',
      isLoading: true,
      currentPage: 0,
    };
  }
  rowPerPage = 10;
  private toolComponentService: ToolComponentService;
  private idToolComp = undefined;
  private arrayIdToolComp: string[] = undefined;
  handleEditToolComp(idToolComp) {
    const { history } = this.props;
    history.push(`/componentTool/update-toolComponent/${idToolComp}`);
  }
  handleCloseConfirm = () => {
    this.setState({
      openConfirmDeleteToolComp: false,
    });
  };
  handleCloseResultDialog = () => {
    this.setState(
      {
        openResultDialog: false,
      },
      () => {
        window.location.reload();
      }
    );
  };
  handleDeleteToolComp = (arrayIdToolComp) => {
    this.arrayIdToolComp = arrayIdToolComp;
    this.setState({
      openConfirmDeleteToolComp: true,
    });
  };
  handleAcceptDeleleteToolComp = () => {
    this.setState(
      {
        openConfirmDeleteToolComp: false,
      },
      () => {
        this.toolComponentService
          .deleteMultipleToolComp(this.arrayIdToolComp)
          .subscribe(async (res) => {
            if (res.response && res.response.data.error) {
              this.setState({ error: res.response.data.error });
              console.error(res.response.data.error);
              // window.alert(res.response.data.error);
              this.setState({
                openResultDialog: true,
                isDeleteToolCompSuccess: false,
                resultInfo: `Can not delete this Tool Component:\n${res.response.data.error}`,
              });
            } else {
              // this.props.onDeleteSuccess();
              this.arrayIdToolComp = undefined;
              this.setState({
                openResultDialog: true,
                isDeleteToolCompSuccess: true,
                resultInfo: `Delete Tool Component successful`,
              });
              // await this.componentDidMount();
            }
          });
      }
    );
  };
  async componentDidMount() {
    this.props.dispatch(toolActions.selectTool(null));
    this.props.dispatch(groupActions.selectGroup(null));
    this.toolComponentService.getallToolComp().subscribe((res1) => {
      this.setState({
        toolcomp: res1.data.data,
        isLoading: false,
      });
    });
  }
  calulateSelectedRow = () => {
    const toolName =
      (this.props.history.location.state &&
        this.props.history.location.state.toolName) ||
      '';
    if (this.state.toolcomp.length > 0 && toolName) {
      const index = this.state.toolcomp.findIndex(
        (item) => item.tools === toolName
      );
      if (index < 0) {
        return [];
      } else {
        return [index];
      }
    }
    return [];
  };

  changePage = (pageNumber) => {
    if (this.tableRef.current) {
      //@ts-ignore
      this.tableRef.current.changePage(pageNumber);
    }
  };

  calculatePageIndex = () => {
    let pageIndex = 0;
    const toolName =
      (this.props.history.location.state &&
        this.props.history.location.state.toolName) ||
      '';
    if (this.state.toolcomp.length > 0 && toolName) {
      const index = this.state.toolcomp.findIndex(
        (item) => item.tools === toolName
      );
      const page = index / this.rowPerPage;
      pageIndex = Math.floor(page);
    }
    return pageIndex;
  };

  render() {
    const data = localStorage.getItem('userInfo');
    const userInfo = JSON.parse(data);

    let pageIndex = this.calculatePageIndex();

    this.changePage(pageIndex);

    const options = {
      page: pageIndex,
      rowsPerPage: this.rowPerPage,
      rowsPerPageOptions: [10, 20, 50, 100],
      rowsSelected: this.calulateSelectedRow(),
      viewColumns: false, // close viewcolumns
      print: false, // close print
      filterType: 'dropdown',
      responsive: 'scrollMaxHeight',
      elevation: 4,
      caseSensitive: false,
      fixedHeaderOptions: {
        xAxis: false,
        yAxis: true,
      },
      selectableRowsHeader: true,
      selectableRows: 'multiple',
      disableToolbarSelect: (() => {
        let htmlContent;
        if (userInfo?.role === 'Admin_Role') {
          // just admin has been right edit, delete
          htmlContent = false;
        } else {
          htmlContent = true; // remove ToolBar // view
        }

        return htmlContent;
      })(),
      customToolbarSelect: (selectedRows, displayData, setSelectedRows) => {
        if (displayData.length < 1) {
          return null;
        }
        const currentIndex = selectedRows.data[0].index;
        const idToolComp =
          displayData[currentIndex].data[10].props.children.props.children;
        const currentIndexArr = selectedRows.data.map((item) => item.index);
        const idToolCompArr = currentIndexArr.map(
          (index) => displayData[index].data[10].props.children.props.children
        );
        return (
          <div className='btn-group'>
            {userInfo?.role === 'Admin_Role' && (
              <Tooltip title={'Edit'}>
                <IconButton onClick={() => this.handleEditToolComp(idToolComp)}>
                  <EditIcon style={{ color: '#fb8c00' }} />
                </IconButton>
              </Tooltip>
            )}
            {userInfo?.role === 'Admin_Role' && (
              <Tooltip title={'Delete'}>
                <IconButton
                  onClick={() => this.handleDeleteToolComp(idToolCompArr)}
                >
                  <DelIcon style={{ color: '#d93025' }} />
                </IconButton>
              </Tooltip>
            )}
          </div>
        );
      },
      // downloadOptions: { filename: 'toolcomponent.csv', separator: ',' },
    };
    const MapDataToDataTableTool = (data1) => {
      return data1.map((item) => {
        return [
          item.tools,
          item.PCB,
          item.RDProjects,
          item.ASIC,
          item.CPU,
          item.MRP_CPU,
          item.Memory,
          item.MRP_Memory,
          item.notes,
          new Date(item.date).toLocaleDateString('en-US'),
          item._id,
        ];
      });
    };
    const columns = [
      // Tools
      {
        name: 'tools',
        label: 'Tools',
        options: {
          filter: true,
          customHeadRender: (columnMeta, updateDirection) => (
            <th className='custom-header-tablehead' key={columnMeta.name}>
              <TableRow className='custom-header-tablerow'>
                <TableCell
                  style={{ height: 114 }}
                  className='custom-header-tablecell'
                >
                  Tools
                </TableCell>
              </TableRow>
            </th>
          ),
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <TableRow className='custom-body-tablerow'>
                <TableCell className='custom-body-tablecell'>
                  {tableMeta.rowData[0]}
                </TableCell>
              </TableRow>
            );
          },
        },
      },
      // PCB
      {
        name: 'PCB',
        label: 'PCB',
        options: {
          filter: false,
          customHeadRender: (columnMeta, updateDirection) => (
            <th className='custom-header-tablehead' key={columnMeta.name}>
              <TableRow className='custom-header-tablerow'>
                <TableCell
                  style={{ height: 114 }}
                  className='custom-header-tablecell'
                >
                  PCB
                </TableCell>
              </TableRow>
            </th>
          ),
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <TableRow className='custom-body-tablerow'>
                <TableCell className='custom-body-tablecell'>
                  {tableMeta.rowData[1]}
                </TableCell>
              </TableRow>
            );
          },
        },
      },
      // R&D Projects
      {
        name: 'RDProjects',
        label: 'R&D Projects',
        options: {
          filter: false,
          customHeadRender: (columnMeta, updateDirection) => (
            <th className='custom-header-tablehead' key={columnMeta.name}>
              <TableRow className='custom-header-tablerow'>
                <TableCell
                  style={{ height: 114 }}
                  className='custom-header-tablecell'
                >
                  R&D Projects
                </TableCell>
              </TableRow>
            </th>
          ),
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <TableRow className='custom-body-tablerow'>
                <TableCell className='custom-body-tablecell'>
                  {tableMeta.rowData[2]}
                </TableCell>
              </TableRow>
            );
          },
        },
      },
      // Components
      // ASIC
      {
        name: 'ASIC',
        label: 'ASIC',
        options: {
          filter: false,
          customHeadRender: (columnMeta, updateDirection) => (
            <th className='custom-header-tablehead' key={columnMeta.name}>
              <TableRow className='custom-header-tablerow'>
                <TableCell className='custom-header-tablecell' colSpan={6}>
                  Components
                </TableCell>
              </TableRow>
              <TableRow className='custom-header-tablerow'>
                <TableCell className='custom-header-tablecell merged-cell-header'>
                  ASIC
                </TableCell>
                <TableCell className='custom-header-tablecell merged-cell-header'>
                  CPU
                </TableCell>
                <TableCell className='custom-header-tablecell merged-cell-header'>
                  MRP#
                </TableCell>
                <TableCell className='custom-header-tablecell merged-cell-header'>
                  Memory
                </TableCell>
                <TableCell className='custom-header-tablecell merged-cell-header'>
                  MRP#
                </TableCell>
                <TableCell className='custom-header-tablecell merged-cell-header notes-custom-cell-header'>
                  Notes
                </TableCell>
              </TableRow>
            </th>
          ),
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <TableRow className='custom-body-tablerow merged-row-body'>
                <TableCell className='custom-body-tablecell merged-cell-body'>
                  {tableMeta.rowData[3]}
                </TableCell>
                <TableCell className='custom-body-tablecell merged-cell-body'>
                  {tableMeta.rowData[4]}
                </TableCell>
                <TableCell className='custom-body-tablecell merged-cell-body'>
                  {tableMeta.rowData[5]}
                </TableCell>
                <TableCell className='custom-body-tablecell merged-cell-body'>
                  {tableMeta.rowData[6]}
                </TableCell>
                <TableCell className='custom-body-tablecell merged-cell-body'>
                  {tableMeta.rowData[7]}
                </TableCell>
                <TableCell className='custom-body-tablecell merged-cell-body notes-custom-cell-body'>
                  {tableMeta.rowData[8]}
                </TableCell>
              </TableRow>
            );
          },
        },
      },
      // CPU
      {
        name: 'CPU',
        label: 'CPU',
        options: {
          display: 'false',
          filter: false,
          customHeadRender: (columnMeta, updateDirection) => null,
          customBodyRender: (value, tableMeta, updateValue) =>
            tableMeta.rowData[4],
        },
      },
      // MRP_CPU
      {
        name: 'MRP_CPU',
        label: 'MRP#',
        options: {
          display: 'false',
          filter: false,
          customHeadRender: (columnMeta, updateDirection) => null,
          customBodyRender: (value, tableMeta, updateValue) =>
            tableMeta.rowData[5],
        },
      },
      // Memory
      {
        name: 'Memory',
        label: 'Memory',
        options: {
          display: 'false',
          filter: false,
          customHeadRender: (columnMeta, updateDirection) => null,
          customBodyRender: (value, tableMeta, updateValue) =>
            tableMeta.rowData[6],
        },
      },
      // MRP_Memory
      {
        name: 'MRP_Memory',
        label: 'MRP#',
        options: {
          display: 'false',
          filter: false,
          customHeadRender: (columnMeta, updateDirection) => null,
          customBodyRender: (value, tableMeta, updateValue) =>
            tableMeta.rowData[7],
        },
      },
      // Notes
      {
        name: 'Notes',
        label: 'Notes',
        options: {
          display: 'false',
          filter: false,
          customHeadRender: (columnMeta, updateDirection) => null,
          customBodyRender: (value, tableMeta, updateValue) =>
            tableMeta.rowData[8],
        },
      },
      // Date
      {
        name: 'date',
        label: 'Date',
        options: {
          filter: false,
          customHeadRender: (columnMeta, updateDirection) => (
            <th className='custom-header-tablehead' key={columnMeta.name}>
              <TableRow className='custom-header-tablerow'>
                <TableCell
                  style={{ height: 114 }}
                  className='custom-header-tablecell'
                >
                  Date
                </TableCell>
              </TableRow>
            </th>
          ),
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <TableRow className='custom-body-tablerow'>
                <TableCell className='custom-body-tablecell'>
                  {tableMeta.rowData[9]}
                </TableCell>
              </TableRow>
            );
          },
        },
      },
      // Id tool component
      {
        name: 'idtoolcompo',
        label: 'Idtoolcompo',
        options: {
          filter: false,
          display: 'false',
          download: false,
          customHeadRender: (columnMeta, updateDirection) => (
            <th className='custom-header-tablehead' key={columnMeta.name}>
              <TableRow className='custom-header-tablerow'>
                <TableCell
                  style={{ height: 114 }}
                  className='custom-header-tablecell'
                >
                  ID
                </TableCell>
              </TableRow>
            </th>
          ),
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <TableRow className='custom-body-tablerow'>
                <TableCell className='custom-body-tablecell'>
                  {tableMeta.rowData[10]}
                </TableCell>
              </TableRow>
            );
          },
        },
      },
    ];

    return (
      <React.Fragment>
        <AlertDialog
          open={this.state.openConfirmDeleteToolComp}
          onClose={this.handleCloseConfirm}
          title='Do you want to delete this tools?'
          okText='OK'
          cancelText='Cancel'
          onAccept={this.handleAcceptDeleleteToolComp}
          onCancel={this.handleCloseConfirm}
        />
        <ResultInfoDialog
          open={this.state.openResultDialog}
          onClose={this.handleCloseResultDialog}
          resultInfo={this.state.resultInfo}
          okText='OK'
          onAccept={this.handleCloseResultDialog}
          isOpSuccess={this.state.isDeleteToolCompSuccess}
        />
        {userInfo?.role === 'Admin_Role' && (
          <Typography dir='rtl' style={{ paddingBottom: 10 }}>
            <Link to='/componentTool/input-toolcomponent'>
              Input Tool Component
            </Link>
          </Typography>
        )}
        <MUIDataTable
          innerRef={this.tableRef}
          title={
            <Typography>
              {' '}
              {/* Chu y: */}
              {'Tool Component'}
              {this.state.isLoading && (
                <CircularProgress
                  size={24}
                  style={{ marginLeft: 15, position: 'relative', top: 4 }}
                />
              )}
            </Typography>
          }
          className='tool-component-table'
          data={MapDataToDataTableTool(this.state.toolcomp)}
          columns={columns}
          options={options}
        />
      </React.Fragment>
    );
  }
}

export default connect()(FunctionToolComp);
