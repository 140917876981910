import React from 'react';
import Button from '@material-ui/core/Button';
import { Typography, Paper, FormLabel } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import Divider from '@material-ui/core/Divider';
import { serviceFactory } from '../../common/config/ServiceFactory';
import { connect } from 'react-redux';
import * as toolActions from '../../../redux/actions/toolActions';
import SaveIcon from '@material-ui/icons/Save';
import moment from 'moment';
class EditVersionTracking extends React.Component<any, any> {
  private versionTrackingService = serviceFactory.getVSTrackingService();
  private toolService = serviceFactory.getToolService();
  private versionId: string;
  constructor(props) {
    super(props);
    this.versionId = this.props.match.params.id;
    this.state = {
      date: new Date(),
      fw: '',
      database: '',
      bootloader: '',
      changes: '',
      status: null, // combobox
      statusVersionId: '', // req
      userinterface_version: '',
      manual_version: '',
      ymme_library: '',
      ymme_database: '',
      vindecode_library: '',
      vindecode_database: '',
      cycle_library: '',
      cycle_database: '',
      possiblecauses_library: '',
      possiblecauses_database: '',
      oilreset_library: '',
      oilreset_database: '',
      obd1_library: '',
      obd1_database: '',
      nwsdtc_library: '',
      nwsdtc_database: '',
      protocol_api: '',
      nws_library: '',
      nws_database: '',
      laymanterm_library: '',
      laymanterm_database: '',
      dtcseverity_library: '',
      dtcseverity_database: '',
      dtc_library: '',
      dtc_database: '',
      dlc_library: '',
      dlc_database: '',
      batteryreset_library: '',
      batteryreset_database: '',
      abssrslivedata_library: '',
      abssrslivedata_database: '',
      obd2app_library: '',
      obd2app_database: '',
      activetest_library: '',
      activetest_database: '',
      sascalib_library: '',
      sascalib_database: '',
      specialfunc_library: '',
      specialfunc_database: '',
      epb_library: '',
      epb_database: '',
      oilresetsf_library: '',
      oilresetsf_database: '',
      batterysf_library: '',
      batterysf_database: '',
      tpmslivedata_library: '',
      tpmslivedata_database: '',
      tcmlivedate_library: '',
      tcmlivedate_database: '',
      tpmsprocedure_library: '',
      tpmsprocedure_database: '',
      ofm_library: '',
      ofm_database: '',
      mode06_library: '',
      mode06_database: '',

      //combobox status version
      namestatusarr: '', //combobox
    };
  }
  onChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleDateChange = (date) => {
    this.setState({ date });
  };
  async componentDidMount() {
    const idTool = this.props.match.params.idTool;
    const res = await this.toolService.getOneTool(idTool).toPromise();
    if (res.response && res.response.data.error) {
      // error checking
      alert(res.response.data.error);
    } else {
      this.props.dispatch(
        toolActions.selectTool({
          ...res.data.data,
          title: res.data.data.toolName,
        })
      );
    }

    this.versionTrackingService.getallStatusVersion().subscribe((res) => {
      this.setState({
        namestatusarr: res.data.data,
      });
    });
    // load lai data
    this.versionTrackingService
      .getOneVersionById(this.versionId)
      .subscribe((res) => {
        if (res.response && res.response.data.error) {
          this.setState({ error: res.response.data.error });
          console.error(res.response.data.error);
          window.alert(res.response.data.error);
        } else {
          const returnedData = res.data.data;
          delete returnedData['__v'];
          delete returnedData['_id'];
          const status = {
            title: returnedData['statusversion'].name,
            value: returnedData['statusversion'].value,
            id: returnedData['statusversion']._id,
          };
          const date = returnedData['date'];
          // luu y
          //   status: null, // combobox
          this.setState({
            ...this.state,
            status,
            date: new Date(date),
            ...returnedData,
            statusVersionId: status.id,
          });
        }
      });
  }
  hangleChangeStatus = (event, value) => {
    if (value) {
      this.setState({ status: value, statusVersionId: value.id });
    }
  };
  onSubmit = (e) => {
    e.preventDefault();
    const body = {
      date: new Date(this.state.date),
      fw:
        typeof this.state.fw == 'string'
          ? this.state.fw.trim()
          : this.state.fw || 'N/A',
      database: this.state.database || 'N/A',
      bootloader: this.state.bootloader || 'N/A',
      changes: this.state.changes || 'N/A',
      statusVersionId: this.state.statusVersionId, // req
      userinterface_version: this.state.userinterface_version || 'N/A',
      manual_version: this.state.manual_version || 'N/A',
      ymme_library: this.state.ymme_library || 'N/A',
      ymme_database: this.state.ymme_database || 'N/A',
      vindecode_library: this.state.vindecode_library || 'N/A',
      vindecode_database: this.state.vindecode_database || 'N/A',
      cycle_library: this.state.cycle_library || 'N/A',
      cycle_database: this.state.cycle_database || 'N/A',
      possiblecauses_library: this.state.possiblecauses_library || 'N/A',
      possiblecauses_database: this.state.possiblecauses_database || 'N/A',
      oilreset_library: this.state.oilreset_library || 'N/A',
      oilreset_database: this.state.oilreset_database || 'N/A',
      obd1_library: this.state.obd1_library || 'N/A',
      obd1_database: this.state.obd1_database || 'N/A',
      nwsdtc_library: this.state.nwsdtc_library || 'N/A',
      nwsdtc_database: this.state.nwsdtc_database || 'N/A',
      protocol_api: this.state.protocol_api || 'N/A',
      nws_library: this.state.nws_library || 'N/A',
      nws_database: this.state.nws_database || 'N/A',
      laymanterm_library: this.state.laymanterm_library || 'N/A',
      laymanterm_database: this.state.laymanterm_database || 'N/A',
      dtcseverity_library: this.state.dtcseverity_library || 'N/A',
      dtcseverity_database: this.state.dtcseverity_database || 'N/A',
      dtc_library: this.state.dtc_library || 'N/A',
      dtc_database: this.state.dtc_database || 'N/A',
      dlc_library: this.state.dlc_library || 'N/A',
      dlc_database: this.state.dlc_database || 'N/A',
      batteryreset_library: this.state.batteryreset_library || 'N/A',
      batteryreset_database: this.state.batteryreset_database || 'N/A',
      abssrslivedata_library: this.state.abssrslivedata_library || 'N/A',
      abssrslivedata_database: this.state.abssrslivedata_database || 'N/A',
      obd2app_library: this.state.obd2app_library || 'N/A',
      obd2app_database: this.state.obd2app_database || 'N/A',
      activetest_library: this.state.activetest_library || 'N/A',
      activetest_database: this.state.activetest_database || 'N/A',
      sascalib_library: this.state.sascalib_library || 'N/A',
      sascalib_database: this.state.sascalib_database || 'N/A',
      specialfunc_library: this.state.specialfunc_library || 'N/A',
      specialfunc_database: this.state.specialfunc_database || 'N/A',
      epb_library: this.state.epb_library || 'N/A',
      epb_database: this.state.epb_database || 'N/A',
      oilresetsf_library: this.state.oilresetsf_library || 'N/A',
      oilresetsf_database: this.state.oilresetsf_database || 'N/A',
      batterysf_library: this.state.batterysf_library || 'N/A',
      batterysf_database: this.state.batterysf_database || 'N/A',
      tpmslivedata_library: this.state.tpmslivedata_library || 'N/A',
      tpmslivedata_database: this.state.tpmslivedata_database || 'N/A',
      tcmlivedate_library: this.state.tcmlivedate_library || 'N/A',
      tcmlivedate_database: this.state.tcmlivedate_database || 'N/A',
      tpmsprocedure_library: this.state.tpmsprocedure_library || 'N/A',
      tpmsprocedure_database: this.state.tpmsprocedure_database || 'N/A',
      ofm_library: this.state.ofm_library || 'N/A',
      ofm_database: this.state.ofm_database || 'N/A',
      mode06_library: this.state.mode06_library || 'N/A',
      mode06_database: this.state.mode06_database || 'N/A',
      //...this.state,
      //date: moment(this.state.date).format('YYYY-MM-DD'),
    };
    delete body['namestatusarr'];
    delete body['status'];
    const idVersion = this.props.match.params.id;
    const idTool = this.props.match.params.idTool;
    this.versionTrackingService
      .updateversion(idVersion, body)
      .subscribe((res) => {
        if (res.response && res.response.data.error) {
          this.setState({ error: res.response.data.error });
          console.error(res.response.data.error);
          window.alert(res.response.data.error);
        } else {
          const { history } = this.props;
          history.push(`/version-tracking/tool/${idTool}`);
        }
      });
  };
  onClear = () => {
    this.setState({
      fw: '',
    });
  };

  render = () => {
    return (
      <Paper elevation={4} style={{ padding: 16 }}>
        <header>
          <Typography variant='h6' component='h3' style={{ color: '#000000' }}>
            {(this.props.tool && this.props.tool.title) || ''} - Update Version
            Tracking
          </Typography>
        </header>
        <form noValidate autoComplete='off' onSubmit={this.onSubmit}>
          <div className='custom-buttoncreate'>
            <Grid container spacing={2} xs={12}>
              <Grid item xs={12}>
                <Typography variant='subtitle2'>Tool FW</Typography>
              </Grid>
              <Grid item xs={4}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    disableToolbar
                    variant='inline'
                    format='MM/dd/yyyy'
                    id='date'
                    label='Date'
                    value={this.state.date}
                    onChange={this.handleDateChange}
                    KeyboardButtonProps={{
                      'aria-label': 'change date',
                    }}
                    fullWidth
                    autoOk
                  />
                </MuiPickersUtilsProvider>
              </Grid>

              <Grid item xs={4}>
                <TextField
                  id='fw'
                  label='FW'
                  fullWidth
                  name='fw'
                  value={this.state.fw}
                  onChange={this.onChange}
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>

              <Grid item xs={4}>
                <TextField
                  id='db'
                  label='DB'
                  fullWidth
                  name='database'
                  value={this.state.database}
                  onChange={this.onChange}
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>

              <Grid item xs={4}>
                <TextField
                  id='bl'
                  label='BL'
                  fullWidth
                  name='bootloader'
                  value={this.state.bootloader}
                  onChange={this.onChange}
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>

              <Grid item xs={4}>
                <TextField
                  fullWidth
                  placeholder='Changes'
                  name='changes'
                  value={this.state.changes}
                  onChange={this.onChange}
                  multiline
                  margin='normal'
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
              <Grid item xs={4}>
                <Autocomplete
                  options={datastatus(this.state.namestatusarr)}
                  getOptionLabel={(option) => option.title}
                  value={this.state.status}
                  onChange={this.hangleChangeStatus}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label='Tool Version Status'
                      fullWidth
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: 'off', // disable autocomplete and autofill
                      }}
                      InputLabelProps={{ shrink: true }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  fullWidth
                  placeholder='User Interface version'
                  name='userinterface_version'
                  value={this.state.userinterface_version}
                  onChange={this.onChange}
                  multiline
                  margin='normal'
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  fullWidth
                  placeholder='Manual version'
                  name='manual_version'
                  value={this.state.manual_version}
                  onChange={this.onChange}
                  multiline
                  margin='normal'
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
            </Grid>

            <Divider style={{ margin: 16 }} />

            <Grid container xs={12} style={{ marginTop: 16 }} spacing={2}>
              <Grid item xs={4}>
                <Typography variant='subtitle2'>YMME</Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant='subtitle2'>VIN DECODE</Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant='subtitle2'>
                  TripCycle/DriveCycle
                </Typography>
              </Grid>
              <Grid item xs={2}>
                <TextField
                  id='lib-ymme'
                  label='Library'
                  fullWidth
                  name='ymme_library'
                  value={this.state.ymme_library}
                  onChange={this.onChange}
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
              <Grid item xs={2}>
                <TextField
                  id='db-ymme'
                  label='Database'
                  fullWidth
                  name='ymme_database'
                  value={this.state.ymme_database}
                  onChange={this.onChange}
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
              <Grid item xs={2}>
                <TextField
                  id='lib-vindecode'
                  label='Library'
                  fullWidth
                  name='vindecode_library'
                  value={this.state.vindecode_library}
                  onChange={this.onChange}
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
              <Grid item xs={2}>
                <TextField
                  id='db-vindecode'
                  label='Database'
                  fullWidth
                  name='vindecode_database'
                  value={this.state.vindecode_database}
                  onChange={this.onChange}
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
              <Grid item xs={2}>
                <TextField
                  id='lib-cycle'
                  label='Library'
                  fullWidth
                  name='cycle_library'
                  value={this.state.cycle_library}
                  onChange={this.onChange}
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
              <Grid item xs={2}>
                <TextField
                  id='db-cycle'
                  label='Database'
                  fullWidth
                  name='cycle_database'
                  value={this.state.cycle_database}
                  onChange={this.onChange}
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
            </Grid>

            <Grid container style={{ marginTop: 16 }} xs={12} spacing={2}>
              <Grid item xs={4}>
                <Typography variant='subtitle2'>PossibleCauses</Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant='subtitle2'>Oil Reset</Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant='subtitle2'>OBD1</Typography>
              </Grid>
              <Grid item xs={2}>
                <TextField
                  id='lib-possible'
                  label='Library'
                  fullWidth
                  name='possiblecauses_library'
                  value={this.state.possiblecauses_library}
                  onChange={this.onChange}
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
              <Grid item xs={2}>
                <TextField
                  id='db-possible'
                  label='Database'
                  fullWidth
                  name='possiblecauses_database'
                  value={this.state.possiblecauses_database}
                  onChange={this.onChange}
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
              <Grid item xs={2}>
                <TextField
                  id='lib-oil'
                  label='Library'
                  fullWidth
                  name='oilreset_library'
                  value={this.state.oilreset_library}
                  onChange={this.onChange}
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
              <Grid item xs={2}>
                <TextField
                  id='db-oil'
                  label='Database'
                  fullWidth
                  name='oilreset_database'
                  value={this.state.oilreset_database}
                  onChange={this.onChange}
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
              <Grid item xs={2}>
                <TextField
                  id='lib-obd1'
                  label='Library'
                  fullWidth
                  name='obd1_library'
                  value={this.state.obd1_library}
                  onChange={this.onChange}
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
              <Grid item xs={2}>
                <TextField
                  id='db-obd1'
                  label='Database'
                  fullWidth
                  name='obd1_database'
                  value={this.state.obd1_database}
                  onChange={this.onChange}
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
            </Grid>

            <Grid container spacing={2} xs={12} style={{ marginTop: 16 }}>
              <Grid item xs={4}>
                <Typography variant='subtitle2'>NwsDtc</Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant='subtitle2'>Protocol API</Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant='subtitle2'>NWS</Typography>
              </Grid>
              <Grid item xs={2}>
                <TextField
                  id='lib-nwsdtc'
                  label='Library'
                  fullWidth
                  name='nwsdtc_library'
                  value={this.state.nwsdtc_library}
                  onChange={this.onChange}
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
              <Grid item xs={2}>
                <TextField
                  id='db-nwsdtc'
                  label='Database'
                  fullWidth
                  name='nwsdtc_database'
                  value={this.state.nwsdtc_database}
                  onChange={this.onChange}
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  id='lib-protocol'
                  label='Protocol API'
                  fullWidth
                  name='protocol_api'
                  value={this.state.protocol_api}
                  onChange={this.onChange}
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
              <Grid item xs={2}>
                <TextField
                  id='lib-nws'
                  label='Library'
                  fullWidth
                  name='nws_library'
                  value={this.state.nws_library}
                  onChange={this.onChange}
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
              <Grid item xs={2}>
                <TextField
                  id='db-nws'
                  label='Database'
                  fullWidth
                  name='nws_database'
                  value={this.state.nws_database}
                  onChange={this.onChange}
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
            </Grid>

            <Grid container spacing={2} xs={12} style={{ marginTop: 16 }}>
              <Grid item xs={4}>
                <Typography variant='subtitle2'>LaymanTerm</Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant='subtitle2'>DtcSeverity</Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant='subtitle2'>Dtc</Typography>
              </Grid>
              <Grid item xs={2}>
                <TextField
                  id='lib-laymanterm'
                  label='Library'
                  fullWidth
                  name='laymanterm_library'
                  value={this.state.laymanterm_library}
                  onChange={this.onChange}
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
              <Grid item xs={2}>
                <TextField
                  id='db-laymanterm'
                  label='Database'
                  fullWidth
                  name='laymanterm_database'
                  value={this.state.laymanterm_database}
                  onChange={this.onChange}
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
              <Grid item xs={2}>
                <TextField
                  id='lib-dtcseverity'
                  label='Library'
                  fullWidth
                  name='dtcseverity_library'
                  value={this.state.dtcseverity_library}
                  onChange={this.onChange}
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
              <Grid item xs={2}>
                <TextField
                  id='db-dtcseverity'
                  label='Database'
                  fullWidth
                  name='dtcseverity_database'
                  value={this.state.dtcseverity_database}
                  onChange={this.onChange}
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
              <Grid item xs={2}>
                <TextField
                  id='lib-dtc'
                  label='Library'
                  fullWidth
                  name='dtc_library'
                  value={this.state.dtc_library}
                  onChange={this.onChange}
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
              <Grid item xs={2}>
                <TextField
                  id='db-dtc'
                  label='Database'
                  fullWidth
                  name='dtc_database'
                  value={this.state.dtc_database}
                  onChange={this.onChange}
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
            </Grid>

            <Grid container xs={12} spacing={2} style={{ marginTop: 16 }}>
              <Grid item xs={4}>
                <Typography variant='subtitle2'>DLC</Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant='subtitle2'>Battery Reset</Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant='subtitle2'>ABS/SRS Live Data</Typography>
              </Grid>
              <Grid item xs={2}>
                <TextField
                  id='lib-dlc'
                  label='Library'
                  fullWidth
                  name='dlc_library'
                  value={this.state.dlc_library}
                  onChange={this.onChange}
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
              <Grid item xs={2}>
                <TextField
                  id='db-dlc'
                  label='Database'
                  fullWidth
                  name='dlc_database'
                  value={this.state.dlc_database}
                  onChange={this.onChange}
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
              <Grid item xs={2}>
                <TextField
                  id='lib-batterreset'
                  label='Library'
                  fullWidth
                  name='batteryreset_library'
                  value={this.state.batteryreset_library}
                  onChange={this.onChange}
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
              <Grid item xs={2}>
                <TextField
                  id='db-batterreset'
                  label='Database'
                  fullWidth
                  name='batteryreset_database'
                  value={this.state.batteryreset_database}
                  onChange={this.onChange}
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
              <Grid item xs={2}>
                <TextField
                  id='lib-abssrs'
                  label='Library'
                  fullWidth
                  name='abssrslivedata_library'
                  value={this.state.abssrslivedata_library}
                  onChange={this.onChange}
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
              <Grid item xs={2}>
                <TextField
                  id='db-abssrs'
                  label='Database'
                  fullWidth
                  name='abssrslivedata_database'
                  value={this.state.abssrslivedata_database}
                  onChange={this.onChange}
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
            </Grid>

            <Grid container xs={12} spacing={2} style={{ marginTop: 16 }}>
              <Grid item xs={4}>
                <Typography variant='subtitle2'>OBD2 APP</Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant='subtitle2'>Active Test</Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant='subtitle2'>SAS Calib</Typography>
              </Grid>
              <Grid item xs={2}>
                <TextField
                  id='lib-obd2app'
                  label='Library'
                  fullWidth
                  name='obd2app_library'
                  value={this.state.obd2app_library}
                  onChange={this.onChange}
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
              <Grid item xs={2}>
                <TextField
                  id='db-obd2app'
                  label='Database'
                  fullWidth
                  name='obd2app_database'
                  value={this.state.obd2app_database}
                  onChange={this.onChange}
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
              <Grid item xs={2}>
                <TextField
                  id='lib-activetest'
                  label='Library'
                  fullWidth
                  name='activetest_library'
                  value={this.state.activetest_library}
                  onChange={this.onChange}
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
              <Grid item xs={2}>
                <TextField
                  id='db-activetest'
                  label='Database'
                  fullWidth
                  name='activetest_database'
                  value={this.state.activetest_database}
                  onChange={this.onChange}
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
              <Grid item xs={2}>
                <TextField
                  id='lib-sascalib'
                  label='Library'
                  fullWidth
                  name='sascalib_library'
                  value={this.state.sascalib_library}
                  onChange={this.onChange}
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
              <Grid item xs={2}>
                <TextField
                  id='db-sascalib'
                  label='Database'
                  fullWidth
                  name='sascalib_database'
                  value={this.state.sascalib_database}
                  onChange={this.onChange}
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
            </Grid>

            <Grid container xs={12} spacing={2} style={{ marginTop: 16 }}>
              <Grid item xs={4}>
                <Typography variant='subtitle2'>Special Function</Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant='subtitle2'>EPB</Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant='subtitle2'>Oil Reset SF</Typography>
              </Grid>
              <Grid item xs={2}>
                <TextField
                  id='lib-specialfunc'
                  label='Library'
                  fullWidth
                  name='specialfunc_library'
                  value={this.state.specialfunc_library}
                  onChange={this.onChange}
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
              <Grid item xs={2}>
                <TextField
                  id='db-specialfunc'
                  label='Database'
                  fullWidth
                  name='specialfunc_database'
                  value={this.state.specialfunc_database}
                  onChange={this.onChange}
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
              <Grid item xs={2}>
                <TextField
                  id='lib-epb'
                  label='Library'
                  fullWidth
                  name='epb_library'
                  value={this.state.epb_library}
                  onChange={this.onChange}
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
              <Grid item xs={2}>
                <TextField
                  id='db-epb'
                  label='Database'
                  fullWidth
                  name='epb_database'
                  value={this.state.epb_database}
                  onChange={this.onChange}
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
              <Grid item xs={2}>
                <TextField
                  id='lib-oidsf'
                  label='Library'
                  fullWidth
                  name='oilresetsf_library'
                  value={this.state.oilresetsf_library}
                  onChange={this.onChange}
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
              <Grid item xs={2}>
                <TextField
                  id='db-oidsf'
                  label='Database'
                  fullWidth
                  name='oilresetsf_database'
                  value={this.state.oilresetsf_database}
                  onChange={this.onChange}
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
            </Grid>

            <Grid container xs={12} spacing={2} style={{ marginTop: 16 }}>
              <Grid item xs={4}>
                <Typography variant='subtitle2'>Battery SF</Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant='subtitle2'>TPMS Live Data </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant='subtitle2'> TCM Live Data </Typography>
              </Grid>
              <Grid item xs={2}>
                <TextField
                  id='lib-batterysf'
                  label='Library'
                  fullWidth
                  name='batterysf_library'
                  value={this.state.batterysf_library}
                  onChange={this.onChange}
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
              <Grid item xs={2}>
                <TextField
                  id='db-batterysf'
                  label='Database'
                  fullWidth
                  name='batterysf_database'
                  value={this.state.batterysf_database}
                  onChange={this.onChange}
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
              <Grid item xs={2}>
                <TextField
                  id='lib-tpmsdata'
                  label='Library'
                  fullWidth
                  name='tpmslivedata_library'
                  value={this.state.tpmslivedata_library}
                  onChange={this.onChange}
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
              <Grid item xs={2}>
                <TextField
                  id='db-tpmsdata'
                  label='Database'
                  fullWidth
                  name='tpmslivedata_database'
                  value={this.state.tpmslivedata_database}
                  onChange={this.onChange}
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
              <Grid item xs={2}>
                <TextField
                  id='lib-tcm'
                  label='Library'
                  fullWidth
                  name='tcmlivedate_library'
                  value={this.state.tcmlivedate_library}
                  onChange={this.onChange}
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
              <Grid item xs={2}>
                <TextField
                  id='db-tcm'
                  label='Database'
                  fullWidth
                  name='tcmlivedate_database'
                  value={this.state.tcmlivedate_database}
                  onChange={this.onChange}
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
            </Grid>

            <Grid container xs={12} spacing={2} style={{ marginTop: 16 }}>
              <Grid item xs={4}>
                <Typography variant='subtitle2'>TPMS Procedure</Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant='subtitle2'>OFM </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant='subtitle2'> Mode06</Typography>
              </Grid>
              <Grid item xs={2}>
                <TextField
                  id='lib-tpmspro'
                  label='Library'
                  fullWidth
                  name='tpmsprocedure_library'
                  value={this.state.tpmsprocedure_library}
                  onChange={this.onChange}
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
              <Grid item xs={2}>
                <TextField
                  id='db-tpmspro'
                  label='Database'
                  fullWidth
                  name='tpmsprocedure_database'
                  value={this.state.tpmsprocedure_database}
                  onChange={this.onChange}
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
              <Grid item xs={2}>
                <TextField
                  id='lib-ofm'
                  label='Library'
                  fullWidth
                  name='ofm_library'
                  value={this.state.ofm_library}
                  onChange={this.onChange}
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
              <Grid item xs={2}>
                <TextField
                  id='db-ofm'
                  label='Database'
                  fullWidth
                  name='ofm_database'
                  value={this.state.ofm_database}
                  onChange={this.onChange}
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
              <Grid item xs={2}>
                <TextField
                  id='lib-mode06'
                  label='Library'
                  fullWidth
                  name='mode06_library'
                  value={this.state.mode06_library}
                  onChange={this.onChange}
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
              <Grid item xs={2}>
                <TextField
                  id='db-mode06'
                  label='Database'
                  fullWidth
                  name='mode06_database'
                  value={this.state.mode06_database}
                  onChange={this.onChange}
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
            </Grid>

            <Divider style={{ margin: 16 }} />

            <Button
              type='submit'
              variant='contained'
              color='secondary'
              startIcon={<SaveIcon />}
            >
              UPDATE
            </Button>
          </div>
        </form>
      </Paper>
    );
  };
}

const datastatus = (options: any) => {
  let list = [];
  for (const option of options) {
    if (option.name) {
      list.push({ title: option.name, id: option._id, value: option.value });
    }
  }
  return list;
};
const mapStateToProps = (state, ownProps) => {
  // state is from store
  return {
    tool: state.tool.currentTool,
  };
};
export default connect(mapStateToProps)(EditVersionTracking);
