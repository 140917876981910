import React from 'react';
import Paper from '@material-ui/core/Paper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import { Link, NavLink } from 'react-router-dom';
import { Typography } from '@material-ui/core';
import './MenuVertical.scss';
import { connect } from 'react-redux';
class MenuVertical extends React.Component<any, any> {
  constructor(props) {
    super(props);
  }
  render() {
    const data = localStorage.getItem('userInfo');
    const userInfo = JSON.parse(data);
    return (
      <nav className='custom-menuvertical'>
        <Paper elevation={4}>
          <MenuList>
            <Typography
              component='h4'
              variant='h5'
              style={{ textAlign: 'center' }}
            >
              {`Tool ${(this.props.tool && this.props.tool.title) || ''}`}
              {/* luu y phai co thuoc tinh title khi dispatch */}
            </Typography>
            <NavLink
              isActive={(match1, location1) => {
                return location1.pathname.startsWith('/version-tracking');
              }}
              activeClassName='is-active'
              to={`/version-tracking/tool/${
                (this.props.tool &&
                  (this.props.tool.id || this.props.tool._id)) ||
                ''
              }`}
            >
              <MenuItem>Version Tracking</MenuItem>
            </NavLink>
            {userInfo?.role !== 'QA_Role' && userInfo?.role !== 'Manager_Role' && (
              <NavLink
                activeClassName='is-active'
                to={`/input-version-tracking/tool/${
                  (this.props.tool &&
                    (this.props.tool.id || this.props.tool._id)) ||
                  ''
                }`}
              >
                <MenuItem>Input Version Tracking</MenuItem>
              </NavLink>
            )}
            <NavLink
              activeClassName='is-active'
              isActive={(match1, location1) => {
                return (
                  location1.pathname.startsWith('/bug-tracking/tool') ||
                  location1.pathname.startsWith(
                    '/bug-tracking/edit-bug-tracking'
                  )
                );
              }}
              to={`/bug-tracking/tool/${
                (this.props.tool &&
                  (this.props.tool.id || this.props.tool._id)) ||
                ''
              }`}
            >
              <MenuItem>Bug Tracking</MenuItem>
            </NavLink>
            {userInfo?.role !== 'QA_Role' && userInfo?.role !== 'Manager_Role' && (
              <NavLink
                activeClassName='is-active'
                to={`/input-bugs/tool/${
                  (this.props.tool &&
                    (this.props.tool.id || this.props.tool._id)) ||
                  ''
                }`}
              >
                <MenuItem>Input Bugs</MenuItem>
              </NavLink>
            )}
            <NavLink
              activeClassName='is-active'
              isActive={(match1, location1) => {
                return location1.pathname.startsWith('/documents');
              }}
              to={`/documents/tool/${
                (this.props.tool &&
                  (this.props.tool.id || this.props.tool._id)) ||
                ''
              }`}
            >
              <MenuItem>Documents</MenuItem>
            </NavLink>
          </MenuList>
        </Paper>
      </nav>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  // state is from store
  return {
    tool: state.tool.currentTool,
  };
};
export default connect(mapStateToProps)(MenuVertical);
