import React from 'react';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { serviceFactory } from '../../common/config/ServiceFactory';
import { Grid, Paper } from '@material-ui/core';
import { connect } from 'react-redux';
import SaveIcon from '@material-ui/icons/Save';
import { sanitizeASCIIString } from '../../../utils/StringUtils';
class EditGroupUser extends React.Component<any, any> {
  private readonly roleService = serviceFactory.getRoleService();
  private readonly groupUserService = serviceFactory.getGroupUserService();
  constructor(props) {
    super(props);
    this.state = {
      nameGroup: '',
      groupUser: [],
      error: '',
      role: null,
    };
  }
  componentDidMount() {
    // combobox
    this.roleService.GetallRole().subscribe((res) => {
      this.setState({
        groupUser: res.data.data,
      });
    });
    // tools

    if (this.props.match.params && this.props.match.params.id) {
      const groupUserId = this.props.match.params.id;
      this.groupUserService.getOneGroupUser(groupUserId).subscribe((res) => {
        if (res.data.data) {
          this.setState({
            nameGroup: res.data.data.nameGroup,
            role: {
              _id: res.data.data.roleId._id,
              title: res.data.data.roleId.nameRole,
            },
          });
        }
      });
    }
  }
  onSubmit = (e) => {
    e.preventDefault();
    const body = {
      nameGroup: sanitizeASCIIString(this.state.nameGroup),
      roleId: this.state.role.id,
    };
    const groupUserId = this.props.match.params.id;
    this.groupUserService
      .updateGroupUser(groupUserId, body)
      .subscribe((res) => {
        if (res.response && res.response.data.error) {
          this.setState({ error: res.response.data.error });
          console.error(res.response.data.error);
          window.alert(res.response.data.error);
        } else {
          this.navigateBack();
          this.onClear();
        }
      });
  };

  onClear = () => {
    this.setState({
      nameGroup: '',
      // groupId: '',
    });
  };
  navigateBack = () => {
    this.props.history.goBack();
  };
  onChange = (e) => {
    this.setState({
      nameGroup: e.target.value,
    });
  };
  handleChangeRole = (event, value) => {
    if (value) {
      this.setState({ role: value });
    }
  };
  render = () => {
    return (
      <form noValidate autoComplete='off' onSubmit={this.onSubmit}>
        <div className='custom-buttoncreate'>
          <Typography variant='h6' component='h3' style={{ marginBottom: 16 }}>
            Update Group User:
          </Typography>
          <Grid container spacing={4} alignItems='center'>
            <Grid item xs={4}>
              <TextField
                required
                id='outlined-required'
                label='Required'
                variant='outlined'
                name='nameGroup'
                size='small'
                fullWidth
                value={this.state.nameGroup}
                onChange={this.onChange}
              />
            </Grid>
            <Grid item xs={4}>
              <Autocomplete
                id='combo-box-demo'
                onChange={this.handleChangeRole}
                options={flattenOptions(this.state.groupUser)}
                getOptionLabel={(option) => option.title}
                //style={{ width: 200 }}
                value={this.state.role}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label='Role name'
                    variant='outlined'
                    fullWidth
                    size='small'
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: 'off', // disable autocomplete and autofill
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={4}>
              <Button
                variant='contained'
                color='secondary'
                type='submit'
                startIcon={<SaveIcon />}
              >
                UPDATE
              </Button>
            </Grid>
          </Grid>
        </div>
      </form>
    );
  };
}
const flattenOptions = (options: any) => {
  let list = [];
  for (const option of options) {
    if (option.nameRole) {
      list.push({ title: option.nameRole, id: option._id });
    }
  }
  return list;
};
export default connect()(EditGroupUser);
