import { catchError } from 'rxjs/operators';
import { WebClientUtil } from '../../util/WebClientUtil';
import { API_ENDPOINT } from '../../../../config';
import { GroupModel } from '../../model/GroupSearchModel';
import { Observable, of } from 'rxjs';
import { StatusService } from './StatusService';
export class StatusServiceImpl implements StatusService {
  private baseUrl = API_ENDPOINT.versionTrackingURL;
  searchStatus(params: any): Observable<GroupModel> {
    let url = `${this.baseUrl}/status/searchStatus`;
    if (params.name) {
      url += '?name=' + params.name;
    }
    if (params.value) {
      if (params.name) {
        url += '&value=' + params.value;
      } else {
        url += '?value=' + params.value;
      }
    }
    return WebClientUtil.getObject(url);
  }

  searchOrCreateNewStatus(body: any): Observable<GroupModel> {
    const url = `${this.baseUrl}/status/searchOrCreateNewStatus`;
    return WebClientUtil.postObject(url, body);
  }
}
