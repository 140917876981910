import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { Observable, of, throwError, from } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
export interface DataResponse {
  error?: any;
  data?: any;
  message?: any;
  status?: any;
}
export class WebClientUtil {
  public static isSessionExpired = false;
  public static getHttpOptions(isIncludeAuthorizationHeader: boolean = true): AxiosRequestConfig {
    const data = localStorage.getItem('userInfo');
    let httpOptions: AxiosRequestConfig = {};
    if (!data) {
      httpOptions = {
        headers: {
          'Content-Type': 'application/json;charset=UTF-8',
        },
      };
      return httpOptions;
    }
    const userInfo = JSON.parse(data);
    httpOptions = {
      headers: {
        'Content-Type': 'application/json;charset=UTF-8',
        Authorization: `Bearer ${userInfo.jwt_token}`,
      },
    };
    if (!isIncludeAuthorizationHeader) {
      delete httpOptions.headers.Authorization;
    }
    return httpOptions;
  }

  public static getObject(url: string): Observable<any> {
    return from(axios.get(url, this.getHttpOptions())).pipe(
      map((data: any) => {
        if (!data) {
          return throwError({ error: 'No data return' });
        } else {
          return data;
        }
      }),
      catchError((error) => {
        if (error?.response.status === 401 && !this.isSessionExpired) {
          this.isSessionExpired = true;
          window.alert(
            error.response?.data.message || 'Authorization error, please login!'
          );
          window.location.href = process.env.REACT_APP_APP_URL;
        }
        console.error(error);
        return of(error);
      })
    );
  }

  public static postObject<T>(url: string, obj: any, isIncludeAuthorizationHeader: boolean = true) {
    return from(axios.post<T>(url, obj, this.getHttpOptions(isIncludeAuthorizationHeader))).pipe(
      map((data: DataResponse) => {
        if (!data) {
          return throwError({ error: 'No data return' });
        } else {
          return data;
        }
      }),
      catchError((error) => {
        if (error?.response.status === 401 && !this.isSessionExpired) {
          this.isSessionExpired = true;
          window.alert(
            error.response?.data.message || 'Authorization error, please login!'
          );
          window.location.href = process.env.REACT_APP_APP_URL;
        }
        console.error(error);
        return of(error);
      })
    );
  }

  public static deleteObject<T>(url: string) {
    return from(axios.delete<T>(url, this.getHttpOptions())).pipe(
      map((data: DataResponse) => {
        if (!data) {
          return throwError({ error: 'No data return' });
        } else {
          return data;
        }
      }),
      catchError((error) => {
        if (error?.response.status === 401 && !this.isSessionExpired) {
          this.isSessionExpired = true;
          window.alert(
            error.response?.data.message || 'Authorization error, please login!'
          );
          window.location.href = process.env.REACT_APP_APP_URL;
        }
        console.error(error);
        return of(error);
      })
    );
  }

  public static putObject<T>(url: string, obj: any) {
    return from(axios.put<T>(url, obj, this.getHttpOptions())).pipe(
      map((data: DataResponse) => {
        if (!data) {
          return throwError({ error: 'No data return' });
        } else {
          return data;
        }
      }),
      catchError((error) => {
        if (error?.response.status === 401 && !this.isSessionExpired) {
          this.isSessionExpired = true;
          window.alert(
            error.response?.data.message || 'Authorization error, please login!'
          );
          window.location.href = process.env.REACT_APP_APP_URL;
        }
        console.error(error);
        return of(error);
      })
    );
  }

  public static patchObject<T>(url: string, obj: any) {
    return from(axios.patch<T>(url, obj, this.getHttpOptions())).pipe(
      map((data: DataResponse) => {
        if (!data) {
          return throwError({ error: 'No data return' });
        } else {
          return data;
        }
      }),
      catchError((error) => {
        if (error?.response.status === 401 && !this.isSessionExpired) {
          this.isSessionExpired = true;
          window.alert(
            error.response?.data.message || 'Authorization error, please login!'
          );
          window.location.href = process.env.REACT_APP_APP_URL;
        }
        console.error(error);
        return of(error);
      })
    );
  }
}
