import React from 'react';
import MUIDataTable from 'mui-datatables';
import { Link } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import {
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  CircularProgress,
  Button,
} from '@material-ui/core';
import './VersionTracking.scss';
import { serviceFactory } from '../../common/config/ServiceFactory';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import CompareArrowsIcon from '@material-ui/icons/CompareArrows';
import EditIcon from '@material-ui/icons/Edit';
import DelIcon from '@material-ui/icons/Delete';
import GetAppIcon from '@material-ui/icons/GetApp';
import readXlsxFile from 'read-excel-file';
import { version } from '@babel/core';
import { unregister } from '../../../serviceWorker';
import { GroupService } from '../../common/service/group/GroupService';
import { VersionTrackingService } from '../../common/service/version-tracking/VersionTrackingService';
import { StatusService } from '../../common/service/status/StatusService';
import { connect } from 'react-redux';
import { fetchGroupAndToolData } from '../../../redux/actions/horizontalNavbarActions';
import AlertDialog from '../../common/component/Dialog/AlertDialog';
import ResultInfoDialog from '../../common/component/Dialog/ResultInfoDialog';
import moment from 'moment';
import { isValidDate } from '../../common/util/dateUtil';
import { sanitizeASCIIString } from '../../../utils/StringUtils';
import DialogMessage from '../../common/component/Dialog/DialogMessage';
import * as toolActions from '../../../redux/actions/toolActions';
import * as groupActions from '../../../redux/actions/groupActions';
import { RoleService } from '../../common/service/role/RoleService';
class GroupVersionTrackingComponent extends React.Component<any, any> {
  private tableRef = React.createRef();
  private groupService: GroupService;
  private vsService: VersionTrackingService;
  private vsStatusService: StatusService;
  private roleService: RoleService;
  private inputExcel: any;
  private versionTrackingService = serviceFactory.getVSTrackingService();
  private toolService = serviceFactory.getToolService();
  private groupId: string = '';
  private idVersion = undefined;
  private arrayIdVersion: string[] = undefined;
  private isVersionsOfGroupTable = true;
  private excelData: any[];
  private columns = [
    {
      name: 'tool_version',
      label: 'Tool',
      options: {
        filter: true,
        display: this.isVersionsOfGroupTable === false ? 'false' : 'true',
        customHeadRender: (columnMeta, updateDirection) => (
          <th className='custom-header-tablehead' key={columnMeta.name}>
            <TableRow className='custom-header-tablerow'>
              <TableCell
                style={{ height: 114 }}
                className='custom-header-tablecell'
              >
                Tool
              </TableCell>
            </TableRow>
          </th>
        ),
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <TableRow className='custom-body-tablerow'>
              <TableCell
                style={{ display: 'inline-block' }}
                className='custom-body-tablecell'
              >
                {tableMeta.rowData[0]}
              </TableCell>
            </TableRow>
          );
        },
      },
    },
    {
      name: 'date',
      label: 'Date',
      options: {
        filter: false,
        customHeadRender: (
          columnMeta,
          updateDirection // custom header cell
        ) => (
          <th className='custom-header-tablehead' key={columnMeta.name}>
            <TableRow className='custom-header-tablerow'>
              <TableCell className='custom-header-tablecell' colSpan={8}>
                Tool FW
              </TableCell>
            </TableRow>
            <TableRow className='custom-header-tablerow'>
              <TableCell className='custom-header-tablecell'>Date</TableCell>
              <TableCell className='custom-header-tablecell'>FW</TableCell>
              <TableCell className='custom-header-tablecell'>
                Database
              </TableCell>
              <TableCell className='custom-header-tablecell'>
                Bootloader
              </TableCell>
              <TableCell className='custom-header-tablecell'>Changes</TableCell>
              <TableCell className='custom-header-tablecell'>
                Tool version status
              </TableCell>
              <TableCell className='custom-header-tablecell'>
                User Interface version
              </TableCell>
              <TableCell className='custom-header-tablecell'>
                Manual version
              </TableCell>
            </TableRow>
          </th>
        ),
        customBodyRender: (value, tableMeta, updateValue) => {
          // custom body cell
          // value is the first column, not merged column
          return (
            <TableRow className='custom-body-tablerow'>
              <TableCell
                style={{ display: 'inline-block' }}
                className='custom-body-tablecell'
              >
                {tableMeta.rowData[1]}
              </TableCell>
              <TableCell
                style={{ display: 'inline-block' }}
                className='custom-body-tablecell'
              >
                {tableMeta.rowData[2]}
              </TableCell>
              <TableCell
                style={{ display: 'inline-block' }}
                className='custom-body-tablecell'
              >
                {tableMeta.rowData[3]}
              </TableCell>
              <TableCell
                style={{ display: 'inline-block' }}
                className='custom-body-tablecell'
              >
                {tableMeta.rowData[4]}
              </TableCell>
              <TableCell
                style={{ display: 'inline-block' }}
                className='custom-body-tablecell'
              >
                {tableMeta.rowData[5]}
              </TableCell>
              <TableCell
                style={{ display: 'inline-block' }}
                className='custom-body-tablecell'
              >
                {tableMeta.rowData[6]}
              </TableCell>
              <TableCell
                style={{ display: 'inline-block' }}
                className='custom-body-tablecell'
              >
                {tableMeta.rowData[7]}
              </TableCell>
              <TableCell
                style={{ display: 'inline-block' }}
                className='custom-body-tablecell'
              >
                {tableMeta.rowData[8]}
              </TableCell>
            </TableRow>
          );
        },
      },
    },
    {
      name: 'fw',
      label: 'FW',
      options: {
        display: 'false',
        filter: true,
        customHeadRender: (columnMeta, updateDirection) => null,
        customBodyRender: (value, tableMeta, updateValue) =>
          tableMeta.rowData[2],
      },
    },
    {
      name: 'database',
      label: 'Database',
      options: {
        display: 'false',
        filter: false,
        customHeadRender: (columnMeta, updateDirection) => null,
        customBodyRender: (value, tableMeta, updateValue) =>
          tableMeta.rowData[3],
      },
    },
    {
      name: 'bootloader',
      label: 'Bootloader',
      options: {
        display: 'false',
        filter: false,
        customHeadRender: (columnMeta, updateDirection) => null,
        customBodyRender: (value, tableMeta, updateValue) =>
          tableMeta.rowData[4],
      },
    },
    {
      name: 'changes',
      label: 'Changes',
      options: {
        display: 'false',
        filter: false,
        customHeadRender: (columnMeta, updateDirection) => null,
        customBodyRender: (value, tableMeta, updateValue) =>
          tableMeta.rowData[5],
      },
    },
    {
      name: 'toolversion',
      label: 'Status',
      options: {
        display: 'false',
        filter: true,
        customHeadRender: (columnMeta, updateDirection) => null,
        customBodyRender: (value, tableMeta, updateValue) =>
          tableMeta.rowData[6],
      },
    },
    {
      name: 'userinterface_version',
      label: 'User Interface version',
      options: {
        display: 'false',
        filter: false,
        customHeadRender: (columnMeta, updateDirection) => null,
        customBodyRender: (value, tableMeta, updateValue) =>
          tableMeta.rowData[7],
      },
    },
    {
      name: 'manual_version',
      label: 'Manual version',
      options: {
        display: 'false',
        filter: false,
        customHeadRender: (columnMeta, updateDirection) => null,
        customBodyRender: (value, tableMeta, updateValue) =>
          tableMeta.rowData[8],
      },
    },
    {
      name: 'ymme_library',
      label: 'Library',
      options: {
        filter: false,
        customHeadRender: (columnMeta, updateDirection) => (
          <th className='custom-header-tablehead' key={columnMeta.name}>
            <TableRow className='custom-header-tablerow'>
              <TableCell className='custom-header-tablecell' colSpan={2}>
                YMME
              </TableCell>
            </TableRow>
            <TableRow className='custom-header-tablerow'>
              <TableCell className='custom-header-tablecell'>Library</TableCell>
              <TableCell className='custom-header-tablecell'>
                Database
              </TableCell>
            </TableRow>
          </th>
        ),
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <TableRow className='custom-body-tablerow'>
              <TableCell
                style={{ display: 'inline-block' }}
                className='custom-body-tablecell'
              >
                {tableMeta.rowData[9]}
              </TableCell>
              <TableCell
                style={{ display: 'inline-block' }}
                className='custom-body-tablecell'
              >
                {tableMeta.rowData[10]}
              </TableCell>
            </TableRow>
          );
        },
      },
    },
    {
      // YMME
      name: 'ymme_database',
      label: 'Database',
      options: {
        display: 'false',
        filter: false,
        customHeadRender: (columnMeta, updateDirection) => null,
        customBodyRender: (value, tableMeta, updateValue) =>
          tableMeta.rowData[10],
      },
    },
    // VIN DECODE
    {
      name: 'vindecode_library',
      label: 'Library',
      options: {
        filter: false,
        customHeadRender: (columnMeta, updateDirection) => (
          <th className='custom-header-tablehead' key={columnMeta.name}>
            <TableRow className='custom-header-tablerow'>
              <TableCell className='custom-header-tablecell' colSpan={2}>
                VIN DECODE
              </TableCell>
            </TableRow>
            <TableRow className='custom-header-tablerow'>
              <TableCell className='custom-header-tablecell'>Library</TableCell>
              <TableCell className='custom-header-tablecell'>
                Database
              </TableCell>
            </TableRow>
          </th>
        ),
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <TableRow className='custom-body-tablerow'>
              <TableCell
                style={{ display: 'inline-block' }}
                className='custom-body-tablecell'
              >
                {tableMeta.rowData[11]}
              </TableCell>
              <TableCell
                style={{ display: 'inline-block' }}
                className='custom-body-tablecell'
              >
                {tableMeta.rowData[12]}
              </TableCell>
            </TableRow>
          );
        },
      },
    },
    {
      name: 'vindecode_database',
      label: 'Database',
      options: {
        display: 'false',
        filter: false,
        customHeadRender: (columnMeta, updateDirection) => null,
        customBodyRender: (value, tableMeta, updateValue) =>
          tableMeta.rowData[12],
      },
    },
    // TripCycle/DriveCycle
    {
      name: 'cycle_library',
      label: 'Library',
      options: {
        filter: false,
        customHeadRender: (columnMeta, updateDirection) => (
          <th className='custom-header-tablehead' key={columnMeta.name}>
            <TableRow className='custom-header-tablerow'>
              <TableCell className='custom-header-tablecell' colSpan={2}>
                TripCycle/DriveCycle
              </TableCell>
            </TableRow>
            <TableRow className='custom-header-tablerow'>
              <TableCell className='custom-header-tablecell'>Library</TableCell>
              <TableCell className='custom-header-tablecell'>
                Database
              </TableCell>
            </TableRow>
          </th>
        ),
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <TableRow className='custom-body-tablerow'>
              <TableCell
                style={{ display: 'inline-block' }}
                className='custom-body-tablecell'
              >
                {tableMeta.rowData[13]}
              </TableCell>
              <TableCell
                style={{ display: 'inline-block' }}
                className='custom-body-tablecell'
              >
                {tableMeta.rowData[14]}
              </TableCell>
            </TableRow>
          );
        },
      },
    },
    {
      name: 'cycle_database',
      label: 'Database',
      options: {
        display: 'false',
        filter: false,
        customHeadRender: (columnMeta, updateDirection) => null,
        customBodyRender: (value, tableMeta, updateValue) =>
          tableMeta.rowData[14],
      },
    },

    // PossibleCauses
    {
      name: 'possiblecauses_library',
      label: 'Library',
      options: {
        filter: false,
        customHeadRender: (columnMeta, updateDirection) => (
          <th className='custom-header-tablehead' key={columnMeta.name}>
            <TableRow className='custom-header-tablerow'>
              <TableCell className='custom-header-tablecell' colSpan={2}>
                PossibleCauses
              </TableCell>
            </TableRow>
            <TableRow className='custom-header-tablerow'>
              <TableCell className='custom-header-tablecell'>Library</TableCell>
              <TableCell className='custom-header-tablecell'>
                Database
              </TableCell>
            </TableRow>
          </th>
        ),
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <TableRow className='custom-body-tablerow'>
              <TableCell
                style={{ display: 'inline-block' }}
                className='custom-body-tablecell'
              >
                {tableMeta.rowData[15]}
              </TableCell>
              <TableCell
                style={{ display: 'inline-block' }}
                className='custom-body-tablecell'
              >
                {tableMeta.rowData[16]}
              </TableCell>
            </TableRow>
          );
        },
      },
    },
    {
      name: 'possiblecauses_database',
      label: 'Database',
      options: {
        display: 'false',
        filter: false,
        customHeadRender: (columnMeta, updateDirection) => null,
        customBodyRender: (value, tableMeta, updateValue) =>
          tableMeta.rowData[16],
      },
    },

    // Oil Reset
    {
      name: 'oilreset_library',
      label: 'Library',
      options: {
        filter: false,
        customHeadRender: (columnMeta, updateDirection) => (
          <th className='custom-header-tablehead' key={columnMeta.name}>
            <TableRow className='custom-header-tablerow'>
              <TableCell className='custom-header-tablecell' colSpan={2}>
                Oil Reset
              </TableCell>
            </TableRow>
            <TableRow className='custom-header-tablerow'>
              <TableCell className='custom-header-tablecell'>Library</TableCell>
              <TableCell className='custom-header-tablecell'>
                Database
              </TableCell>
            </TableRow>
          </th>
        ),
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <TableRow className='custom-body-tablerow'>
              <TableCell
                style={{ display: 'inline-block' }}
                className='custom-body-tablecell'
              >
                {tableMeta.rowData[17]}
              </TableCell>
              <TableCell
                style={{ display: 'inline-block' }}
                className='custom-body-tablecell'
              >
                {tableMeta.rowData[18]}
              </TableCell>
            </TableRow>
          );
        },
      },
    },
    {
      name: 'oilreset_database',
      label: 'Database',
      options: {
        display: 'false',
        filter: false,
        customHeadRender: (columnMeta, updateDirection) => null,
        customBodyRender: (value, tableMeta, updateValue) =>
          tableMeta.rowData[18],
      },
    },

    // OBD1
    {
      name: 'obd1_library',
      label: 'Library',
      options: {
        filter: false,
        customHeadRender: (columnMeta, updateDirection) => (
          <th className='custom-header-tablehead' key={columnMeta.name}>
            <TableRow className='custom-header-tablerow'>
              <TableCell className='custom-header-tablecell' colSpan={2}>
                OBD1
              </TableCell>
            </TableRow>
            <TableRow className='custom-header-tablerow'>
              <TableCell className='custom-header-tablecell'>Library</TableCell>
              <TableCell className='custom-header-tablecell'>
                Database
              </TableCell>
            </TableRow>
          </th>
        ),
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <TableRow className='custom-body-tablerow'>
              <TableCell
                style={{ display: 'inline-block' }}
                className='custom-body-tablecell'
              >
                {tableMeta.rowData[19]}
              </TableCell>
              <TableCell
                style={{ display: 'inline-block' }}
                className='custom-body-tablecell'
              >
                {tableMeta.rowData[20]}
              </TableCell>
            </TableRow>
          );
        },
      },
    },
    {
      name: 'obd1_database',
      label: 'Database',
      options: {
        display: 'false',
        filter: false,
        customHeadRender: (columnMeta, updateDirection) => null,
        customBodyRender: (value, tableMeta, updateValue) =>
          tableMeta.rowData[20],
      },
    },

    // NwsDtc
    {
      name: 'nwsdtc_library',
      label: 'Library',
      options: {
        filter: false,
        customHeadRender: (columnMeta, updateDirection) => (
          <th className='custom-header-tablehead' key={columnMeta.name}>
            <TableRow className='custom-header-tablerow'>
              <TableCell className='custom-header-tablecell' colSpan={2}>
                NwsDtc
              </TableCell>
            </TableRow>
            <TableRow className='custom-header-tablerow'>
              <TableCell className='custom-header-tablecell'>Library</TableCell>
              <TableCell className='custom-header-tablecell'>
                Database
              </TableCell>
            </TableRow>
          </th>
        ),
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <TableRow className='custom-body-tablerow'>
              <TableCell
                style={{ display: 'inline-block' }}
                className='custom-body-tablecell'
              >
                {tableMeta.rowData[21]}
              </TableCell>
              <TableCell
                style={{ display: 'inline-block' }}
                className='custom-body-tablecell'
              >
                {tableMeta.rowData[22]}
              </TableCell>
            </TableRow>
          );
        },
      },
    },
    {
      name: 'nwsdtc_database',
      label: 'Database',
      options: {
        display: 'false',
        filter: false,
        customHeadRender: (columnMeta, updateDirection) => null,
        customBodyRender: (value, tableMeta, updateValue) =>
          tableMeta.rowData[22],
      },
    },

    // Protocol API
    {
      name: 'protocol_api',
      label: 'Protocol API',
      options: {
        filter: false,
        customHeadRender: (columnMeta, updateDirection) => (
          <th className='custom-header-tablehead' key={columnMeta.name}>
            <TableRow className='custom-header-tablerow'>
              <TableCell
                style={{ height: 114 }}
                className='custom-header-tablecell'
              >
                Protocol API
              </TableCell>
            </TableRow>
          </th>
        ),
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <TableRow className='custom-body-tablerow'>
              <TableCell
                style={{ display: 'inline-block' }}
                className='custom-body-tablecell'
              >
                {tableMeta.rowData[23]}
              </TableCell>
            </TableRow>
          );
        },
      },
    },
    // NWS
    {
      name: 'nws_library',
      label: 'Library',
      options: {
        filter: false,
        customHeadRender: (columnMeta, updateDirection) => (
          <th className='custom-header-tablehead' key={columnMeta.name}>
            <TableRow className='custom-header-tablerow'>
              <TableCell className='custom-header-tablecell' colSpan={2}>
                NWS
              </TableCell>
            </TableRow>
            <TableRow className='custom-header-tablerow'>
              <TableCell className='custom-header-tablecell'>Library</TableCell>
              <TableCell className='custom-header-tablecell'>
                Database
              </TableCell>
            </TableRow>
          </th>
        ),
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <TableRow className='custom-body-tablerow'>
              <TableCell
                style={{ display: 'inline-block' }}
                className='custom-body-tablecell'
              >
                {tableMeta.rowData[24]}
              </TableCell>
              <TableCell
                style={{ display: 'inline-block' }}
                className='custom-body-tablecell'
              >
                {tableMeta.rowData[25]}
              </TableCell>
            </TableRow>
          );
        },
      },
    },
    {
      name: 'nws_database',
      label: 'Database',
      options: {
        display: 'false',
        filter: false,
        customHeadRender: (columnMeta, updateDirection) => null,
        customBodyRender: (value, tableMeta, updateValue) =>
          tableMeta.rowData[25],
      },
    },
    // LaymanTerm
    {
      name: 'laymanterm_library',
      label: 'Library',
      options: {
        filter: false,
        customHeadRender: (columnMeta, updateDirection) => (
          <th className='custom-header-tablehead' key={columnMeta.name}>
            <TableRow className='custom-header-tablerow'>
              <TableCell className='custom-header-tablecell' colSpan={2}>
                LaymanTerm
              </TableCell>
            </TableRow>
            <TableRow className='custom-header-tablerow'>
              <TableCell className='custom-header-tablecell'>Library</TableCell>
              <TableCell className='custom-header-tablecell'>
                Database
              </TableCell>
            </TableRow>
          </th>
        ),
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <TableRow className='custom-body-tablerow'>
              <TableCell
                style={{ display: 'inline-block' }}
                className='custom-body-tablecell'
              >
                {tableMeta.rowData[26]}
              </TableCell>
              <TableCell
                style={{ display: 'inline-block' }}
                className='custom-body-tablecell'
              >
                {tableMeta.rowData[27]}
              </TableCell>
            </TableRow>
          );
        },
      },
    },
    {
      name: 'laymanterm_database',
      label: 'Database',
      options: {
        display: 'false',
        filter: false,
        customHeadRender: (columnMeta, updateDirection) => null,
        customBodyRender: (value, tableMeta, updateValue) =>
          tableMeta.rowData[27],
      },
    },
    // DtcSeverity
    {
      name: 'dtcseverity_library',
      label: 'Library',
      options: {
        filter: false,
        customHeadRender: (columnMeta, updateDirection) => (
          <th className='custom-header-tablehead' key={columnMeta.name}>
            <TableRow className='custom-header-tablerow'>
              <TableCell className='custom-header-tablecell' colSpan={2}>
                DtcSeverity
              </TableCell>
            </TableRow>
            <TableRow className='custom-header-tablerow'>
              <TableCell className='custom-header-tablecell'>Library</TableCell>
              <TableCell className='custom-header-tablecell'>
                Database
              </TableCell>
            </TableRow>
          </th>
        ),
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <TableRow className='custom-body-tablerow'>
              <TableCell
                style={{ display: 'inline-block' }}
                className='custom-body-tablecell'
              >
                {tableMeta.rowData[28]}
              </TableCell>
              <TableCell
                style={{ display: 'inline-block' }}
                className='custom-body-tablecell'
              >
                {tableMeta.rowData[29]}
              </TableCell>
            </TableRow>
          );
        },
      },
    },
    {
      name: 'dtcseverity_database',
      label: 'Database',
      options: {
        display: 'false',
        filter: false,
        customHeadRender: (columnMeta, updateDirection) => null,
        customBodyRender: (value, tableMeta, updateValue) =>
          tableMeta.rowData[29],
      },
    },
    // Dtc
    {
      name: 'dtc_library',
      label: 'Library',
      options: {
        filter: false,
        customHeadRender: (columnMeta, updateDirection) => (
          <th className='custom-header-tablehead' key={columnMeta.name}>
            <TableRow className='custom-header-tablerow'>
              <TableCell className='custom-header-tablecell' colSpan={2}>
                Dtc
              </TableCell>
            </TableRow>
            <TableRow className='custom-header-tablerow'>
              <TableCell className='custom-header-tablecell'>Library</TableCell>
              <TableCell className='custom-header-tablecell'>
                Database
              </TableCell>
            </TableRow>
          </th>
        ),
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <TableRow className='custom-body-tablerow'>
              <TableCell
                style={{ display: 'inline-block' }}
                className='custom-body-tablecell'
              >
                {tableMeta.rowData[30]}
              </TableCell>
              <TableCell
                style={{ display: 'inline-block' }}
                className='custom-body-tablecell'
              >
                {tableMeta.rowData[31]}
              </TableCell>
            </TableRow>
          );
        },
      },
    },
    {
      name: 'dtc_database',
      label: 'Database',
      options: {
        display: 'false',
        filter: false,
        customHeadRender: (columnMeta, updateDirection) => null,
        customBodyRender: (value, tableMeta, updateValue) =>
          tableMeta.rowData[31],
      },
    },
    // DLC
    {
      name: 'dlc_library',
      label: 'Library',
      options: {
        filter: false,
        customHeadRender: (columnMeta, updateDirection) => (
          <th className='custom-header-tablehead' key={columnMeta.name}>
            <TableRow className='custom-header-tablerow'>
              <TableCell className='custom-header-tablecell' colSpan={2}>
                DLC
              </TableCell>
            </TableRow>
            <TableRow className='custom-header-tablerow'>
              <TableCell className='custom-header-tablecell'>Library</TableCell>
              <TableCell className='custom-header-tablecell'>
                Database
              </TableCell>
            </TableRow>
          </th>
        ),
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <TableRow className='custom-body-tablerow'>
              <TableCell
                style={{ display: 'inline-block' }}
                className='custom-body-tablecell'
              >
                {tableMeta.rowData[32]}
              </TableCell>
              <TableCell
                style={{ display: 'inline-block' }}
                className='custom-body-tablecell'
              >
                {tableMeta.rowData[33]}
              </TableCell>
            </TableRow>
          );
        },
      },
    },
    {
      name: 'dlc_database',
      label: 'Database',
      options: {
        display: 'false',
        filter: false,
        customHeadRender: (columnMeta, updateDirection) => null,
        customBodyRender: (value, tableMeta, updateValue) =>
          tableMeta.rowData[33],
      },
    },
    // Battery Reset
    {
      name: 'batteryreset_library',
      label: 'Library',
      options: {
        filter: false,
        customHeadRender: (columnMeta, updateDirection) => (
          <th className='custom-header-tablehead' key={columnMeta.name}>
            <TableRow className='custom-header-tablerow'>
              <TableCell className='custom-header-tablecell' colSpan={2}>
                Battery Reset
              </TableCell>
            </TableRow>
            <TableRow className='custom-header-tablerow'>
              <TableCell className='custom-header-tablecell'>Library</TableCell>
              <TableCell className='custom-header-tablecell'>
                Database
              </TableCell>
            </TableRow>
          </th>
        ),
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <TableRow className='custom-body-tablerow'>
              <TableCell
                style={{ display: 'inline-block' }}
                className='custom-body-tablecell'
              >
                {tableMeta.rowData[34]}
              </TableCell>
              <TableCell
                style={{ display: 'inline-block' }}
                className='custom-body-tablecell'
              >
                {tableMeta.rowData[35]}
              </TableCell>
            </TableRow>
          );
        },
      },
    },
    {
      name: 'batteryreset_database',
      label: 'Database',
      options: {
        display: 'false',
        filter: false,
        customHeadRender: (columnMeta, updateDirection) => null,
        customBodyRender: (value, tableMeta, updateValue) =>
          tableMeta.rowData[35],
      },
    },
    // ABS/SRS Live Data
    {
      name: 'abssrslivedata_library',
      label: 'Library',
      options: {
        filter: false,
        customHeadRender: (columnMeta, updateDirection) => (
          <th className='custom-header-tablehead' key={columnMeta.name}>
            <TableRow className='custom-header-tablerow'>
              <TableCell className='custom-header-tablecell' colSpan={2}>
                ABS/SRS Live Data
              </TableCell>
            </TableRow>
            <TableRow className='custom-header-tablerow'>
              <TableCell className='custom-header-tablecell'>Library</TableCell>
              <TableCell className='custom-header-tablecell'>
                Database
              </TableCell>
            </TableRow>
          </th>
        ),
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <TableRow className='custom-body-tablerow'>
              <TableCell
                style={{ display: 'inline-block' }}
                className='custom-body-tablecell'
              >
                {tableMeta.rowData[36]}
              </TableCell>
              <TableCell
                style={{ display: 'inline-block' }}
                className='custom-body-tablecell'
              >
                {tableMeta.rowData[37]}
              </TableCell>
            </TableRow>
          );
        },
      },
    },
    {
      name: 'abssrslivedata_database',
      label: 'Database',
      options: {
        display: 'false',
        filter: false,
        customHeadRender: (columnMeta, updateDirection) => null,
        customBodyRender: (value, tableMeta, updateValue) =>
          tableMeta.rowData[37],
      },
    },
    // OBD2 APP
    {
      name: 'obd2app_library',
      label: 'Library',
      options: {
        filter: false,
        customHeadRender: (columnMeta, updateDirection) => (
          <th className='custom-header-tablehead' key={columnMeta.name}>
            <TableRow className='custom-header-tablerow'>
              <TableCell className='custom-header-tablecell' colSpan={2}>
                OBD2 APP
              </TableCell>
            </TableRow>
            <TableRow className='custom-header-tablerow'>
              <TableCell className='custom-header-tablecell'>Library</TableCell>
              <TableCell className='custom-header-tablecell'>
                Database
              </TableCell>
            </TableRow>
          </th>
        ),
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <TableRow className='custom-body-tablerow'>
              <TableCell
                style={{ display: 'inline-block' }}
                className='custom-body-tablecell'
              >
                {tableMeta.rowData[38]}
              </TableCell>
              <TableCell
                style={{ display: 'inline-block' }}
                className='custom-body-tablecell'
              >
                {tableMeta.rowData[39]}
              </TableCell>
            </TableRow>
          );
        },
      },
    },
    {
      name: 'obd2app_database',
      label: 'Database',
      options: {
        display: 'false',
        filter: false,
        customHeadRender: (columnMeta, updateDirection) => null,
        customBodyRender: (value, tableMeta, updateValue) =>
          tableMeta.rowData[39],
      },
    },
    // Active Test
    {
      name: 'activetest_library',
      label: 'Library',
      options: {
        filter: false,
        customHeadRender: (columnMeta, updateDirection) => (
          <th className='custom-header-tablehead' key={columnMeta.name}>
            <TableRow className='custom-header-tablerow'>
              <TableCell className='custom-header-tablecell' colSpan={2}>
                Active Test
              </TableCell>
            </TableRow>
            <TableRow className='custom-header-tablerow'>
              <TableCell className='custom-header-tablecell'>Library</TableCell>
              <TableCell className='custom-header-tablecell'>
                Database
              </TableCell>
            </TableRow>
          </th>
        ),
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <TableRow className='custom-body-tablerow'>
              <TableCell
                style={{ display: 'inline-block' }}
                className='custom-body-tablecell'
              >
                {tableMeta.rowData[40]}
              </TableCell>
              <TableCell
                style={{ display: 'inline-block' }}
                className='custom-body-tablecell'
              >
                {tableMeta.rowData[41]}
              </TableCell>
            </TableRow>
          );
        },
      },
    },
    {
      name: 'activetest_database',
      label: 'Database',
      options: {
        display: 'false',
        filter: false,
        customHeadRender: (columnMeta, updateDirection) => null,
        customBodyRender: (value, tableMeta, updateValue) =>
          tableMeta.rowData[41],
      },
    },
    // SAS Calib
    {
      name: 'sascalib_library',
      label: 'Library',
      options: {
        filter: false,
        customHeadRender: (columnMeta, updateDirection) => (
          <th className='custom-header-tablehead' key={columnMeta.name}>
            <TableRow className='custom-header-tablerow'>
              <TableCell className='custom-header-tablecell' colSpan={2}>
                SAS Calib
              </TableCell>
            </TableRow>
            <TableRow className='custom-header-tablerow'>
              <TableCell className='custom-header-tablecell'>Library</TableCell>
              <TableCell className='custom-header-tablecell'>
                Database
              </TableCell>
            </TableRow>
          </th>
        ),
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <TableRow className='custom-body-tablerow'>
              <TableCell
                style={{ display: 'inline-block' }}
                className='custom-body-tablecell'
              >
                {tableMeta.rowData[42]}
              </TableCell>
              <TableCell
                style={{ display: 'inline-block' }}
                className='custom-body-tablecell'
              >
                {tableMeta.rowData[43]}
              </TableCell>
            </TableRow>
          );
        },
      },
    },
    {
      name: 'sascalib_database',
      label: 'Database',
      options: {
        display: 'false',
        filter: false,
        customHeadRender: (columnMeta, updateDirection) => null,
        customBodyRender: (value, tableMeta, updateValue) =>
          tableMeta.rowData[43],
      },
    },
    // Special Function
    {
      name: 'specialfunc_library',
      label: 'Library',
      options: {
        filter: false,
        customHeadRender: (columnMeta, updateDirection) => (
          <th className='custom-header-tablehead' key={columnMeta.name}>
            <TableRow className='custom-header-tablerow'>
              <TableCell className='custom-header-tablecell' colSpan={2}>
                Special Function
              </TableCell>
            </TableRow>
            <TableRow className='custom-header-tablerow'>
              <TableCell className='custom-header-tablecell'>Library</TableCell>
              <TableCell className='custom-header-tablecell'>
                Database
              </TableCell>
            </TableRow>
          </th>
        ),
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <TableRow className='custom-body-tablerow'>
              <TableCell
                style={{ display: 'inline-block' }}
                className='custom-body-tablecell'
              >
                {tableMeta.rowData[44]}
              </TableCell>
              <TableCell
                style={{ display: 'inline-block' }}
                className='custom-body-tablecell'
              >
                {tableMeta.rowData[45]}
              </TableCell>
            </TableRow>
          );
        },
      },
    },
    {
      name: 'specialfunc_database',
      label: 'Database',
      options: {
        display: 'false',
        filter: false,
        customHeadRender: (columnMeta, updateDirection) => null,
        customBodyRender: (value, tableMeta, updateValue) =>
          tableMeta.rowData[45],
      },
    },
    // EPB
    {
      name: 'epb_library',
      label: 'Library',
      options: {
        filter: false,
        customHeadRender: (columnMeta, updateDirection) => (
          <th className='custom-header-tablehead' key={columnMeta.name}>
            <TableRow className='custom-header-tablerow'>
              <TableCell className='custom-header-tablecell' colSpan={2}>
                EPB
              </TableCell>
            </TableRow>
            <TableRow className='custom-header-tablerow'>
              <TableCell className='custom-header-tablecell'>Library</TableCell>
              <TableCell className='custom-header-tablecell'>
                Database
              </TableCell>
            </TableRow>
          </th>
        ),
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <TableRow className='custom-body-tablerow'>
              <TableCell
                style={{ display: 'inline-block' }}
                className='custom-body-tablecell'
              >
                {tableMeta.rowData[46]}
              </TableCell>
              <TableCell
                style={{ display: 'inline-block' }}
                className='custom-body-tablecell'
              >
                {tableMeta.rowData[47]}
              </TableCell>
            </TableRow>
          );
        },
      },
    },
    {
      name: 'epb_database',
      label: 'Database',
      options: {
        display: 'false',
        filter: false,
        customHeadRender: (columnMeta, updateDirection) => null,
        customBodyRender: (value, tableMeta, updateValue) =>
          tableMeta.rowData[47],
      },
    },
    // Oil Reset SF
    {
      name: 'oilresetsf_library',
      label: 'Library',
      options: {
        filter: false,
        customHeadRender: (columnMeta, updateDirection) => (
          <th className='custom-header-tablehead' key={columnMeta.name}>
            <TableRow className='custom-header-tablerow'>
              <TableCell className='custom-header-tablecell' colSpan={2}>
                Oil Reset SF
              </TableCell>
            </TableRow>
            <TableRow className='custom-header-tablerow'>
              <TableCell className='custom-header-tablecell'>Library</TableCell>
              <TableCell className='custom-header-tablecell'>
                Database
              </TableCell>
            </TableRow>
          </th>
        ),
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <TableRow className='custom-body-tablerow'>
              <TableCell
                style={{ display: 'inline-block' }}
                className='custom-body-tablecell'
              >
                {tableMeta.rowData[48]}
              </TableCell>
              <TableCell
                style={{ display: 'inline-block' }}
                className='custom-body-tablecell'
              >
                {tableMeta.rowData[49]}
              </TableCell>
            </TableRow>
          );
        },
      },
    },
    {
      name: 'oilresetsf_database',
      label: 'Database',
      options: {
        display: 'false',
        filter: false,
        customHeadRender: (columnMeta, updateDirection) => null,
        customBodyRender: (value, tableMeta, updateValue) =>
          tableMeta.rowData[49],
      },
    },
    // Battery SF
    {
      name: 'batterysf_library',
      label: 'Library',
      options: {
        filter: false,
        customHeadRender: (columnMeta, updateDirection) => (
          <th className='custom-header-tablehead' key={columnMeta.name}>
            <TableRow className='custom-header-tablerow'>
              <TableCell className='custom-header-tablecell' colSpan={2}>
                Battery SF
              </TableCell>
            </TableRow>
            <TableRow className='custom-header-tablerow'>
              <TableCell className='custom-header-tablecell'>Library</TableCell>
              <TableCell className='custom-header-tablecell'>
                Database
              </TableCell>
            </TableRow>
          </th>
        ),
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <TableRow className='custom-body-tablerow'>
              <TableCell
                style={{ display: 'inline-block' }}
                className='custom-body-tablecell'
              >
                {tableMeta.rowData[50]}
              </TableCell>
              <TableCell
                style={{ display: 'inline-block' }}
                className='custom-body-tablecell'
              >
                {tableMeta.rowData[51]}
              </TableCell>
            </TableRow>
          );
        },
      },
    },
    {
      name: 'batterysf_database',
      label: 'Database',
      options: {
        display: 'false',
        filter: false,
        customHeadRender: (columnMeta, updateDirection) => null,
        customBodyRender: (value, tableMeta, updateValue) =>
          tableMeta.rowData[51],
      },
    },
    // TPMS Live Data
    {
      name: 'tpmslivedata_library',
      label: 'Library',
      options: {
        filter: false,
        customHeadRender: (columnMeta, updateDirection) => (
          <th className='custom-header-tablehead' key={columnMeta.name}>
            <TableRow className='custom-header-tablerow'>
              <TableCell className='custom-header-tablecell' colSpan={2}>
                TPMS Live Data
              </TableCell>
            </TableRow>
            <TableRow className='custom-header-tablerow'>
              <TableCell className='custom-header-tablecell'>Library</TableCell>
              <TableCell className='custom-header-tablecell'>
                Database
              </TableCell>
            </TableRow>
          </th>
        ),
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <TableRow className='custom-body-tablerow'>
              <TableCell
                style={{ display: 'inline-block' }}
                className='custom-body-tablecell'
              >
                {tableMeta.rowData[52]}
              </TableCell>
              <TableCell
                style={{ display: 'inline-block' }}
                className='custom-body-tablecell'
              >
                {tableMeta.rowData[53]}
              </TableCell>
            </TableRow>
          );
        },
      },
    },
    {
      name: 'tpmslivedata_database',
      label: 'Database',
      options: {
        display: 'false',
        filter: false,
        customHeadRender: (columnMeta, updateDirection) => null,
        customBodyRender: (value, tableMeta, updateValue) =>
          tableMeta.rowData[53],
      },
    },
    //  TCM Live Data
    {
      name: 'tcmlivedate_library',
      label: 'Library',
      options: {
        filter: false,
        customHeadRender: (columnMeta, updateDirection) => (
          <th className='custom-header-tablehead' key={columnMeta.name}>
            <TableRow className='custom-header-tablerow'>
              <TableCell className='custom-header-tablecell' colSpan={2}>
                TCM Live Data
              </TableCell>
            </TableRow>
            <TableRow className='custom-header-tablerow'>
              <TableCell className='custom-header-tablecell'>Library</TableCell>
              <TableCell className='custom-header-tablecell'>
                Database
              </TableCell>
            </TableRow>
          </th>
        ),
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <TableRow className='custom-body-tablerow'>
              <TableCell
                style={{ display: 'inline-block' }}
                className='custom-body-tablecell'
              >
                {tableMeta.rowData[54]}
              </TableCell>
              <TableCell
                style={{ display: 'inline-block' }}
                className='custom-body-tablecell'
              >
                {tableMeta.rowData[55]}
              </TableCell>
            </TableRow>
          );
        },
      },
    },
    {
      name: 'tcmlivedate_database',
      label: 'Database',
      options: {
        display: 'false',
        filter: false,
        customHeadRender: (columnMeta, updateDirection) => null,
        customBodyRender: (value, tableMeta, updateValue) =>
          tableMeta.rowData[55],
      },
    },
    // TPMS Procedure
    {
      name: 'tpmsprocedure_library',
      label: 'Library',
      options: {
        filter: false,
        customHeadRender: (columnMeta, updateDirection) => (
          <th className='custom-header-tablehead' key={columnMeta.name}>
            <TableRow className='custom-header-tablerow'>
              <TableCell className='custom-header-tablecell' colSpan={2}>
                TPMS Procedure
              </TableCell>
            </TableRow>
            <TableRow className='custom-header-tablerow'>
              <TableCell className='custom-header-tablecell'>Library</TableCell>
              <TableCell className='custom-header-tablecell'>
                Database
              </TableCell>
            </TableRow>
          </th>
        ),
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <TableRow className='custom-body-tablerow'>
              <TableCell
                style={{ display: 'inline-block' }}
                className='custom-body-tablecell'
              >
                {tableMeta.rowData[56]}
              </TableCell>
              <TableCell
                style={{ display: 'inline-block' }}
                className='custom-body-tablecell'
              >
                {tableMeta.rowData[57]}
              </TableCell>
            </TableRow>
          );
        },
      },
    },
    {
      name: 'tpmsprocedure_database',
      label: 'Database',
      options: {
        display: 'false',
        filter: false,
        customHeadRender: (columnMeta, updateDirection) => null,
        customBodyRender: (value, tableMeta, updateValue) =>
          tableMeta.rowData[57],
      },
    },
    // OFM
    {
      name: 'ofm_library',
      label: 'Library',
      options: {
        filter: false,
        customHeadRender: (columnMeta, updateDirection) => (
          <th className='custom-header-tablehead' key={columnMeta.name}>
            <TableRow className='custom-header-tablerow'>
              <TableCell className='custom-header-tablecell' colSpan={2}>
                OFM
              </TableCell>
            </TableRow>
            <TableRow className='custom-header-tablerow'>
              <TableCell className='custom-header-tablecell'>Library</TableCell>
              <TableCell className='custom-header-tablecell'>
                Database
              </TableCell>
            </TableRow>
          </th>
        ),
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <TableRow className='custom-body-tablerow'>
              <TableCell
                style={{ display: 'inline-block' }}
                className='custom-body-tablecell'
              >
                {tableMeta.rowData[58]}
              </TableCell>
              <TableCell
                style={{ display: 'inline-block' }}
                className='custom-body-tablecell'
              >
                {tableMeta.rowData[59]}
              </TableCell>
            </TableRow>
          );
        },
      },
    },
    {
      name: 'ofm_database',
      label: 'Database',
      options: {
        display: 'false',
        filter: false,
        customHeadRender: (columnMeta, updateDirection) => null,
        customBodyRender: (value, tableMeta, updateValue) =>
          tableMeta.rowData[59],
      },
    },
    // Mode06
    {
      name: 'mode06_library',
      label: 'Library',
      options: {
        filter: false,
        customHeadRender: (columnMeta, updateDirection) => (
          <th className='custom-header-tablehead' key={columnMeta.name}>
            <TableRow className='custom-header-tablerow'>
              <TableCell className='custom-header-tablecell' colSpan={2}>
                Mode06
              </TableCell>
            </TableRow>
            <TableRow className='custom-header-tablerow'>
              <TableCell className='custom-header-tablecell'>Library</TableCell>
              <TableCell className='custom-header-tablecell'>
                Database
              </TableCell>
            </TableRow>
          </th>
        ),
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <TableRow className='custom-body-tablerow'>
              <TableCell
                style={{ display: 'inline-block' }}
                className='custom-body-tablecell'
              >
                {tableMeta.rowData[60]}
              </TableCell>
              <TableCell
                style={{ display: 'inline-block' }}
                className='custom-body-tablecell'
              >
                {tableMeta.rowData[61]}
              </TableCell>
            </TableRow>
          );
        },
      },
    },
    {
      name: 'mode06_database',
      label: 'Database',
      options: {
        display: 'false',
        filter: false,
        customHeadRender: (columnMeta, updateDirection) => null,
        customBodyRender: (value, tableMeta, updateValue) =>
          tableMeta.rowData[61],
      },
    },
    {
      name: 'versionId',
      label: 'versionId',
      options: {
        display: 'false',
        filter: false,
        download: false,
      },
    },
    {
      name: 'toolId',
      label: 'toolId',
      options: {
        display: 'false',
        filter: false,
        download: false,
      },
    },
    {
      name: 'groupName',
      label: 'Group',
      options: {
        display: 'false',
        filter: false,
      },
    },
  ];
  private rowsPerPage = 10;

  changePage = (pageNumber) => {
    if (this.tableRef.current) {
      //@ts-ignore
      this.tableRef.current.changePage(pageNumber);
    }
  };

  constructor(props) {
    super(props);
    this.isVersionsOfGroupTable =
      this.props.match.path === '/version-tracking/group/:id';
    this.state = {
      groupId: this.props.match.params.id || '',
      dataversion: [],
      groupname: '',
      // state for tool
      toolId: this.props.match.params.id || '',
      toolName: '',
      isLoading: false,
      isLoading1: false,
      openConfirmDeleteVersion: false,
      openResultDialog: false,
      resultInfo: '',
      errorMsg: '',
      toolIdsOfRole: [],
    };
    this.inputExcel = React.createRef();
    this.groupService = serviceFactory.getGroupService();
    this.vsService = serviceFactory.getVSTrackingService();
    this.vsStatusService = serviceFactory.getStatusVSTrackingService();
    this.roleService = serviceFactory.getRoleService();
  }

  componentWillReceiveProps(nextProps) {
    this.isVersionsOfGroupTable =
      nextProps.match.path === '/version-tracking/group/:id';
    this.setState(
      {
        groupId: nextProps.match.params.id,
        toolId: nextProps.match.params.id,
        dataversion: [],
        groupname: '',
        toolName: '',
      },
      () => {
        this.componentDidMount();
      }
    );
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.onRouteChanged(this.props.location);
    }
  }

  onRouteChanged(location) {
    this.isVersionsOfGroupTable = location.pathname.startsWith(
      '/version-tracking/group'
    );
    this.setState(
      {
        groupId: location.pathname.split('/')[3],
        toolId: location.pathname.split('/')[3],
        dataversion: [],
        groupname: '',
        toolName: '',
      },
      () => {
        this.componentDidMount();
      }
    );
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.toolIdsOfRole !== prevState.toolIdsOfRole) {
      return { toolIdsOfRole: nextProps.toolIdsOfRole };
    }
    return null;
  }
  // messeage delete
  handleDeleteVersion(arrayIdVersion): void {
    this.arrayIdVersion = arrayIdVersion;
    this.setState({
      openConfirmDeleteVersion: true,
    });
  }
  handleCloseConfirm = () => {
    this.setState({
      openConfirmDeleteVersion: false,
    });
  };
  handleCloseResultDialog = () => {
    this.setState(
      {
        openResultDialog: false,
      },
      () => {
        window.location.reload();
      }
    );
  };
  handleAcceptDeleleteVersion = () => {
    this.setState(
      {
        openConfirmDeleteVersion: false,
      },
      () => {
        this.versionTrackingService
          .deleteVersions(this.arrayIdVersion)
          .subscribe(async (res) => {
            if (res.response && res.response.data.error) {
              this.setState({ error: res.response.data.error });
              console.error(res.response.data.error);
              // window.alert(res.response.data.error);
              this.setState({
                openResultDialog: true,
                isDeleteVersionSuccess: false,
                resultInfo: `Can not delete these version(s):\n${res.response.data.error}`,
              });
            } else {
              this.arrayIdVersion = undefined;
              this.setState({
                openResultDialog: true,
                isDeleteVersionSuccess: true,
                resultInfo: `Delete version(s) successful`,
              });
            }
          });
      }
    );
  };

  handleImportDataToGroupTable = async () => {
    const groupnameSelect = this.state.groupname;
    const groupId = this.state.groupId;
    this.excelData.splice(0, 2);
    let isError = false;
    let errorMsg = '';
    for (const rowitem of this.excelData) {
      if (groupnameSelect === rowitem[0].trim()) {
        // neu co ten group trong excel
        try {
          // xet xet tool hien tai trong excel co trong group hay khong
          // neu co thi tien hanh add version
          // neu khong co thi tien hanh tao tool-version cua group
          const rowToolName = rowitem[1];
          const res1 = await this.toolService
            .searchTool({ toolName: sanitizeASCIIString(rowToolName) })
            .toPromise();
          if (res1.response && res1.response.data.error) {
            // error checking
            throw res1.response.data.error;
          }
          if (
            res1.data &&
            res1.data.data &&
            res1.data.data.groupId === this.state.groupId
          ) {
            await this.addVersionToExistingTool(rowitem, res1.data.data);
          }
        } catch (error) {
          isError = true;
          errorMsg += '\n' + error;
        }
      } else {
        errorMsg +=
          '\n' + 'This Group ' + `"${rowitem[0]}"` + ' does not exist';
      }
    }
    if (errorMsg) {
      this.setState({ errorMsg });
    }
    this.props.dispatch(fetchGroupAndToolData());
    this.setState(
      {
        isLoading: false,
      },
      () => {
        this.componentDidMount();
      }
    );
  };

  handleImportDataToToolTable = async () => {
    const toolnameSelect = this.state.toolName;
    const toolId = this.state.toolId;
    const res = await this.toolService.getOneTool(toolId).toPromise();
    if (res.response && res.response.data.error) {
      // error checking
      alert(res.response.data.error);
      return;
    }
    const groupnameoftool = res.data.data.groupId.groupName; // ten group cua tool hien tai
    const groupId = res.data.data.groupId._id;
    this.excelData.splice(0, 2);
    let isError = false;
    let errorMsg = '';
    for (const rowitem of this.excelData) {
      if (groupnameoftool === sanitizeASCIIString(rowitem[0])) {
        // neu co ten group trong excel
        try {
          // xet xet tool hien tai trong excel co trong group hay khong
          // neu co thi tien hanh add version
          // neu khong co thi tien hanh tao tool-version cua group
          const rowToolName = rowitem[1];
          const res1 = await this.toolService
            .searchTool({ toolName: sanitizeASCIIString(rowToolName) })
            .toPromise();
          if (res1.response && res1.response.data.error) {
            // error checking
            throw res1.response.data.error;
          }
          if (res1.data && res1.data.data && res1.data.data._id === toolId) {
            await this.addVersionToExistingTool(rowitem, res1.data.data);
          }
        } catch (error) {
          isError = true;
          errorMsg += '\n' + error;
        }
      } else {
        errorMsg +=
          '\n' + 'This Group ' + `"${rowitem[0]}"` + ' does not exist';
      }
    }
    if (errorMsg) {
      this.setState({ errorMsg });
    }
    this.props.dispatch(fetchGroupAndToolData());
    this.componentDidMount();
    this.setState({
      isLoading: false,
    });
  };

  private async createNewGroup(groupModel: { groupName: any }) {
    try {
      return await this.groupService
        .createOrGetExistingGroup(groupModel)
        .toPromise();
    } catch (error) {
      console.error('error', error);
    }
  }

  private async createNewTool(bodyCreateTool: { toolName: any; groupId: any }) {
    try {
      return await this.toolService
        .createOrGetExistingToolOnGroup(bodyCreateTool)
        .toPromise();
    } catch (error) {
      console.error('error', error);
    }
  }

  private async addVersionToExistingTool(rowitem: any, tool: any) {
    try {
      const status: any = await this.vsStatusService
        .searchOrCreateNewStatus({
          name: sanitizeASCIIString(rowitem[7]),
        })
        .toPromise();
      if (status.response && status.response.data.error) {
        // error checking
        throw status.response.data.error;
      }
      const versionModel = this.createVersionModel(rowitem, status.data.data);
      const versionCreated = await this.vsService
        .createVersionOfTool(tool._id, versionModel)
        .toPromise();
      if (versionCreated.response && versionCreated.response.data.error) {
        // error checking
        throw versionCreated.response.data.error;
      }
    } catch (error) {
      throw error;
    }
  }

  private createVersionModel(rowitem: any, status: any) {
    if (!isValidDate(new Date(rowitem[2]))) {
      throw new Error(
        `${rowitem[2]} is not a valid date. Need import right Date format (Month/Day/Year)!`
      );
    }

    return {
      date: new Date(rowitem[2]),
      fw:
        typeof rowitem[3] == 'string'
          ? sanitizeASCIIString(rowitem[3])
          : rowitem[3] || 'N/A',
      database: rowitem[4] || 'N/A',
      bootloader: rowitem[5] || 'N/A',
      changes: rowitem[6] || 'N/A',
      statusVersionId: status._id,
      userinterface_version: rowitem[8] || 'N/A',
      manual_version: rowitem[9] || 'N/A',
      ymme_library: rowitem[10] || 'N/A',
      ymme_database: rowitem[11] || 'N/A',
      vindecode_library: rowitem[12] || 'N/A',
      vindecode_database: rowitem[13] || 'N/A',
      cycle_library: rowitem[14] || 'N/A',
      cycle_database: rowitem[15] || 'N/A',
      possiblecauses_library: rowitem[16] || 'N/A',
      possiblecauses_database: rowitem[17] || 'N/A',
      oilreset_library: rowitem[18] || 'N/A',
      oilreset_database: rowitem[19] || 'N/A',
      obd1_library: rowitem[20] || 'N/A',
      obd1_database: rowitem[21] || 'N/A',
      nwsdtc_library: rowitem[22] || 'N/A',
      nwsdtc_database: rowitem[23] || 'N/A',
      protocol_api: rowitem[24] || 'N/A',
      nws_library: rowitem[25] || 'N/A',
      nws_database: rowitem[26] || 'N/A',
      laymanterm_library: rowitem[27] || 'N/A',
      laymanterm_database: rowitem[28] || 'N/A',
      dtcseverity_library: rowitem[29] || 'N/A',
      dtcseverity_database: rowitem[30] || 'N/A',
      dtc_library: rowitem[31] || 'N/A',
      dtc_database: rowitem[32] || 'N/A',
      dlc_library: rowitem[33] || 'N/A',
      dlc_database: rowitem[34] || 'N/A',
      batteryreset_library: rowitem[35] || 'N/A',
      batteryreset_database: rowitem[36] || 'N/A',
      abssrslivedata_library: rowitem[37] || 'N/A',
      abssrslivedata_database: rowitem[38] || 'N/A',
      obd2app_library: rowitem[39] || 'N/A',
      obd2app_database: rowitem[40] || 'N/A',
      activetest_library: rowitem[41] || 'N/A',
      activetest_database: rowitem[42] || 'N/A',
      sascalib_library: rowitem[43] || 'N/A',
      sascalib_database: rowitem[44] || 'N/A',
      specialfunc_library: rowitem[45] || 'N/A',
      specialfunc_database: rowitem[46] || 'N/A',
      epb_library: rowitem[47] || 'N/A',
      epb_database: rowitem[48] || 'N/A',
      oilresetsf_library: rowitem[49] || 'N/A',
      oilresetsf_database: rowitem[50] || 'N/A',
      batterysf_library: rowitem[51] || 'N/A',
      batterysf_database: rowitem[52] || 'N/A',
      tpmslivedata_library: rowitem[53] || 'N/A',
      tpmslivedata_database: rowitem[54] || 'N/A',
      tcmlivedate_library: rowitem[55] || 'N/A',
      tcmlivedate_database: rowitem[56] || 'N/A',
      tpmsprocedure_library: rowitem[57] || 'N/A',
      tpmsprocedure_database: rowitem[58] || 'N/A',
      ofm_library: rowitem[59] || 'N/A',
      ofm_database: rowitem[60] || 'N/A',
      mode06_library: rowitem[61] || 'N/A',
      mode06_database: rowitem[62] || 'N/A',
    };
  }

  componentDidMount() {
    this.handleRole();
    this.setState({ isLoading: true });
    if (this.isVersionsOfGroupTable) {
      this.toolService
        .getAllToolsByGroupId(this.state.groupId)
        .subscribe((res) => {
          if (res.response && res.response.data.error) {
            this.setState({ error: res.response.data.error });
            console.error(res.response.data.error);
            window.alert(res.response.data.error);
            this.setState({ isLoading: false });
          } else {
            this.setState({
              dataversion: res.data.data,
              groupname: res.data.data[0].groupId.groupName,
              isLoading: false,
            });
            this.props.dispatch(
              groupActions.selectGroup({
                // ...group,
                title: res.data.data[0].groupId.groupName,
                id: res.data.data[0].groupId.groupId,
              })
            );
          }
        });
    } else {
      this.toolService.getOneTool(this.state.toolId).subscribe((res) => {
        if (res.response && res.response.data.error) {
          this.setState({ error: res.response.data.error });
          console.error(res.response.data.error);
          window.alert(res.response.data.error);
          this.setState({ isLoading: false });
        } else {
          this.setState({
            dataversion: [res.data.data],
            toolName: res.data.data.toolName,
            isLoading: false,
          });
          this.props.dispatch(
            toolActions.selectTool({
              ...res.data.data,
              title: res.data.data.toolName,
              id: res.data.data._id,
            })
          );
        }
      });
    }
  }

  handleReadExcelFile = async (e) => {
    this.setState({
      isLoading: true,
    });
    e.stopPropagation();
    const input = e.target;
    if (!input) {
      return;
    }
    // input.value = '';
    // input.click();
    if (input.files && input.files.length > 0) {
      try {
        const rows = await readXlsxFile(input.files[0]);
        this.excelData = rows; // luu du lieu file excel
        // kiem tra bang hien tai la bang group hay bang tool
        if (this.isVersionsOfGroupTable) {
          await this.handleImportDataToGroupTable();
        } else {
          await this.handleImportDataToToolTable();
        }
        // B1: Luu du lieu trong file excel: done
        // B2:* Neu import vo bang group:
        // - Lay ra ten group hien tai trong this.props
        // - Tim ten group co trong file excel khong?
        //        + Neu co
        //            add them cac tool + version neu tool hien tai khong nam trong excel,
        //            neu tool hien tai o file excel thi tien hanh them version vao tool do
        //        + Neu khong thi tien hanh tao group -> tool -> version

        //   * Neu import vao bang tool
        // Lay ra ten group, ten tool hien tai trong this.props
        // Tim ten group coi co trong excel k?
        //  neu khong co thi lam nhu tren tao group -> tool -> version
        //  Neu co group:
        //    Add cac version vao cac tool tuong ung neu tool hien tai co trong file excel,
        //    neu khong co tool thi tao tool -> version
        // Goi API cac thao tac o tren
      } catch (err) {
        alert(err);
        this.excelData = undefined;
        this.setState({
          isLoading: false,
        });
      } finally {
        input.value = '';
        this.setState({
          isLoading: false,
        });
      }
    }
  };
  handleRole = () => {
    this.setState({
      isLoading1: true,
    });
    if (this.state.toolIdsOfRole.length == 0) {
      const data = localStorage.getItem('userInfo');
      const userInfo = JSON.parse(data);
      const nameRole = userInfo.role;
      const body = {
        nameRole,
      };
      this.roleService.getToolThatRole(body).subscribe((res) => {
        if (res.response && res.response.data.error) {
          this.setState({ error: res.response.data.error });
          window.alert(res.response.data.error);
        } else {
          const ids = res.data.data.map((role) => role['_id']);
          this.props.dispatch(groupActions.storeRoleByToolIds(ids));
          this.setState({
            toolIdsOfRole: ids,
          });
        }
        this.setState({
          isLoading1: false,
        });
      });
    } else {
      this.setState({
        isLoading1: false,
      });
    }
  };

  render() {
    let indexOfFW = -1;
    let versionClone = {};
    const FW = this.props.history.location.state?.FW || '';
    const MapData = this.state.dataversion.map((tool) =>
      tool.version.map((version, index) => {
        if (version['fw'] === FW) indexOfFW = index;
        versionClone = { ...version };
        const versionId = versionClone['_id'];
        const toolId = versionClone['idTool'];
        delete versionClone['idTool'];
        delete versionClone['__v'];
        delete versionClone['_id'];
        versionClone['date'] = new Date(
          versionClone['date']
        ).toLocaleDateString('en-US');
        versionClone['statusversion'] =
          (typeof versionClone['statusversion'] === 'string' &&
            versionClone['statusversion']) ||
          versionClone['statusversion'].name; // object

        const date = versionClone['date'];
        const fw = versionClone['fw'];
        const database = versionClone['database'];
        const bootloader = versionClone['bootloader'];
        const changes = versionClone['changes'];
        const statusversion = versionClone['statusversion'];
        const userinterface_version =
          versionClone['userinterface_version'] || '';
        const manual_version = versionClone['manual_version'] || '';
        delete versionClone['date'];
        delete versionClone['fw'];
        delete versionClone['database'];
        delete versionClone['bootloader'];
        delete versionClone['changes'];
        delete versionClone['statusversion'];
        delete versionClone['userinterface_version'];
        delete versionClone['manual_version'];
        return [
          tool.toolName,
          date,
          fw,
          database,
          bootloader,
          changes,
          statusversion,
          userinterface_version,
          manual_version,
          ...Object.values(versionClone),
          versionId,
          toolId,
          tool.groupId.groupName, // add group name
        ];
      })
    );
    console.log('Chau', ...Object.values(versionClone));
    const pageIndex =
      indexOfFW !== -1 ? Math.floor(indexOfFW / this.rowsPerPage) : 0;
    this.changePage(pageIndex);
    const options = {
      rowsPerPage: this.rowsPerPage,
      rowsPerPageOptions: [10, 20, 50, 100],
      page: pageIndex,
      rowsSelected: indexOfFW < 0 ? [] : [indexOfFW],
      onDownload: (buildHead, buildBody, columns, data) => {
        let prependHeader =
          'Group,Tool,Tool FW,,,,,,,,YMME,,VIN DECODE,,TripCycle/DriveCycle,,PossibleCauses,,Oil Reset,,OBD1,,NwsDtc,,Protocol API,NWS,,LaymanTerm,,DtcSeverity,,Dtc,,DLC,,Battery Reset,,ABS/SRS Live Data,,OBD2 APP,,Active Test,,SAS Calib,,Special Function,,EPB,,Oil Reset SF,,Battery SF,,TPMS Live Data,,TCM Live Data,,TPMS Procedure,,OFM,,Mode06,';
        prependHeader = prependHeader
          .split(',')
          .map((s) => `"${s}"`)
          .join(',');
        // unshift groupName column (last column) for columns
        const lengthColumns = columns.length;
        const groupNameCol = columns[lengthColumns - 1];
        columns.length = lengthColumns - 1;
        columns.unshift(groupNameCol);
        columns[0]['label'] = 'Name'; // set custom second header name for group column
        columns[1]['label'] = 'Name'; // set custom second header name for tool column

        // unshift groupName column (last column) for data
        data.forEach((item) => {
          const lengthData = item.data.length;
          const dataGroupNameCol = item.data[lengthData - 1];
          item.data.length = lengthData - 1;
          item.data.unshift(dataGroupNameCol);
        });

        return (
          '\uFEFF' + prependHeader + '\n' + buildHead(columns) + buildBody(data)
        );
      },
      viewColumns: false, // close viewcolumns
      print: false, // close print
      filterType: 'dropdown',
      responsive: 'scrollMaxHeight',
      // selectableRowsOnClick: true,
      elevation: 4,
      caseSensitive: false,
      fixedHeaderOptions: {
        xAxis: false,
        yAxis: true,
      },
      selectableRowsHeader: true,
      selectableRows: 'multiple',
      // selectableRows: (() => {
      //   const data = localStorage.getItem('userInfo');
      //   const userInfo = JSON.parse(data);
      //   let htmlContent = '';
      //   if (userInfo.role !== 'QA_Role' || userInfo.role !== 'Manager_Role') {
      //     htmlContent = 'multiple';
      //   }
      //   htmlContent = 'none'; // o vuong remove
      //   return htmlContent;
      // })(),

      // Các hiển thị icon edit and remove
      // disableToolbarSelect: (() => {
      //   const data = localStorage.getItem('userInfo');
      //   const userInfo = JSON.parse(data);
      //   let htmlContent;
      //   if (userInfo?.role === 'QA_Role' || userInfo?.role === 'Manager_Role') {
      //     htmlContent = true;
      //   } else {
      //     htmlContent = false; // remove ToolBar
      //   }

      //   return htmlContent;
      // })(),
      customToolbarSelect: (selectedRows, displayData, _setSelectedRows) => {
        const currentIndex = selectedRows.data[0].index;
        const idTool = displayData[currentIndex].data[63];
        const idVersion = displayData[currentIndex].data[62];

        const currentIndexArr = selectedRows.data.map((item) => item.index);
        const idVersionArr = currentIndexArr.map(
          (index) => displayData[index].data[62]
        );

        const data = localStorage.getItem('userInfo');
        const userInfo = JSON.parse(data);
        return (
          <div className='btn-group'>
            {this.state.isLoading1 && (
              <CircularProgress
                size={30}
                style={{
                  //marginLeft: 5,
                  position: 'relative',
                  top: 4,
                  marginRight: 70,
                  //paddingRight: 5,
                }}
              />
            )}
            {this.state.toolIdsOfRole.includes(idTool) && (
              <Tooltip title={'Edit version'}>
                <IconButton
                  onClick={() => this.handleEditVersion(idTool, idVersion)}
                >
                  <EditIcon style={{ color: '#fb8c00' }} />
                </IconButton>
              </Tooltip>
            )}
            {this.state.toolIdsOfRole.includes(idTool) && (
              <Tooltip title={'Delete version'}>
                <IconButton
                  onClick={() => this.handleDeleteVersion(idVersionArr)}
                >
                  <DelIcon style={{ color: '#d93025' }} />
                </IconButton>
              </Tooltip>
            )}
          </div>
        );
      },
      customToolbar: () => {
        const data = localStorage.getItem('userInfo');
        const userInfo = JSON.parse(data);
        const htmlContent =
          (userInfo?.role !== 'QA_Role' &&
            userInfo?.role !== 'Manager_Role' && (
              <>
                <input
                  accept='application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel'
                  id='importVersionInput'
                  multiple
                  type='file'
                  onChange={async (e) => {
                    await this.handleReadExcelFile(e);
                  }}
                  style={{ display: 'none' }}
                />
                <label htmlFor='importVersionInput'>
                  <Button component={'span'}>
                    <GetAppIcon style={{ transform: 'rotate(-180deg)' }} />
                  </Button>
                </label>
              </>
            )) ||
          null;
        return htmlContent;
      },
    };
    return (
      <React.Fragment>
        <AlertDialog
          open={this.state.openConfirmDeleteVersion}
          onClose={this.handleCloseConfirm}
          title='Do you want to delete these versions?'
          okText='OK'
          cancelText='Cancel'
          onAccept={this.handleAcceptDeleleteVersion}
          onCancel={this.handleCloseConfirm}
        />
        <ResultInfoDialog
          open={this.state.openResultDialog}
          onClose={this.handleCloseResultDialog}
          resultInfo={this.state.resultInfo}
          okText='OK'
          onAccept={this.handleCloseResultDialog}
          isOpSuccess={this.state.isDeleteVersionSuccess}
        />
        <DialogMessage
          open={!!this.state.errorMsg}
          title='Error'
          content={this.state.errorMsg}
        />
        <MUIDataTable
          innerRef={this.tableRef}
          className='custom-table'
          title={
            <Typography
              component='div'
              style={{
                fontSize: 16,
                fontWeight: 500,
                color: '#263238',
                letterSpacing: -0.05,
              }}
            >
              {`Version Tracking ${
                this.isVersionsOfGroupTable
                  ? `Group ${this.state.groupname}`
                  : `Tool ${this.state.toolName}`
              }`}
              {this.state.isLoading && (
                <CircularProgress
                  size={24}
                  style={{ marginLeft: 15, position: 'relative', top: 4 }}
                />
              )}
            </Typography>
          }
          data={MapData.reduce((acc, val) => acc.concat(val), [])}
          columns={this.columns}
          options={options}
        />
      </React.Fragment>
    );
  }

  handleEditVersion(idTool, idVersion: any): void {
    const { history } = this.props;
    history.push(
      `/version-tracking/edit-version-tracking/tool/${idTool}/version/${idVersion}`
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    toolIdsOfRole: state?.group?.currentToolIds || [],
  };
};

export default connect(mapStateToProps)(GroupVersionTrackingComponent);
