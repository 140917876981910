const server = 'https://vers.innovavietnam.com';
export const API_ENDPOINT = {
  versionTrackingURL: `${server}/api`,
  versionTrackingURLSignInUp: `${server}`,
  //versionTrackingURL: 'http://192.168.1.81:5000/api',
};
export const FILE_ENDPOINT = `${server}/file/`;
// export const API_ENDPOINT = {
//   versionTrackingURL: 'http://54.254.225.5:4050/api',
//   versionTrackingURLSignInUp: 'http://54.254.225.5:4050',
//   //versionTrackingURL: 'http://192.168.1.81:5000/api',
// };
// export const FILE_ENDPOINT = 'http://54.254.225.5:4050/file/';
