import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import Container from '@material-ui/core/Container';
import CssBaseline from '@material-ui/core/CssBaseline';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import React from 'react';
import './Login.scss';
import { serviceFactory } from '../../common/config/ServiceFactory';
class Login extends React.Component<any, any> {
  private readonly userService = serviceFactory.getUserService();
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      password: '',
      isError: false,
      errorMsg: '',
      loading: false,
    };
  }
  componentDidMount = () => {
    // console.log(process.env.REACT_APP_APP_URL);
  };
  onSubmit = (e) => {
    e.preventDefault();
    if (!this.state.email) {
      this.setState({ isError: true, errorMsg: 'Please enter your email' });
      return;
    }
    if (!this.state.password) {
      this.setState({ isError: true, errorMsg: 'Please enter your password' });
      return;
    }
    const body = {
      email: this.state.email,
      password: this.state.password,
    };
    this.setState({
      loading: true,
    });
    this.userService.signin(body).subscribe((res) => {
      localStorage.clear();
      this.setState({
        loading: false,
      });
      if (res && res.status === 201) {
        const userInfo = {
          email: this.state.email,
          jwt_token: res.data.token,
          role: res.data.role,
          groupName: res.data.groupName,
        };
        localStorage.setItem('userInfo', JSON.stringify(userInfo));
        this.props.history.push('/release-schedule');
      } else {
        this.setState({
          loading: false,
          isError: true,
          errorMsg: 'Invalid email or password, please try again!',
        });
      }
    });
  };
  onChange = (evt) => {
    const value = evt.target.value;
    this.setState({
      ...this.state,
      [evt.target.name]: value,
    });
  };
  render = () => {
    return (
      <Container
        component='main'
        maxWidth='xs'
        style={{
          background: '#fff',
          borderRadius: 5,
        }}
      >
        <div
          className='paper'
          style={{
            paddingTop: 16,
            paddingBottom: 32,
            paddingLeft: 8,
            paddingRight: 8,
          }}
        >
          <Avatar className='avatar'>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component='h1' variant='h5'>
            Sign in
          </Typography>
          <form className='form' noValidate onSubmit={this.onSubmit}>
            <TextField
              autoComplete='on'
              variant='outlined'
              margin='normal'
              required
              fullWidth
              id='email'
              label='Email Address'
              name='email'
              value={this.state.email}
              onChange={this.onChange}
              autoFocus
              type='email'
            />
            <TextField
              autoComplete='on'
              variant='outlined'
              margin='normal'
              required
              fullWidth
              name='password'
              label='Password'
              type='password'
              id='password'
              value={this.state.password}
              onChange={this.onChange}
            />
            {this.state.isError && (
              <p style={{ fontSize: 14, color: 'red' }}>
                {this.state.errorMsg}
              </p>
            )}
            <Button
              disabled={this.state.loading}
              type='submit'
              fullWidth
              variant='contained'
              color='secondary'
              className='submit'
            >
              Sign In
            </Button>
            <Grid container>
              <Grid item xs>
                <Link to='/forgotpass' variant='body2' color='textSecondary'>
                  Forgot password?
                </Link>
              </Grid>
              <Grid item>
                <Link to='/signup' variant='body2' color='textSecondary'>
                  Don't have an account? Sign Up
                </Link>
              </Grid>
            </Grid>
          </form>
        </div>
      </Container>
    );
  };
}
export default Login;
