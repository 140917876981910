import React from 'react';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import SaveIcon from '@material-ui/icons/Save';
import MenuItem from '@material-ui/core/MenuItem';
import Grid from '@material-ui/core/Grid';
import { serviceFactory } from '../../common/config/ServiceFactory';
class ChangePassword extends React.Component<any, any> {
  private readonly userService = serviceFactory.getUserService();
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      oldPassword: '',
      newPassword: '',
      confirmNewPassword: '',
      showNewPassword: false,
      showconfirmNewPassword: false,
      showoldPassword: false,
      loading: false,
      isError: false,
      errorMsg: '',
      isSuccess: false,
    };
  }
  onSubmit = (e) => {
    e.preventDefault();
    if (!this.state.oldPassword) {
      this.setState({
        isError: true,
        errorMsg: 'Please enter your current password',
      });
      return;
    }
    if (!this.state.newPassword) {
      this.setState({
        isError: true,
        errorMsg: 'Please enter your new password',
      });
      return;
    }
    if (!this.state.confirmNewPassword) {
      this.setState({
        isError: true,
        errorMsg: 'Please enter your confirm new password',
      });
      return;
    }
    if (this.state.newPassword !== this.state.confirmNewPassword) {
      this.setState({
        isError: true,
        errorMsg: 'Passwords are different, please check again.',
      });
      return;
    }
    const body = {
      oldPassword: this.state.oldPassword,
      newPassword: this.state.newPassword,
    };
    this.setState({
      loading: true,
    });
    this.userService.changePassword(body).subscribe((res) => {
      if (res.response && res.response.data.error) {
        this.setState({
          isError: true,
          isSuccess: false,
          errorMsg: res.response.data.error,
        });
        //window.alert(res.response.data.error);
      } else {
        this.setState({
          loading: false,
          oldPassword: '',
          newPassword: '',
          confirmNewPassword: '',
          isError: false,
          isSuccess: true,
          errorMsg: 'You have successfully changed your password!',
        });
        setTimeout(() => {
          this.setState({
            open: false,
            loading: false,
            isError: false,
            isSuccess: false,
          });
        }, 1500);
      }
    });
  };
  render() {
    const handleClickOpen = () => {
      this.setState({
        open: true,
      });
    };

    const handleClose = () => {
      this.setState({
        open: false,
        oldPassword: '',
        newPassword: '',
        confirmNewPassword: '',
        loading: false,
        isError: false,
        errorMsg: '',
      });
    };
    const handleChangeoldPassword = (e) => {
      this.setState({
        oldPassword: e.target.value,
      });
    };
    const handleChangenewPassword = (e) => {
      this.setState({
        newPassword: e.target.value,
      });
    };
    const handleChangeconfirmNewPassword = (e) => {
      this.setState({
        confirmNewPassword: e.target.value,
      });
    };
    const handleClickShowoldPassword = () => {
      this.setState({
        oldPassword: this.state.oldPassword,
        showoldPassword: !this.state.showoldPassword,
      });
    };
    const handleClickShowNewPassword = () => {
      this.setState({
        newPassword: this.state.newPassword,
        showNewPassword: !this.state.showNewPassword,
      });
    };
    const handleClickShowConfirmNewPassword = () => {
      this.setState({
        confirmNewPassword: this.state.confirmNewPassword,
        showconfirmNewPassword: !this.state.showconfirmNewPassword,
      });
    };
    const handleMouseDownPassword = (event) => {
      event.preventDefault();
    };
    return (
      <div style={{ width: '100%' }}>
        <MenuItem onClick={handleClickOpen}>Change Password</MenuItem>

        <Dialog
          open={this.state.open}
          onClose={handleClose}
          maxWidth='xs'
          aria-labelledby='form-dialog-title'
        >
          <h1
            id='form-dialog-title'
            style={{
              color: '#313131 !important',
              paddingTop: 20,
              paddingLeft: 30,
            }}
          >
            Change password
          </h1>

          <form noValidate className='form' onSubmit={this.onSubmit}>
            <DialogContent>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <FormControl style={{ display: 'flex' }}>
                    <InputLabel htmlFor='standard-adornment-password'>
                      Current Password
                    </InputLabel>
                    <Input
                      autoFocus
                      margin='dense'
                      fullWidth
                      id='standard-adornment-password'
                      type={this.state.showoldPassword ? 'text' : 'password'}
                      value={this.state.oldPassword}
                      onChange={handleChangeoldPassword}
                      endAdornment={
                        <InputAdornment position='end'>
                          <IconButton
                            aria-label='toggle password visibility'
                            onClick={handleClickShowoldPassword}
                            onMouseDown={handleMouseDownPassword}
                          >
                            {this.state.showoldPassword ? (
                              <Visibility />
                            ) : (
                              <VisibilityOff />
                            )}
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <FormControl style={{ display: 'flex' }}>
                    <InputLabel htmlFor='standard-adornment-password'>
                      New Password
                    </InputLabel>
                    <Input
                      id='standard-adornment-password'
                      type={this.state.showNewPassword ? 'text' : 'password'}
                      value={this.state.newPassword}
                      onChange={handleChangenewPassword}
                      endAdornment={
                        <InputAdornment position='end'>
                          <IconButton
                            aria-label='toggle password visibility'
                            onClick={handleClickShowNewPassword}
                            onMouseDown={handleMouseDownPassword}
                          >
                            {this.state.showNewPassword ? (
                              <Visibility />
                            ) : (
                              <VisibilityOff />
                            )}
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <FormControl style={{ display: 'flex' }}>
                    <InputLabel htmlFor='standard-adornment-password'>
                      Confirm New Password
                    </InputLabel>
                    <Input
                      id='standard-adornment-password'
                      type={
                        this.state.showconfirmNewPassword ? 'text' : 'password'
                      }
                      value={this.state.confirmNewPassword}
                      onChange={handleChangeconfirmNewPassword}
                      endAdornment={
                        <InputAdornment position='end'>
                          <IconButton
                            aria-label='toggle password visibility'
                            onClick={handleClickShowConfirmNewPassword}
                            onMouseDown={handleMouseDownPassword}
                          >
                            {this.state.showconfirmNewPassword ? (
                              <Visibility />
                            ) : (
                              <VisibilityOff />
                            )}
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                  </FormControl>
                </Grid>
              </Grid>
            </DialogContent>
            {this.state.isError && (
              <p
                style={{
                  fontSize: 14,
                  color: 'red',
                  paddingLeft: 30,
                  paddingTop: 20,
                }}
              >
                {this.state.errorMsg}
              </p>
            )}
            {this.state.isSuccess && (
              <p
                style={{
                  fontSize: 14,
                  color: '#039be5',
                  paddingLeft: 30,
                  paddingTop: 20,
                }}
              >
                {this.state.errorMsg}
              </p>
            )}
            <DialogActions style={{ marginTop: 20 }}>
              <Button onClick={handleClose} color='primary'>
                Cancel
              </Button>
              <Button
                type='submit'
                variant='contained'
                color='secondary'
                startIcon={<SaveIcon />}
              >
                Change Password
              </Button>
            </DialogActions>
          </form>
        </Dialog>
      </div>
    );
  }
}
export default connect()(ChangePassword);
