import { catchError } from 'rxjs/operators';
import { WebClientUtil } from './../../util/WebClientUtil';
import { API_ENDPOINT } from './../../../../config';
import { Observable, of } from 'rxjs';
import { RoleService } from './RoleService';
export class RoleServiceImpl implements RoleService {
  private baseUrl = API_ENDPOINT.versionTrackingURL;
  GetallRole(): Observable<any> {
    const url = `${this.baseUrl}/role`;
    return WebClientUtil.getObject(url);
  }
  getOneRole(id: string) {
    const url = `${this.baseUrl}/role/getRole/${id}`;
    return WebClientUtil.getObject(url);
  }
  createRole(body: any): Observable<any> {
    const url = `${this.baseUrl}/role`;
    return WebClientUtil.postObject(url, body);
  }
  getallToolGroup(id: string) {
    const url = `${this.baseUrl}/role/getallToolandGroup/${id}`;
    return WebClientUtil.getObject(url);
  }
  AddToolOnRole(body: any): Observable<any> {
    const url = `${this.baseUrl}/role/oftool`;
    return WebClientUtil.postObject(url, body);
  }
  updateToolOnOneRole(body: any): Observable<any> {
    const url = `${this.baseUrl}/role/updateToolOnOneRole`;
    return WebClientUtil.putObject(url, body);
  }
  updateNameRole(id: string, newModel: any) {
    const url = `${this.baseUrl}/role/updateRoleName/${id}`;
    return WebClientUtil.putObject(url, newModel);
  }
  deleteRoleName(id: string): Observable<any> {
    const url = `${this.baseUrl}/role/${id}`;
    return WebClientUtil.deleteObject(url);
  }
  getToolThatRole(body: any): Observable<any> {
    const url = `${this.baseUrl}/role/tool/ofRole`;
    return WebClientUtil.postObject(url, body);
  }
}
