import React, { useEffect } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import DoneIcon from '@material-ui/icons/Done';
import ClearIcon from '@material-ui/icons/Clear';
import { Typography } from '@material-ui/core';
export default function ResultInfoDialog(props) {
  const {
    okText,
    open: openProp,
    onClose,
    onAccept,
    resultInfo,
    isOpSuccess,
    ...rest
  } = props;
  const [open, setOpen] = React.useState(openProp);
  useEffect(() => {
    setOpen(openProp);
  }, [openProp]);
  const handleClose = () => {
    // setOpen(false);
    onClose();
  };

  const handleAccept = () => {
    // setOpen(false);
    onAccept();
  };
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
    >
      <DialogTitle id='alert-dialog-title'>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {isOpSuccess && <DoneIcon style={{ fontSize: 32, color: 'green' }} />}
          {!isOpSuccess && <ClearIcon style={{ fontSize: 32, color: 'red' }} />}
          &nbsp;
          <Typography style={{ color: '#000000' }}>{resultInfo}</Typography>
        </div>
      </DialogTitle>
      <DialogActions>
        <Button onClick={handleAccept} color='secondary' autoFocus>
          {okText}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
