import { catchError } from 'rxjs/operators';
import { WebClientUtil } from '../../util/WebClientUtil';
import { API_ENDPOINT } from '../../../../config';
import { Observable, of } from 'rxjs';
import { CategoryService } from './CategoryService';
export class CategoryServiceImpl implements CategoryService {
  private baseUrl = API_ENDPOINT.versionTrackingURL;
  getallCategory(): Observable<any> {
    const url = `${this.baseUrl}/category`;
    return WebClientUtil.getObject(url);
  }
}
