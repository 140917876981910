import { catchError } from 'rxjs/operators';
import { WebClientUtil } from '../../util/WebClientUtil';
import { API_ENDPOINT } from '../../../../config';
import { Observable, of } from 'rxjs';
import { ReleasesService } from './ReleasesService';
export class ReleasesServiceImpl implements ReleasesService {
  private baseUrl = API_ENDPOINT.versionTrackingURL;
  createRelease(body: any): Observable<any> {
    const url = `${this.baseUrl}/release`;
    return WebClientUtil.postObject(url, body);
  }
  getallrelease(): Observable<any> {
    const url = `${this.baseUrl}/release`;
    return WebClientUtil.getObject(url);
  }
  updateRelease(id: string, newModel: any) {
    const url = `${this.baseUrl}/release/${id}`;
    return WebClientUtil.putObject(url, newModel);
  }
  deleteRelease(id: string): Observable<any> {
    const url = `${this.baseUrl}/release/${id}`;
    return WebClientUtil.deleteObject(url);
  }
  getOneReleaseById(id: string): Observable<any> {
    const url = `${this.baseUrl}/release/${id}`;
    return WebClientUtil.getObject(url);
  }
  deleteReleases(arrayId: string[]): Observable<any> {
    const url = `${this.baseUrl}/release/delete/DeleteMultiple`;
    return WebClientUtil.postObject(url, arrayId);
  }
}
