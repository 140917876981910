import * as React from 'react';
import { Toolbar, Typography, Paper } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { Link, Prompt } from 'react-router-dom';
import Icon from '@material-ui/core/Icon';
import { connect } from 'react-redux';
import { serviceFactory } from '../../common/config/ServiceFactory';
import * as toolActions from '../../../redux/actions/toolActions';
import { FILE_ENDPOINT } from '../../../config';
import DelIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import SaveIcon from '@material-ui/icons/Save';
import CircularProgress from '@material-ui/core/CircularProgress';
import LinearProgress from '@material-ui/core/LinearProgress';
import Autocomplete from '@material-ui/lab/Autocomplete';
import './style.scss';
import {
  sanitizeASCIIString,
  sanitizeStringForFolder,
} from '../../../utils/StringUtils';
import { DataStateDocument } from './DataStateDocument';
import { async } from 'rxjs/internal/scheduler/async';
import axios from 'axios';

class Documents extends React.Component<any, any> {
  private toolService = serviceFactory.getToolService();
  private docService = serviceFactory.getDocumentService();
  private userService = serviceFactory.getUserService();
  private fileInput = null;
  constructor(props) {
    super(props);
    this.state = {
      documents: [],
      selectedFile: null,
      listFilePending: [],
      listFileUploaded: [],
      hidenUpload: true,
      hidenUploadEdit: true,
      isLoading: false,
      nameStateDocument: '',
      DataStateDocument: '',
      state: null,
      selectedEditIndex: null,
      usergrouparr: [],
      emailAddress: [],
    };
  }
  toolInfo = { toolName: '' };
  async componentDidMount() {
    try {
      const toolId = this.props.match.params.id;
      const toolRes = await this.toolService.getOneTool(toolId).toPromise();
      if (toolRes.response && toolRes.response.data.error) {
        // error checking
        alert(toolRes.response.data.error);
      } else {
        this.toolInfo = toolRes.data.data;
        this.props.dispatch(
          toolActions.selectTool({
            ...toolRes.data.data,
            title: toolRes.data.data.toolName,
          })
        );
      }

      await this.docService.removeAllOrphanDocuments().toPromise();

      // this.docService.getOneDocsByToolDocs(IdDocument).subscribe((res) => {
      //   if (res.data.data) {
      //     this.setState({

      //     });
      //   }
      // });

      const resDocs = await this.docService.getallDocs(toolId).toPromise();
      if (resDocs.response && resDocs.response.data.error) {
        // error checking
        alert(resDocs.response.data.error);
      } else {
        this.setState({
          documents: resDocs.data.data,
        });
      }
    } catch (e) {
      alert(e);
    }
  }
  async componentWillUnmount() {
    // tslint:disable-next-line: no-unused-expression
    this.state.listFileUploaded.length > 0 &&
      (await this.docService.removeAllOrphanDocuments().toPromise());
  }
  componentDidUpdate = async () => {
    const shouldBlockNavigation = this.state.listFileUploaded.length > 0;
    if (shouldBlockNavigation) {
      window.onbeforeunload = () => true;
    } else {
      window.onbeforeunload = undefined;
    }
  };
  uploadDocument = async () => {
    const file = this.state.selectedFile;
    const data = localStorage.getItem('userInfo');
    const userInfo = JSON.parse(data);
    if (!file) {
      alert('Please select file upload!');
      return;
    }
    const fileName = `${file.name}`;
    const body = {
      nameFile: fileName,
      userUpload: userInfo.email,
      datetimeUpload: new Date().getTime(),
    };
    const uploadBody = new FormData();
    uploadBody.append(
      'subPath',
      (this.toolInfo.toolName && sanitizeASCIIString(this.toolInfo.toolName)) ||
        ''
    ); // always put fields before file key
    uploadBody.append('fileName', fileName);
    uploadBody.append('file', file);
    try {
      this.setState(() => {
        const listFilePending = this.state.listFilePending;
        listFilePending.push({
          fileName,
        });
        return {
          listFilePending,
        };
      });

      const resUpload = await this.docService
        .uploadFile(uploadBody)
        .toPromise();
      if (resUpload.response && resUpload.response.data) {
        // error checking
        this.setState(() => {
          const listFilePending = this.state.listFilePending;
          listFilePending.splice(
            listFilePending.findIndex((file) => file.fileName === fileName),
            1
          );
          return {
            listFilePending,
          };
        });
        const str = /<pre>(.*?)<\/pre>/g.exec(resUpload.response.data)[1];
        throw Error(str);
      }

      const res = await this.docService.createdocument(body).toPromise();
      if (res.response && res.response.data.error) {
        // error checking
        this.setState(() => {
          const listFilePending = this.state.listFilePending;
          listFilePending.splice(
            listFilePending.findIndex((file) => file.fileName === fileName),
            1
          );
          return {
            listFilePending,
          };
        });
        throw res.response.data.error;
      }

      this.setState(() => {
        const listFileUploaded = this.state.listFileUploaded;
        const listFilePending = this.state.listFilePending;
        listFilePending.splice(
          listFilePending.findIndex((item) => item.fileName === fileName),
          1
        );
        listFileUploaded.push({
          _id: res.data.data._id,
          fileName,
          optionalDescription: '',
          nameStateDocument: '',
        });
        return {
          listFileUploaded,
          listFilePending,
        };
      });
    } catch (e) {
      alert(e);
    }
  };
  onChangeHandler = async (event) => {
    this.setState(
      {
        selectedFile: event.target.files[0],
      },
      async () => {
        this.fileInput.value = '';
        await this.uploadDocument();
      }
    );
  };
  setOptionalDescription = (event, index) => {
    event.persist();
    this.setState(() => {
      const listFileUploaded = this.state.listFileUploaded;
      listFileUploaded[index] = {
        ...listFileUploaded[index],
        optionalDescription: event.target.value,
      };
      return {
        listFileUploaded,
      };
    });
  };
  saveAllDocs = async () => {
    const toolId = this.props.match.params.id;
    const body = {
      toolId,
      files: this.state.listFileUploaded.map((file) => ({
        idDocument: file._id,
        optionalDescription: file.optionalDescription,
        nameStateDocument: file.nameStateDocument,
      })),
    };
    // console.log('bodyyy', body);
    this.setState({
      isLoading: true,
    });
    const res = await this.docService.saveAllDocs(body).toPromise();
    if (res.response && res.response.data.error) {
      // error checking
      this.setState({
        isLoading: false,
      });
      throw res.response.data.error;
    }
    this.setState({
      isLoading: false,
    });
    // gui email
    // goi ham gui email
    //await this.createEmail();

    this.setState(
      {
        selectedFile: null,
        listFilePending: [],
        listFileUploaded: [],
        hidenUpload: true,
      },
      async () => {
        await this.componentDidMount();
      }
    );
  };

  createEmail = async () => {
    let address = [];

    // Handle upload file --> Need review ---> send mail cho Keith
    if (this.state.listFileUploaded[0].nameStateDocument == '0') {
      //address.push('baochauluongnguyen@gmail.com', 'hieudn@vn.innova.com');
      address.push(
        'KeithA@innova.com',
        'hieudn@vn.innova.com',
        'rockyy@innova.com'
      );
    }
    //Keith upload file --> Need update---> send mail cho Handle
    if (this.state.listFileUploaded[0].nameStateDocument == '1') {
      const idtool = this.props.match.params.id;
      const resUsers = await this.userService
        .getEmailUserHasToolId(idtool)
        .toPromise();
      if (resUsers.response && resUsers.response.data.error) {
        // error checking
        alert(resUsers.response.data.error);
      } else {
        address = resUsers.data.data;
      }
    }
    //Keith upload file / Edit status --> Approve --> send mail cho Keith
    if (this.state.listFileUploaded[0].nameStateDocument == '2') {
      address.push(
        'KeithA@innova.com',
        'hieudn@vn.innova.com',
        'rockyy@innova.com'
      );
      //address.push('baochauluongnguyen@gmail.com', 'hieudn@vn.innova.com');
    }
    //Handle Edit status -->  Reject No send maill
    if (this.state.listFileUploaded[0].nameStateDocument == '3') {
      return;
    }

    const body = {
      address: address,
      subject: 'Innova Tool Line Document',
      message: `
      ${this.props.tool.toolName}
      Documemt: ${this.state.listFileUploaded[0].fileName}
      Author: ${
        this.state.documents[this.state.selectedEditIndex].userUpload
      }  ${new Date(
        this.state.documents[this.state.selectedEditIndex].datetimeUpload
      ).toLocaleString('en-GB')}
      Note: ${this.state.listFileUploaded[0].optionalDescription}
      Status:${
        DataStateDocument.find(
          (dsd) =>
            dsd.value === this.state.listFileUploaded[0].nameStateDocument
        )?.NameState
      }
      ${window.location.href}
      `,
    };

    this.docService.sendMailDoc(body).subscribe((res) => {
      if (res.response && res.response.data.error) {
        this.setState({ error: res.response.data.error });
        console.error(res.response.data.error);
        window.alert(res.response.data.error);
      } else {
        alert('Ok');
      }
    });
  };

  createEmailUpdate = async () => {
    let address = [];
    // Handle upload file --> Need review ---> send mail cho Keith
    if (
      this.state.documents[this.state.selectedEditIndex].nameStateDocument ==
      '0'
    ) {
      address.push(
        'KeithA@innova.com',
        'hieudn@vn.innova.com',
        'rockyy@innova.com'
      );
      //address.push('baochauluongnguyen@gmail.com', 'hieudn@vn.innova.com');
    }
    //Keith upload file --> Need update---> send mail cho Handle
    if (
      this.state.documents[this.state.selectedEditIndex].nameStateDocument ==
      '1'
    ) {
      const idtool = this.props.match.params.id;
      const resUsers = await this.userService
        .getEmailUserHasToolId(idtool)
        .toPromise();
      if (resUsers.response && resUsers.response.data.error) {
        // error checking
        alert(resUsers.response.data.error);
      } else {
        address = resUsers.data.data;
      }
    }
    //Keith upload file / Edit status --> Approve --> send mail cho Keith
    if (
      this.state.documents[this.state.selectedEditIndex].nameStateDocument ==
      '2'
    ) {
      //address.push('baochauluongnguyen@gmail.com', 'hieudn@vn.innova.com');
      address.push(
        'KeithA@innova.com',
        'hieudn@vn.innova.com',
        'rockyy@innova.com'
      );
    }
    //Handle Edit status -->  Reject No send maill
    if (
      this.state.documents[this.state.selectedEditIndex].nameStateDocument ==
      '3'
    ) {
      return;
    }

    const body = {
      address: address,
      subject: 'Innova Tool Line Document',
      message: `
      ${this.props.tool.toolName}
      Document: ${this.state.documents[this.state.selectedEditIndex].nameFile}
      Author: ${
        this.state.documents[this.state.selectedEditIndex].userUpload
      } ${new Date(
        this.state.documents[this.state.selectedEditIndex].datetimeUpload
      ).toLocaleString('en-GB')}
      Note: ${
        this.state.documents[this.state.selectedEditIndex].optionalDescription
      }
      Status:${
        DataStateDocument.find(
          (dsd) =>
            dsd.value ===
            this.state.documents[this.state.selectedEditIndex].nameStateDocument
        )?.NameState
      }
      ${window.location.href}
      `,
    };

    const resSendMail = await this.docService.sendMailDoc(body).toPromise();
    if (resSendMail.response && resSendMail.response.data.error) {
      // error checking
      alert(resSendMail.response.data.error);
    } else {
      alert('Ok');
    }
  };

  deleteDocument = async (idDocument, nameFile = '') => {
    const res = await this.docService.deleteDocument(idDocument).toPromise();
    if (res.response && res.response.data.error) {
      // error checking
      throw res.response.data.error;
    }
    const { documents } = this.state;
    const document = documents.find((file) => file._id === idDocument);
    const delBody = {
      subPath:
        (this.toolInfo.toolName &&
          sanitizeASCIIString(this.toolInfo.toolName)) ||
        '',
      fileName: nameFile || (document && document.nameFile) || 'file',
    };

    const resDel = await this.docService.deleteFileByName(delBody).toPromise();
    if (resDel.response && resDel.response.data.error) {
      // error checking
      throw resDel.response.data.error;
    }

    this.setState(() => {
      const { listFileUploaded, documents } = this.state;
      documents.splice(
        documents.findIndex((file) => file._id === idDocument),
        1
      );
      listFileUploaded.splice(
        listFileUploaded.findIndex((file) => file._id === idDocument),
        1
      );
      return {
        documents,
        listFileUploaded,
      };
    });
    this.componentDidMount();
    this.setState({
      hidenUpload: true,
    });
  };

  // add state combobox
  handleChangeDocument = (event, value, index) => {
    if (value) {
      this.setState(() => {
        const listFileUploaded = this.state.listFileUploaded;
        listFileUploaded[index] = {
          ...listFileUploaded[index],
          nameStateDocument: value.value,
        };
        return {
          listFileUploaded,
        };
      });
    }
  };
  // edit change state document
  handleChangeState = (event, value, index) => {
    const data = localStorage.getItem('userInfo');
    const userInfo = JSON.parse(data);
    //console.log('userUpload', userInfo.email);
    this.setState(() => {
      const documents = this.state.documents;
      documents.nameStateDocument = value.value;
      documents[index] = {
        ...documents[index],
        nameStateDocument: value.value,
        userUpload: userInfo.email,
        datetimeUpload: new Date().getTime(),
      };

      return {
        documents,
      };
    });
  };
  cancelStateDocs = () => {
    this.setState({
      hidenUploadEdit: true,
    });
    window.location.reload();
  };
  updateStateDocs = async (idDocument) => {
    //e.preventDefault();
    //console.log('idDocument', idDocument);
    const body = {
      nameStateDocument: this.state.documents[this.state.selectedEditIndex]
        .nameStateDocument,
      userUpload: this.state.documents[this.state.selectedEditIndex].userUpload,
      datetimeUpload: this.state.documents[this.state.selectedEditIndex]
        .datetimeUpload,
    };
    const resupdateDocument = await this.docService
      .updateStateDocument(idDocument, body)
      .toPromise();
    if (resupdateDocument.response && resupdateDocument.response.data.error) {
      // error checking
      this.setState({ error: resupdateDocument.response.data.error });
      console.error(resupdateDocument.response.data.error);
      window.alert(resupdateDocument.response.data.error);
    }
    this.componentDidMount();
    this.setState({
      hidenUploadEdit: true,
    });
    // this.docService.updateStateDocument(idDocument, body).subscribe((res) => {
    //   if (res.response && res.response.data.error) {
    //     this.setState({ error: res.response.data.error });
    //     console.error(res.response.data.error);
    //     window.alert(res.response.data.error);
    //   }
    // });
    // gui email
    // goi ham gui email
    // await this.createEmailUpdate();
    window.location.reload();
  };
  render() {
    const data = localStorage.getItem('userInfo');
    const userInfo = JSON.parse(data);
    //console.log('data info', data);
    //console.log('userInfo info', userInfo);
    return (
      <Paper elevation={4} style={{ padding: 16 }}>
        <Prompt
          when={this.state.listFileUploaded.length > 0}
          message={(location) =>
            `Unsaved changes, are you sure you want to go to ${location.pathname}`
          }
        />
        <form
          noValidate
          autoComplete='off'
          onSubmit={(event) => {
            event.preventDefault();
          }}
        >
          <Grid container justify='space-between'>
            <Grid item>
              <Typography
                variant='h5'
                component='h3'
                style={{ color: '#000000' }}
              >
                Tool document
              </Typography>
            </Grid>
          </Grid>
          <hr />
          <Typography component='h6' variant='h6' style={{ marginBottom: 10 }}>
            Files
          </Typography>
          <Grid container>
            {this.state.documents.length > 0 &&
              this.state.documents.map((doc, index) => {
                const idDoc = doc._id;
                // console.log('filesize', doc.fileSize);
                //const toolId = this.state.toolId;
                return (
                  <div
                    style={{
                      width: '100%',
                      display: 'flex',
                      alignItems: 'center',
                    }}
                    className='document-item'
                    key={doc._id}
                  >
                    <AttachFileIcon
                      style={{
                        transform: 'rotate(-140deg)',
                        fontSize: 15,
                        color: '#6c6c6c',
                      }}
                    />
                    &nbsp;
                    <a
                      className='hoverFileName'
                      style={{
                        cursor: 'pointer',
                      }}
                      title='Download file'
                      download
                      href={`${FILE_ENDPOINT}${
                        (this.toolInfo.toolName &&
                          sanitizeStringForFolder(this.toolInfo.toolName)) ||
                        'default'
                      }/${doc.nameFile}`}
                    >
                      {doc.nameFile}
                    </a>
                    {doc.optionalDescription && (
                      <span>&nbsp;-&nbsp;{doc.optionalDescription}</span>
                    )}
                    {doc.nameStateDocument && (
                      <span
                        style={{
                          fontSize: 18,
                          color: '#fb8c00',
                        }}
                      >
                        &nbsp;---&nbsp;
                        {
                          DataStateDocument.find(
                            (dsd) => dsd.value === doc.nameStateDocument
                          )?.NameState
                        }
                      </span>
                    )}
                    {/* <span>
                      &nbsp; (
                      {doc.fileSize >= 1048576
                        ? parseFloat(`${doc.fileSize / 1048576}`).toFixed(1) +
                          ' MB'
                        : parseFloat(`${doc.fileSize / 1024}`).toFixed(1) +
                          ' KB'}
                      ) &nbsp;
                    </span> */}
                    {(userInfo?.role !== 'Manager_Role' ||
                      (userInfo?.role == 'Manager_Role' &&
                        userInfo?.email == 'KeithA@innova.com')) && (
                      <>
                        <Tooltip title={'Delete'}>
                          <IconButton
                            onClick={() => {
                              const result = window.confirm(
                                'Are you sure want to delete?'
                              );
                              // tslint:disable-next-line: no-unused-expression
                              result && this.deleteDocument(doc._id);
                            }}
                            style={{
                              padding: 6,
                            }}
                          >
                            <DelIcon
                              style={{
                                fontSize: 20,
                                color: '#6c6c6c',
                              }}
                            />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title={'Edit'}>
                          <IconButton
                            // onClick={() => this.handleEditBug(toolId, idDoc)}
                            onClick={() => {
                              this.setState({
                                hidenUploadEdit: false,
                                selectedEditIndex: index,
                                hidenUpload: true,
                              });
                            }}
                          >
                            <EditIcon
                              style={{
                                fontSize: 20,
                                color: '#fb8c00',
                              }}
                            />
                          </IconButton>
                        </Tooltip>
                      </>
                    )}
                    <span>{`${doc.userUpload}, ${new Date(
                      doc.datetimeUpload
                    ).toLocaleString('en-GB')}`}</span>
                  </div>
                );
              })}
          </Grid>

          {(userInfo?.role !== 'Manager_Role' ||
            (userInfo?.role == 'Manager_Role' &&
              userInfo?.email == 'KeithA@innova.com')) && (
            <a
              onClick={() => {
                this.setState({ hidenUpload: false, hidenUploadEdit: true });
              }}
              style={{ display: 'inline-block', marginTop: 16 }}
              href='#'
            >
              New file
            </a>
          )}

          {!this.state.hidenUpload && (
            <>
              <Grid
                style={{
                  marginTop: 16,
                  width: '100%',
                  padding: 10,
                  borderRadius: 3,
                  backgroundColor: '#ececec',
                  border: '1px solid #ccc',
                }}
                container
                alignItems='center'
              >
                {/* List file uploaded */}
                {this.state.listFileUploaded.length > 0 &&
                  this.state.listFileUploaded.map((file, index) => (
                    <React.Fragment key={file._id}>
                      <Grid container item xs={4} alignItems='center'>
                        <AttachFileIcon
                          style={{
                            transform: 'rotate(-140deg)',
                            fontSize: 15,
                            color: '#6c6c6c',
                          }}
                        />
                        <span className='file-name'>{file.fileName}</span>
                      </Grid>

                      <Grid container item xs={8} alignItems='center'>
                        <TextField
                          id={file._id}
                          name={file._id}
                          value={file.optionalDescription}
                          onChange={(event) =>
                            this.setOptionalDescription(event, index)
                          }
                          label='Optional description '
                          variant='outlined'
                          placeholder='Optional description'
                          size='small'
                          style={{ width: 300 }}
                        />
                        <Grid container item xs={6}>
                          <Autocomplete
                            disableClearable
                            id={file._id}
                            onChange={(event, value) =>
                              this.handleChangeDocument(event, value, index)
                            }
                            options={DataStateDocument}
                            getOptionLabel={(option) => option.NameState}
                            style={{ width: 180, marginLeft: 20 }}
                            value={
                              DataStateDocument.find(
                                (dsd) => dsd.value === file.nameStateDocument
                              ) || null
                            }
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label='State'
                                variant='outlined'
                                fullWidth
                                size='small'
                                inputProps={{
                                  ...params.inputProps,
                                  autoComplete: 'off', // disable autocomplete and autofill
                                }}
                              />
                            )}
                          />
                          <Tooltip title={'Delete'}>
                            <IconButton
                              onClick={() =>
                                this.deleteDocument(file._id, file.fileName)
                              }
                            >
                              <DelIcon
                                style={{
                                  fontSize: 20,
                                  color: '#6c6c6c',
                                }}
                              />
                            </IconButton>
                          </Tooltip>
                        </Grid>
                      </Grid>
                    </React.Fragment>
                  ))}
                {/* End list file uploaded */}

                {/* List file pending upload */}
                {this.state.listFilePending.length > 0 &&
                  this.state.listFilePending.map((file, index) => (
                    <React.Fragment key={file.fileName}>
                      <Grid container item xs={4} alignItems='center'>
                        <CircularProgress
                          size={12}
                          variant='indeterminate'
                          disableShrink
                          thickness={4}
                          style={{
                            marginRight: 5,
                          }}
                        />
                        <span className='file-name'>{file.fileName}</span>
                      </Grid>

                      <Grid container item xs={8} alignItems='center'>
                        <LinearProgress
                          variant='indeterminate'
                          color='primary'
                          style={{
                            width: 300,
                            height: 10,
                            borderRadius: 5,
                          }}
                        />
                      </Grid>
                    </React.Fragment>
                  ))}
                {/* End list file pending upload */}

                <input
                  style={{ border: 'none' }}
                  type='file'
                  id='file'
                  name='file'
                  multiple
                  onChange={this.onChangeHandler}
                  ref={(ref) => (this.fileInput = ref)}
                />
                <span>(Maximum size: 300 MB)</span>
              </Grid>

              <Button
                disabled={
                  this.state.isLoading || this.state.listFileUploaded.length < 1
                }
                variant='contained'
                color='secondary'
                size='medium'
                type='button'
                style={{ marginTop: 16 }}
                onClick={this.saveAllDocs}
                startIcon={<SaveIcon />}
              >
                Add
              </Button>
            </>
          )}
          <Grid>
            {!this.state.hidenUploadEdit && (
              <>
                <Grid
                  style={{
                    marginTop: 16,
                    width: '100%',
                    padding: 10,
                    borderRadius: 3,
                    backgroundColor: '#ececec',
                    border: '1px solid #ccc',
                  }}
                  container
                  alignItems='center'
                >
                  <Autocomplete
                    disableClearable
                    onChange={(event, valueChange) => {
                      this.handleChangeState(
                        event,
                        valueChange,
                        this.state.selectedEditIndex
                      );
                    }}
                    options={DataStateDocument}
                    getOptionLabel={(option) => option.NameState}
                    style={{ width: 180, marginLeft: 20, marginRight: 20 }}
                    value={
                      DataStateDocument.find(
                        (dsd) =>
                          dsd.value ===
                          this.state.documents[this.state.selectedEditIndex]
                            .nameStateDocument
                      ) || null
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label='State'
                        variant='outlined'
                        fullWidth
                        size='small'
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: 'off', // disable autocomplete and autofill
                        }}
                      />
                    )}
                  />

                  <Button
                    variant='contained'
                    color='primary'
                    size='medium'
                    type='button'
                    style={{ marginTop: 0 }}
                    onClick={() =>
                      this.updateStateDocs(
                        this.state.documents[this.state.selectedEditIndex]._id
                      )
                    }
                    startIcon={<SaveIcon />}
                  >
                    Update
                  </Button>
                  <Button
                    variant='contained'
                    color='secondary'
                    size='medium'
                    type='button'
                    style={{ marginTop: 0, marginLeft: 10 }}
                    onClick={() => this.cancelStateDocs()}
                  >
                    Cancel
                  </Button>
                </Grid>
              </>
            )}
          </Grid>
        </form>
      </Paper>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  // state is from store
  return {
    tool: state.tool.currentTool,
  };
};
export default connect(mapStateToProps)(Documents);
