import React from 'react';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { serviceFactory } from '../../common/config/ServiceFactory';
import { Grid, Paper } from '@material-ui/core';
import { fetchGroupGroupNameData } from '../../../redux/actions/horizontalNavbarActions';
import { connect } from 'react-redux';
import SaveIcon from '@material-ui/icons/Save';
import * as toolActions from '../../../redux/actions/toolActions';
import * as groupActions from '../../../redux/actions/groupActions';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { FunctionGroupUser } from './functionGroupUser';
import { sanitizeASCIIString } from '../../../utils/StringUtils';

class NewGroupUser extends React.Component<any, any> {
  private readonly roleService = serviceFactory.getRoleService();
  private readonly groupUserService = serviceFactory.getGroupUserService();
  constructor(props) {
    super(props);
    this.state = {
      rolesCombobox: '',
      nameGroup: '',
      role: null,
      groupUser: [],
    };
  }
  componentDidMount() {
    this.props.dispatch(toolActions.selectTool(null));
    this.props.dispatch(groupActions.selectGroup(null));

    //combobox role
    this.roleService.GetallRole().subscribe((res) => {
      this.setState({ rolesCombobox: res.data.data });
    });
    // table groupUser
    this.groupUserService.getallGroupUser().subscribe((res) => {
      this.setState({ groupUser: res.data.data });
    });
  }
  onChange = (e) => {
    this.setState({ nameGroup: e.target.value });
  };

  onSubmit = (e) => {
    e.preventDefault();
    const body = {
      nameGroup: sanitizeASCIIString(this.state.nameGroup),
      roleId: this.state.roleId,
    };
    this.groupUserService.createGroupUser(body).subscribe((res) => {
      this.setState({ nameGroup: '', role: null });
      this.groupUserService.getallGroupUser().subscribe((res) => {
        this.setState({ groupUser: res.data.data });
      });
    });

    // cancel
    this.onClear();
  };

  onClear = () => {
    this.setState({});
  };
  handleChangeTool = (event, value) => {
    if (value) {
      this.setState({ roleId: value.id, role: value });
    }
  };
  handleDeleteGroupUserSuccess = () => {
    this.componentDidMount();
  };
  render = () => {
    return (
      <React.Fragment>
        <form noValidate autoComplete='off' onSubmit={this.onSubmit}>
          <div className='custom-buttoncreate'>
            <Typography variant='h6' component='h3'>
              New Group User
            </Typography>
            <Grid container spacing={4} alignItems='center'>
              <Grid item xs={4} justify='flex-end'>
                <TextField
                  required
                  label='Group User Name'
                  margin='normal'
                  variant='outlined'
                  name='nameGroup'
                  value={this.state.nameGroup}
                  onChange={this.onChange}
                  fullWidth
                  size='small'
                  placeholder='Please enter Group User Name'
                  style={{ marginTop: 16, marginBottom: 16 }}
                />
              </Grid>
              <Grid item xs={4}>
                <Autocomplete
                  onChange={this.handleChangeTool}
                  options={flattenOptions(this.state.rolesCombobox)}
                  getOptionLabel={(option) => option.title}
                  //  style={{ width: 300 }}
                  value={this.state.role}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label='Associate Role Name'
                      variant='outlined'
                      fullWidth
                      size='small'
                      style={{ marginTop: 16, marginBottom: 16 }}
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: 'off', // disable autocomplete and autofill
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={4}>
                <Button
                  variant='contained'
                  color='secondary'
                  size='medium'
                  type='submit'
                  startIcon={<SaveIcon />}
                >
                  ADD
                </Button>
              </Grid>
            </Grid>
          </div>
        </form>
        <FunctionGroupUser
          {...this.props}
          groupUser={this.state.groupUser}
          onDeleteSuccess={this.handleDeleteGroupUserSuccess}
        />
      </React.Fragment>
    );
  };
}
const flattenOptions = (options: any) => {
  let list = [];
  for (const option of options) {
    if (option.nameRole) {
      list.push({ title: option.nameRole, id: option._id });
    }
  }
  return list;
};
export default connect()(NewGroupUser);
