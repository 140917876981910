import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Link from '@material-ui/core/Link';
import 'react-perfect-scrollbar/dist/css/styles.css';
import PerfectScrollbar from 'react-perfect-scrollbar';

const useStyles = makeStyles({
  tableCell: {
    border: '1px solid black',
    borderCollapse: 'collapse',
    padding: 5,
    textAlign: 'left',
  },
  border: {
    border: '1px solid black',
  },
  table: {
    display: 'block',
    position: 'sticky',
    top: 0,
    left: 0,
    zIndex: 1,
    borderCollapse: 'collapse',
    '& th': {
      borderCollapse: 'collapse',
      padding: 1,
      fontWeight: 'normal',
      paddingLeft: 4,
      paddingRight: 4,
    },
    '& td': {
      border: '1px solid black',
      borderCollapse: 'collapse',
      padding: 2,
    },
  },
  colHeader: {
    minWidth: 350,
    textAlign: 'left',
  },
  colChild: {
    minWidth: 230,
    maxWidth: 230,
    textAlign: 'center',
  },
  colChildFixedHeight: {
    minWidth: 230,
    maxWidth: 230,
    textAlign: 'center',
    height: 45,
  },
  inputText: {
    textAlign: 'center',
    border: 'none',
    width: '100%',
    outline: 'none',
    resize: 'none',
  },
  checkBoxInput: {},
  headerMainCustom: {
    background: '#000000',
    color: '#ffffff',
    border: '1px solid #ffffff',
    minWidth: 350,
    textAlign: 'left',
  },
  headerCustom: {
    color: '#000000',
    minWidth: 350,
    textAlign: 'left',
    border: '1px solid black',
    background: '#f9f9f9',
  },
});

const colHeaders = [
  { key: 'checkbox', name: 'Actions', customHeight: 25 },

  { key: '', name: 'HW specifications link', customHeight: 63 },

  { key: '', name: 'Tool line X', customHeight: 45 },
  { key: '', name: 'Tool line Y', customHeight: 45 },
  {
    key: '',
    name: 'Low-end Line (uses blister package) - CTC/WM',
    customHeight: 45,
  },
  { key: '', name: 'Blue Line', customHeight: 45 },
  { key: '', name: 'Silver Line', customHeight: 45 },
  { key: '', name: 'OBDspace#', customHeight: 45 },
  { key: '', name: 'HFT Tool #', customHeight: 45 },

  { key: '', name: 'Tool ID', customHeight: 45 },
  {
    key: '',
    name: 'Reads & Clears Engine OBD Trouble Codes',
    customHeight: 45,
  },
  { key: '', name: 'Emission/SMOG Check Readiness 3-LED', customHeight: 45 },
  { key: '', name: 'Auto Re-Link', customHeight: 45 },
  { key: '', name: 'Code Priority', customHeight: 45 },
  { key: '', name: 'Code Severity Alert', customHeight: 45 },
  { key: '', name: 'Reads & Clears ABS/Brake Codes', customHeight: 45 },
  { key: '', name: 'Battery/Charging System Test', customHeight: 45 },
  { key: '', name: 'View Live Data Stream', customHeight: 45 },
  { key: '', name: 'Covers All 10 Modes', customHeight: 45 },
  {
    key: '',
    name: 'Oil Light Reset w/Parts List, Clears Service Light',
    customHeight: 45,
  },
  {
    key: '',
    name: 'Trip Cycle for Completing SMOG/Emission Check',
    customHeight: 45,
  },
  { key: '', name: 'Oil Level, Oil Life', customHeight: 45 },
  { key: '', name: 'Reads & Clears SRS/AirBag Codes', customHeight: 45 },
  { key: '', name: 'Graphing/Record/Playback', customHeight: 45 },
  { key: '', name: 'BMS/Battery Reset & Initialization', customHeight: 45 },
  { key: '', name: 'Reads Hybrid Battery Cells Voltage', customHeight: 45 },
  { key: '', name: 'All System Network Scan', customHeight: 45 },
  { key: '', name: 'Brake Pad Life', customHeight: 45 },
  { key: '', name: 'EPB/ABS Brake Bleeding/Caliper Service', customHeight: 45 },
  { key: '', name: 'Reads TPMS/Tire Pressure', customHeight: 45 },
  { key: '', name: 'Reads Transmission Temp ', customHeight: 45 },
  {
    key: '',
    name: 'Special Reset Function: SAS, DPF Regen, etc.',
    customHeight: 45,
  },
  {
    key: '',
    name: 'Enhanced Data Stream: Engine, AT, ABS, SRS',
    customHeight: 45,
  },
  { key: '', name: 'Bi-Directional Tests', customHeight: 45 },
  { key: '', name: 'Active Tests/System & component Tests', customHeight: 45 },
  { key: '', name: 'DPF Regen', customHeight: 45 },
  { key: '', name: 'OBD1 Coverage', customHeight: 45 },
  { key: '', name: 'Cable', customHeight: 45 },
  { key: '', name: 'LCD Screen Size', customHeight: 45 },
  { key: '', name: 'Pin Count', customHeight: 45 },
  { key: '', name: 'WiFi/BT', customHeight: 45 },
  {
    key: '',
    name: 'RepairSolutions w/Parts & Tool Buy on App',
    customHeight: 45,
  }, // Fixed
  {
    key: '',
    name: '- Fix Solutions, Parts Needed for Repair',
    customHeight: 45,
  },
  { key: '', name: '- Tool Needed for Repair', customHeight: 45 },
  { key: '', name: '- Commercial Repair Shop Referral', customHeight: 45 },
  { key: '', name: '- Scheduled Maintenance', customHeight: 45 },
  { key: '', name: '- Predicted Failure', customHeight: 45 },
];

function TableRow(props) {
  const classes = useStyles();
  const {
    columnName,
    data,
    rowIndex,
    onColumSeleted,
    onUpdateNewState,
    onUpdateSelectedColumn,
    isHiddenRowHeader,
    customRowHeaderHeight,
    isUsingCustomHeight,
    selectedColumns,
  } = props;

  const prepareData = data.map((arrRow) => {
    return arrRow[rowIndex];
  });

  const intialTextState = prepareData.reduce(
    (acc, currentValue, currentIndex) => {
      return Object.assign(acc, {
        [`row-${rowIndex}-col-${currentIndex}`]: currentValue,
      });
    },
    {}
  );

  const cancelUpdate = (event) => {
    event.preventDefault();
    const result = window.confirm(
      'Are you sure want to cancel all changes? Please save before proceed'
    );
    if (result == false) {
      return;
    }
    setCheck(false);
    window.location.reload();
  };

  const updateCurentColumn = (event, colIndex) => {
    event.preventDefault();
    onUpdateSelectedColumn(colIndex);
  };

  const [text, setText] = useState({
    ...intialTextState,
    [`row-41-col-${rowIndex}`]: null,
  });

  const [check, setCheck] = useState({});

  useEffect(() => {
    onUpdateNewState({ check, text });
  }, [text]);

  useEffect(() => {
    onUpdateNewState({ check, text });
  }, [check]);

  const data1 = localStorage.getItem('userInfo');
  const userInfo = JSON.parse(data1);
  return (
    <tr style={{ position: 'relative' }}>
      {/* Render rows header */}
      {!isHiddenRowHeader && (
        <th
          style={isUsingCustomHeight ? { height: customRowHeaderHeight } : null}
          className={
            (rowIndex >= 2 && rowIndex <= 8) || rowIndex == 41
              ? classes.headerMainCustom
              : classes.headerCustom
          }
        >
          {columnName}
        </th>
      )}
      {/* Render other columns */}
      {prepareData.map((colValue, index) => {
        if (rowIndex === 0) {
          return (
            <td
              style={
                isUsingCustomHeight ? { height: customRowHeaderHeight } : null
              }
              key={`row-${rowIndex}-col-${index}`}
              className={classes.colChild}
            >
              <input
                className={classes.checkBoxInput}
                type='checkbox'
                checked={!!check[`row-${rowIndex}-col-${index}`]}
                onChange={(e) => {
                  onColumSeleted({ [index]: e.target.checked });
                  setCheck({
                    ...check,
                    [`row-${rowIndex}-col-${index}`]: e.target.checked,
                  });
                }}
              />

              {userInfo?.role === 'Admin_Role' &&
                check[`row-${rowIndex}-col-${index}`] && (
                  <>
                    &nbsp;
                    <span>
                      <Link
                        href='#'
                        onClick={(e) => updateCurentColumn(e, index)}
                      >
                        Save
                      </Link>
                    </span>
                    &nbsp; &nbsp;
                    <span>
                      <Link href='#' onClick={cancelUpdate}>
                        Cancel
                      </Link>
                    </span>
                  </>
                )}
            </td>
          );
        } else if (rowIndex === 1) {
          return (
            <td
              style={
                isUsingCustomHeight ? { height: customRowHeaderHeight } : null
              }
              key={`row-${rowIndex}-col-${index}-${prepareData[index]}`}
              className={classes.colChild}
              style={{
                background: text[`row-${rowIndex}-col-${index}`]
                  ? '#e4e3e3'
                  : 'unset',
              }}
            >
              <textarea
                disabled={!selectedColumns[index]}
                style={{
                  background: text[`row-${rowIndex}-col-${index}`]
                    ? '#e4e3e3'
                    : 'unset',
                  height: 32,
                }}
                className={classes.inputText}
                type='text'
                value={text[`row-${rowIndex}-col-${index}`] || ''}
                onFocus={(e) => {}}
                onChange={(e) => {
                  setText({
                    ...text,
                    [`row-${rowIndex}-col-${index}`]: e.target.value,
                  });
                }}
              />

              <a
                onClick={(e) => {
                  e.preventDefault();
                  if (!text[`row-${rowIndex}-col-${index}`]) return;

                  props.history.push({
                    pathname: '/componentTool',
                    state: { toolName: text[`row-${rowIndex}-col-${index}`] },
                  });
                }}
              >
                Go to tool
              </a>
            </td>
          );
        } else if (rowIndex === 41) {
          return (
            <td
              key={`row-${rowIndex}-col-${index}-${prepareData[index]}`}
              style={{ background: '#000' }}
              className={classes.colChildFixedHeight}
            >
              &nbsp;
            </td>
          );
        } else if (rowIndex >= 2 && rowIndex <= 8) {
          return (
            <td
              style={
                isUsingCustomHeight ? { height: customRowHeaderHeight } : null
              }
              key={`row-${rowIndex}-col-${index}-${prepareData[index]}`}
              className={classes.colChildFixedHeight}
              style={{
                background: '#000',
                color: '#ffffff',
                border: '1px solid #ffffff',
              }}
            >
              <textarea
                disabled={!selectedColumns[index]}
                style={{
                  background: '#000',
                  color: '#ffffff',
                  border: '1px solid #000000de',
                }}
                className={classes.inputText}
                type='text'
                value={text[`row-${rowIndex}-col-${index}`] || ''}
                onFocus={(e) => {}}
                onChange={(e) => {
                  setText({
                    ...text,
                    [`row-${rowIndex}-col-${index}`]: e.target.value,
                  });
                }}
              />
            </td>
          );
        } else
          return (
            <td
              style={
                isUsingCustomHeight ? { height: customRowHeaderHeight } : null
              }
              key={`row-${rowIndex}-col-${index}-${prepareData[index]}`}
              className={classes.colChildFixedHeight}
              style={{
                background: text[`row-${rowIndex}-col-${index}`]
                  ? '#e4e3e3'
                  : 'unset',
              }}
            >
              <textarea
                disabled={!selectedColumns[index]}
                style={{
                  background: text[`row-${rowIndex}-col-${index}`]
                    ? '#e4e3e3'
                    : 'unset',
                }}
                className={classes.inputText}
                type='text'
                value={text[`row-${rowIndex}-col-${index}`] || ''}
                onFocus={(e) => {}}
                onChange={(e) => {
                  setText({
                    ...text,
                    [`row-${rowIndex}-col-${index}`]: e.target.value,
                  });
                }}
              />
            </td>
          );
      })}
    </tr>
  );
}
export function TableMasterComponent(props) {
  const classes = useStyles();
  const {
    data,
    onColumSeleted,
    onUpdateNewState,
    onUpdateSelectedColumn,
    selectedColumns,
  } = props;
  const normalizeData = data.map((obj) => Object.values(obj));
  if (data.length < 1) return null;
  return (
    <PerfectScrollbar>
      <div
        id='master-table'
        style={{ display: 'flex', position: 'relative', width: 'max-content' }}
      >
        {/* Empty table, render row header only */}
        <table className={classes.table}>
          <tbody>
            {colHeaders.map((colHeader, index) => {
              return (
                <TableRow
                  customRowHeaderHeight={colHeader.customHeight}
                  isUsingCustomHeight={true}
                  history={props.history}
                  onUpdateSelectedColumn={() => {}}
                  onUpdateNewState={() => {}}
                  onColumSeleted={() => {}}
                  data={[]}
                  rowIndex={index}
                  key={colHeader.name}
                  columnName={colHeader.name}
                ></TableRow>
              );
            })}
          </tbody>
        </table>
        <div id='data-table-container' style={{ display: 'block', zIndex: 0 }}>
          {/*  data table */}
          <PerfectScrollbar>
            <table className={classes.table}>
              <tbody>
                {colHeaders.map((colHeader, index) => {
                  return (
                    <TableRow
                      customRowHeaderHeight={colHeader.customHeight}
                      isUsingCustomHeight={true}
                      selectedColumns={selectedColumns}
                      isHiddenRowHeader
                      history={props.history}
                      onUpdateSelectedColumn={onUpdateSelectedColumn}
                      onUpdateNewState={onUpdateNewState}
                      onColumSeleted={onColumSeleted}
                      data={normalizeData}
                      rowIndex={index}
                      key={colHeader.name}
                      columnName={colHeader.name}
                    ></TableRow>
                  );
                })}
              </tbody>
            </table>
          </PerfectScrollbar>
        </div>
      </div>
    </PerfectScrollbar>
  );
}
