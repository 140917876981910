import palette from './palette';

export default {
  htmlFontSize: 16,
  fontFamily: [
    'Roboto',
    'Helvetica Neue',
    'Helvetica',
    'Arial',
    'sans-serif',
  ].join(','),
  body: {
    color: palette.text.primary,
    fontSize: 16,
    fontWeight: 400,
  },
};
