import { catchError } from 'rxjs/operators';
import { WebClientUtil } from '../../util/WebClientUtil';
import { API_ENDPOINT } from '../../../../config';
import { Observable, of } from 'rxjs';
import { MasterComponentService } from './MasterComponentService';
export class MasterComponentServiceImpl implements MasterComponentService {
  private baseUrl = API_ENDPOINT.versionTrackingURL;
  createMasterComp(body: any): Observable<any> {
    const url = `${this.baseUrl}/mastercomponent`;
    return WebClientUtil.postObject(url, body);
  }
  getallMasterComp(): Observable<any> {
    const url = `${this.baseUrl}/mastercomponent`;
    return WebClientUtil.getObject(url);
  }
  updateMasterComp(newModel: any) {
    const url = `${this.baseUrl}/mastercomponent`;
    return WebClientUtil.putObject(url, newModel);
  }
  deleteMultipleMasterComp(arrayId: string[]): Observable<any> {
    const url = `${this.baseUrl}/mastercomponent/delete/DeleteMultiple`;
    return WebClientUtil.postObject(url, arrayId);
  }
  getOneMasterCompById(id: string): Observable<any> {
    const url = `${this.baseUrl}/mastercomponent/${id}`;
    return WebClientUtil.getObject(url);
  }
  checkHWSpecificationsExistToolComp(body: any): Observable<any> {
    const url = `${this.baseUrl}/mastercomponent/checkHWSpecificationsExistToolComp`;
    return WebClientUtil.postObject(url, body);
  }
}
