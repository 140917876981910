import React from 'react';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { serviceFactory } from '../../common/config/ServiceFactory';
import { Grid, Paper } from '@material-ui/core';
import { FunctionTool } from './functiontool';
import { connect } from 'react-redux';
import { fetchGroupAndToolData } from '../../../redux/actions/horizontalNavbarActions';
import SaveIcon from '@material-ui/icons/Save';
import { sanitizeASCIIString } from '../../../utils/StringUtils';
class EditTool extends React.Component<any, any> {
  private readonly groupService = serviceFactory.getGroupService();
  private readonly toolService = serviceFactory.getToolService();
  constructor(props) {
    super(props);
    this.state = {
      toolName: '',
      groupnamearr: [],
      error: '',
      group: null,
    };
  }
  componentDidMount() {
    // combobox
    this.groupService.getAllGroupTool().subscribe((res) => {
      this.setState({
        groupnamearr: res.data.data,
      });
    });
    // tools

    if (this.props.match.params && this.props.match.params.id) {
      const toolId = this.props.match.params.id;
      this.toolService.getOneTool(toolId).subscribe((res) => {
        if (res.data.data) {
          this.setState({
            toolName: res.data.data.toolName,
            group: {
              _id: res.data.data.groupId._id,
              title: res.data.data.groupId.groupName,
            },
          });
        }
      });
    }
  }
  onSubmit = (e) => {
    e.preventDefault();
    const body = {
      toolName: sanitizeASCIIString(this.state.toolName),
      groupId: this.state.group.id,
    };
    const idTool = this.props.match.params.id;
    this.toolService.updateTool(idTool, body).subscribe((res) => {
      if (res.response && res.response.data.error) {
        this.setState({ error: res.response.data.error });
        console.error(res.response.data.error);
        window.alert(res.response.data.error);
      } else {
        this.navigateBack();
        this.onClear();
        this.props.dispatch(fetchGroupAndToolData());
      }
    });
  };

  onClear = () => {
    this.setState({
      toolName: '',
      // groupId: '',
    });
  };
  navigateBack = () => {
    this.props.history.goBack();
  };
  onChange = (e) => {
    this.setState({
      toolName: e.target.value,
    });
  };
  handleChangeTool = (event, value) => {
    if (value) {
      this.setState({ group: value });
    }
  };
  render = () => {
    return (
      <form
        noValidate
        autoComplete='off'
        onSubmit={this.onSubmit}
        style={{ height: '100%' }}
      >
        <div className='custom-buttoncreate'>
          <Typography variant='h6' component='h3' style={{ marginBottom: 16 }}>
            Update Tool:
          </Typography>
          <Grid container spacing={4} alignItems='center'>
            <Grid item xs={4}>
              <TextField
                required
                id='outlined-required'
                label='Required'
                variant='outlined'
                name='toolName'
                value={this.state.toolName}
                onChange={this.onChange}
                fullWidth
                size='small'
              />
            </Grid>
            <Grid item xs={4}>
              <Autocomplete
                id='combo-box-demo'
                onChange={this.handleChangeTool}
                options={flattenOptions(this.state.groupnamearr)}
                getOptionLabel={(option) => option.title}
                //style={{ width: 200 }}
                value={this.state.group}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label='Group'
                    variant='outlined'
                    fullWidth
                    size='small'
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: 'off', // disable autocomplete and autofill
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={4}>
              <Button
                variant='contained'
                color='secondary'
                type='submit'
                startIcon={<SaveIcon />}
              >
                UPDATE
              </Button>
            </Grid>
          </Grid>
        </div>
      </form>
    );
  };
}
const flattenOptions = (options: any) => {
  let list = [];
  for (const option of options) {
    if (option.groupName) {
      list.push({ title: option.groupName, id: option._id });
    }
  }
  return list;
};
export default connect()(EditTool);
