import React from 'react';
import { AppBar, Toolbar, Typography } from '@material-ui/core';

const FullPageLayout = (props) => {
  const { children } = props;
  return (
    <React.Fragment>
      <AppBar style={{ height: 60, display: 'flex', justifyContent: 'center' }}>
        <Toolbar>
          <Typography variant='h6' component='h2' style={{ paddingLeft: 15 }}>
            Innova FW & DB Released Management
          </Typography>
        </Toolbar>
      </AppBar>
      <main style={{ height: '100%', paddingTop: 60 }}>{children}</main>
    </React.Fragment>
  );
};
export default FullPageLayout;
