import React, { useState } from 'react';
import MUIDataTable from 'mui-datatables';
import { Link } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import { TableRow, TableCell } from '@material-ui/core';
import '../VersionTracking/VersionTracking.scss';
import { connect } from 'react-redux';
import * as toolActions from '../../../redux/actions/toolActions';
import * as groupActions from '../../../redux/actions/groupActions';
import { TableMasterComponent } from './TableMasterComponent';
import { serviceFactory } from '../../common/config/ServiceFactory';
import { MasterComponentService } from '../../common/service/masterComponent/MasterComponentService';
import AlertDialog from '../../common/component/Dialog/AlertDialog';
import ResultInfoDialog from '../../common/component/Dialog/ResultInfoDialog';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import { CSVLink, CSVDownload } from 'react-csv';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';
import List from '@material-ui/core/List';
import Toolbar from '@material-ui/core/Toolbar';
import Grid from '@material-ui/core/Grid';
import './style.scss';
import { Prompt } from 'react-router';
import { sanitizeASCIIString } from '../../../utils/StringUtils';
class FunctionMasterComp extends React.Component<any, any> {
  constructor(props) {
    super(props);
    this.masterComponentService = serviceFactory.getMasterComponentService();
    this.state = {
      dataMaster: [],
      filterTableData: [],
      columnSelectedIndex: {},
      isLoading: false,
      mergeChild: {},
      openConfirmDeleteMasterComp: false,
      openResultDialog: false,
      resultInfo: '',
      selectToolId: { title: 'All', id: 'all' },
    };
  }
  private masterComponentService: MasterComponentService;
  private currentIndexArr: string[] = undefined;
  defaultAddData = {
    _id: '',
    HW_Specifications: '',
    tool_LineX: '',
    tool_LineY: '',
    Low_EndLine: '',
    Blue_Line: '',
    Silver_Line: '',
    OBD_Space: '',
    HFT_Tool: '',
    ToolsID: '',
    RClears_EngineOBD: '',
    Emission_SMOG_3LED: '',
    Auto_ReLink: '',
    Code_Priority: '',
    Code_Severity: '',
    RClears_ABS_Brake: '',
    Battery_Charging: '',
    View_LiveData: '',
    Covers_All_Modes: '',
    OilReset_Clears_Service: '',
    TripCycle_SMOGEmiss: '',
    OilLevel_Life: '',
    RClears_SRS_AirBag: '',
    Graphing_Record_Playback: '',
    BMS_Battery_Initialization: '',
    RHybrid_Battery_Voltage: '',
    All_Network_Scan: '',
    Brake_Pad_Life: '',
    EPB_ABS_Caliper: '',
    RTPMS_Tire: '',
    RTransmission: '',
    Reset_Func_SAS_DPF: '',
    Enhanced_Engine_AT_ABS_SRS: '',
    Bi_Directional: '',
    Active_Tests_compo: '',
    DPF_Regen: '',
    OBD1_Coverage: '',
    Cable: '',
    LCD: '',
    Pin_Count: '',
    WiFi_BT: '',
    // RepairSolutions w/Parts & Tool Buy on App
    RepairSolutions_WParts_And_Tool: null,
    FixSolutions_Parts: '',
    Tool_Repair: '',
    Commercial_Repair: '',
    Scheduled_Maintenance: '',
    Predicted_Failure: '',
  };

  CSV_ColHeaders = [
    { key: 'HW_Specifications', label: 'HW specifications link' },

    { key: 'tool_LineX', label: 'Tool line X' },
    { key: 'tool_LineY', label: 'Tool line Y' },
    {
      key: 'Low_EndLine',
      label: 'Low-end Line (uses blister package) - CTC/WM',
    },
    { key: 'Blue_Line', label: 'Blue Line' },
    { key: 'Silver_Line', label: 'Silver Line' },
    { key: 'OBD_Space', label: 'OBDspace#' },
    { key: 'HFT_Tool', label: 'HFT Tool #' },

    { key: 'ToolsID', label: 'Tool ID' },
    {
      key: 'RClears_EngineOBD',
      label: 'Reads & Clears Engine OBD Trouble Codes',
    },
    {
      key: 'Emission_SMOG_3LED',
      label: 'Emission/SMOG Check Readiness 3-LED',
    },
    { key: 'Auto_ReLink', label: 'Auto Re-Link' },
    { key: 'Code_Priority', label: 'Code Priority' },
    { key: 'Code_Severity', label: 'Code Severity Alert' },
    { key: 'RClears_ABS_Brake', label: 'Reads & Clears ABS/Brake Codes' },
    { key: 'Battery_Charging', label: 'Battery/Charging System Test' },
    { key: 'View_LiveData', label: 'View Live Data Stream' },
    { key: 'Covers_All_Modes', label: 'Covers All 10 Modes' },
    {
      key: 'OilReset_Clears_Service',
      label: 'Oil Light Reset w/Parts List, Clears Service Light',
    },
    {
      key: 'TripCycle_SMOGEmiss',
      label: 'Trip Cycle for Completing SMOG/Emission Check',
    },
    { key: 'OilLevel_Life', label: 'Oil Level, Oil Life' },
    { key: 'RClears_SRS_AirBag', label: 'Reads & Clears SRS/AirBag Codes' },
    { key: 'Graphing_Record_Playback', label: 'Graphing/Record/Playback' },
    {
      key: 'BMS_Battery_Initialization',
      label: 'BMS/Battery Reset & Initialization',
    },
    {
      key: 'RHybrid_Battery_Voltage',
      label: 'Reads Hybrid Battery Cells Voltage',
    },
    { key: 'All_Network_Scan', label: 'All System Network Scan' },
    { key: 'Brake_Pad_Life', label: 'Brake Pad Life' },
    {
      key: 'EPB_ABS_Caliper',
      label: 'EPB/ABS Brake Bleeding/Caliper Service',
    },
    { key: 'RTPMS_Tire', label: 'Reads TPMS/Tire Pressure' },
    { key: 'RTransmission', label: 'Reads Transmission Temp ' },
    {
      key: 'Reset_Func_SAS_DPF',
      label: 'Special Reset Function: SAS, DPF Regen, etc.',
    },
    {
      key: 'Enhanced_Engine_AT_ABS_SRS',
      label: 'Enhanced Data Stream: Engine, AT, ABS, SRS',
    },
    { key: 'Bi_Directional', label: 'Bi-Directional Tests' },
    {
      key: 'Active_Tests_compo',
      label: 'Active Tests/System & component Tests',
    },
    { key: 'DPF_Regen', label: 'DPF Regen' },
    { key: 'OBD1_Coverage', label: 'OBD1 Coverage' },
    { key: 'Cable', label: 'Cable' },
    { key: 'LCD', label: 'LCD Screen Size' },
    { key: 'Pin_Count', label: 'Pin Count' },
    { key: 'WiFi_BT', label: 'WiFi/BT ' },
    {
      key: 'RepairSolutions_WParts_And_Tool',
      label: 'RepairSolutions w/Parts & Tool Buy on App',
    }, // Fixed
    {
      key: 'FixSolutions_Parts',
      label: 'Fix Solutions, Parts Needed for Repair',
    },
    { key: 'Tool_Repair', label: 'Tool Needed for Repair' },
    { key: 'Commercial_Repair', label: 'Commercial Repair Shop Referral' },
    { key: 'Scheduled_Maintenance', label: 'Scheduled Maintenance' },
    { key: 'Predicted_Failure', label: 'Predicted Failure' },
  ];

  componentDidMount() {
    this.props.dispatch(toolActions.selectTool(null));
    this.props.dispatch(groupActions.selectGroup(null));

    // call get all api here to get data from backend
    // set state in dataMaster
    this.masterComponentService.getallMasterComp().subscribe((res) => {
      this.setState((preState) => {
        return {
          dataMaster: res.data.data,
          filterTableData: res.data.data,
        };
      });
    });
  }

  handleColumnSelected = (e) => {
    this.setState(
      (preState) => {
        return {
          columnSelectedIndex: { ...preState.columnSelectedIndex, ...e },
        };
      },
      () => {
        console.log('columnSelectedIndex', this.state.columnSelectedIndex);
      }
    );
  };
  handleCloseConfirm = () => {
    this.setState({
      openConfirmDeleteMasterComp: false,
    });
  };
  handleNewState = (e) => {
    this.setState((preState) => {
      return {
        mergeChild: {
          ...preState.mergeChild,
          ...e.text,
          ...e.check,
        },
      };
    });
  };
  handleUpdateSelectedColumn = async (colIndex, showSuccess = true) => {
    const { mergeChild } = this.state;
    let isCheckSuccess = false;

    const dataFilter = Object.entries(mergeChild)
      .filter((array) => {
        return array[0].endsWith(`col-${colIndex}`);
      })
      .sort((a1, a2) => {
        const n1 = +a1[0].split('-')[1];
        const n2 = +a2[0].split('-')[1];
        if (n1 > n2) {
          return 1;
        } else if (n1 < n2) {
          return -1;
        } else {
          return 0;
        }
      })
      .reduce((acc, curVal) => {
        const key = curVal[0].split('-')[1];
        return Object.assign(acc, { [key]: curVal[1] });
      }, {});

    try {
      const res1 = await this.masterComponentService
        .checkHWSpecificationsExistToolComp({
          HW_Specifications:
            (dataFilter[1] && sanitizeASCIIString(dataFilter[1])) || '',
        })
        .toPromise();
      // error handling here
      if (res1.response && res1.response.data.error) {
        throw res1.response.data.error;
      }
      isCheckSuccess = true;
    } catch (err) {
      isCheckSuccess = false;
      window.alert(err);
    }
    if (isCheckSuccess) {
      const buildBody = {
        HW_Specifications:
          (dataFilter[1] && sanitizeASCIIString(dataFilter[1])) || '',
        tool_LineX: dataFilter[2] || '',
        tool_LineY: dataFilter[3] || '',
        Low_EndLine: dataFilter[4] || '',
        Blue_Line: dataFilter[5] || '',
        Silver_Line: dataFilter[6] || '',
        OBD_Space: dataFilter[7] || '',
        HFT_Tool: dataFilter[8] || '',
        ToolsID: dataFilter[9] || '',
        RClears_EngineOBD: dataFilter[10] || '',
        Emission_SMOG_3LED: dataFilter[11] || '',
        Auto_ReLink: dataFilter[12] || '',
        Code_Priority: dataFilter[13] || '',
        Code_Severity: dataFilter[14] || '',
        RClears_ABS_Brake: dataFilter[15] || '',
        Battery_Charging: dataFilter[16] || '',
        View_LiveData: dataFilter[17] || '',
        Covers_All_Modes: dataFilter[18] || '',
        OilReset_Clears_Service: dataFilter[19] || '',
        TripCycle_SMOGEmiss: dataFilter[20] || '',
        OilLevel_Life: dataFilter[21] || '',
        RClears_SRS_AirBag: dataFilter[22] || '',
        Graphing_Record_Playback: dataFilter[23] || '',
        BMS_Battery_Initialization: dataFilter[24] || '',
        RHybrid_Battery_Voltage: dataFilter[25] || '',
        All_Network_Scan: dataFilter[26] || '',
        Brake_Pad_Life: dataFilter[27] || '',
        EPB_ABS_Caliper: dataFilter[28] || '',
        RTPMS_Tire: dataFilter[29] || '',
        RTransmission: dataFilter[30] || '',
        Reset_Func_SAS_DPF: dataFilter[31] || '',
        Enhanced_Engine_AT_ABS_SRS: dataFilter[32] || '',
        Bi_Directional: dataFilter[33] || '',
        Active_Tests_compo: dataFilter[34] || '',
        DPF_Regen: dataFilter[35] || '',
        OBD1_Coverage: dataFilter[36] || '',
        Cable: dataFilter[37] || '',
        LCD: dataFilter[38] || '',
        Pin_Count: dataFilter[39] || '',
        WiFi_BT: dataFilter[40] || '',
        RepairSolutions_WParts_And_Tool: null,
        FixSolutions_Parts: dataFilter[42] || '',
        Tool_Repair: dataFilter[43] || '',
        Commercial_Repair: dataFilter[44] || '',
        Scheduled_Maintenance: dataFilter[45] || '',
        Predicted_Failure: dataFilter[46] || '', // 46
      };
      if (this.state.filterTableData[colIndex]['_id']) {
        buildBody['_id'] = this.state.filterTableData[colIndex]['_id'];
      }
      try {
        const saveRes = await this.masterComponentService
          .updateMasterComp(buildBody)
          .toPromise();
        // error handling here
        if (saveRes.response && saveRes.response.data.error) {
          throw saveRes.response.data.error;
        }
        isCheckSuccess = true;
      } catch (err) {
        isCheckSuccess = false;
        window.alert(err);
      }
    }
    if (isCheckSuccess && showSuccess) {
      window.alert('Update success');
      window.location.reload();
    }
    return isCheckSuccess;
  };
  handleCloseResultDialog = () => {
    this.setState(
      {
        openResultDialog: false,
      },
      () => {
        window.location.reload();
      }
    );
  };
  handleDeleteSelected = (e) => {
    // first check if there is selected column, than call api
    if (!Object.values(this.state.columnSelectedIndex).includes(true)) {
      alert('Please select one or multiple column to delete');
      return;
    }
    this.setState({
      openConfirmDeleteMasterComp: true,
    });
  };

  saveAllSelectedColumn = async () => {
    const { columnSelectedIndex } = this.state;
    let result = true;
    for (const key in columnSelectedIndex) {
      if (columnSelectedIndex[key]) {
        const returnValue = await this.handleUpdateSelectedColumn(key, false);
        if (!returnValue) {
          result = false;
        }
      }
    }
    if (result) {
      window.alert('Save all success');
    }
    window.location.reload();
  };

  handleAcceptDeleleteMasterComp = () => {
    this.setState(
      {
        openConfirmDeleteMasterComp: false,
      },
      () => {
        const arrayIndex = Object.keys(this.state.columnSelectedIndex);
        const currentIndexArr = arrayIndex.map(
          (item) => this.state.filterTableData[item]['_id']
        );
        this.currentIndexArr = currentIndexArr;
        this.masterComponentService
          .deleteMultipleMasterComp(currentIndexArr)
          .subscribe(async (res) => {
            if (res.response && res.response.data.error) {
              this.setState({ error: res.response.data.error });
              console.error(res.response.data.error);
              // window.alert(res.response.data.error);
              this.setState({
                openResultDialog: true,
                isDeleteMasterCompSuccess: false,
                resultInfo: `Can not delete this Master Component:\n${res.response.data.error}`,
              });
            } else {
              // this.props.onDeleteSuccess();
              this.currentIndexArr = undefined;
              this.setState({
                openResultDialog: true,
                isDeleteMasterCompSuccess: true,
                resultInfo: `Delete Master Component successful`,
              });
              // await this.componentDidMount();
            }
          });
      }
    );
  };
  hangleChangeToolID = (event, value) => {
    if (
      Object.entries(this.state.columnSelectedIndex).filter((array) => array[1])
        .length > 0
    ) {
      const result = window.confirm(
        'You have unsaved selected column, Are you sure want to leave?'
      );
      if (result == false) {
        return;
      } else {
        this.setState({
          columnSelectedIndex: {},
        });
      }
    }
    this.setState(
      {
        isLoading: true,
        selectToolId: value ? { title: value.title, id: value.id } : {},
      },
      () => {
        if (value != null) {
          const selectedToolId = this.state.selectToolId.id;
          const filterTableData =
            selectedToolId === 'all'
              ? this.state.dataMaster
              : this.state.dataMaster.filter(function (column) {
                  return column._id === selectedToolId;
                });

          // @todo make sure setState is always async
          setTimeout(() => {
            this.setState({
              filterTableData,
              isLoading: false,
            });
          }, 0);
        } else if (value == null) {
          this.setState({
            // selectToolId: { title: 'All', id: 'all' },
            filterTableData: this.state.dataMaster,
            isLoading: false,
          });
        }
      }
    );
  };

  render() {
    const data = localStorage.getItem('userInfo');
    const userInfo = JSON.parse(data);

    const handleClickData = (e) => {
      e.preventDefault();
      this.setState((preState) => {
        return {
          filterTableData: [...preState.filterTableData, this.defaultAddData],
        };
      });
    };

    // const saveAllChanges = async (e) => {
    //   // find all the added columns
    //   // validate unsave selected column before save all columns
    //   if (
    //     Object.entries(this.state.columnSelectedIndex).filter(
    //       (array) => array[1]
    //     ).length > 0
    //   ) {
    //     const result = window.confirm(
    //       'You have unsaved selected column, Are you sure want to leave?'
    //     );
    //     if (result == false) {
    //       return;
    //     } else {
    //       this.setState({
    //         columnSelectedIndex: {},
    //       });
    //     }
    //   }
    //   const { filterTableData, mergeChild } = this.state;
    //   let success = true;
    //   const arrayIndexAdded = filterTableData.map(
    //     (item, i) => (item._id === '' ? i : '') // save new added column
    //   );
    //   for (const colIndex of arrayIndexAdded) {
    //     let isCheckSuccess = false;
    //     if (colIndex !== '') {
    //       const dataFilter = Object.entries(mergeChild)
    //         .filter((array) => {
    //           return array[0].endsWith(`col-${colIndex}`);
    //         })
    //         .sort((a1, a2) => {
    //           const n1 = +a1[0].split('-')[1];
    //           const n2 = +a2[0].split('-')[1];
    //           if (n1 > n2) {
    //             return 1;
    //           } else if (n1 < n2) {
    //             return -1;
    //           } else {
    //             return 0;
    //           }
    //         })
    //         .reduce((acc, curVal) => {
    //           const key = curVal[0].split('-')[1];
    //           return Object.assign(acc, { [key]: curVal[1] });
    //         }, {});

    //       try {
    //         const res1 = await this.masterComponentService
    //           .checkHWSpecificationsExistToolComp({
    //             HW_Specifications:
    //               (dataFilter[1] && dataFilter[1].trim()) || '',
    //           })
    //           .toPromise();
    //         // error handling here
    //         if (res1.response && res1.response.data.error) {
    //           throw res1.response.data.error;
    //         }
    //         isCheckSuccess = true;
    //       } catch (err) {
    //         success = false;
    //         window.alert(err);
    //       }
    //       if (isCheckSuccess) {
    //         const buildBody = {
    //           HW_Specifications: dataFilter[1] || '',
    //           tool_LineX: dataFilter[2] || '',
    //           tool_LineY: dataFilter[3] || '',
    //           Low_EndLine: dataFilter[4] || '',
    //           Blue_Line: dataFilter[5] || '',
    //           Silver_Line: dataFilter[6] || '',
    //           OBD_Space: dataFilter[7] || '',
    //           HFT_Tool: dataFilter[8] || '',
    //           ToolsID: dataFilter[9] || '',
    //           RClears_EngineOBD: dataFilter[10] || '',
    //           Emission_SMOG_3LED: dataFilter[11] || '',
    //           Auto_ReLink: dataFilter[12] || '',
    //           Code_Priority: dataFilter[13] || '',
    //           Code_Severity: dataFilter[14] || '',
    //           RClears_ABS_Brake: dataFilter[15] || '',
    //           Battery_Charging: dataFilter[16] || '',
    //           View_LiveData: dataFilter[17] || '',
    //           Covers_All_Modes: dataFilter[18] || '',
    //           OilReset_Clears_Service: dataFilter[19] || '',
    //           TripCycle_SMOGEmiss: dataFilter[20] || '',
    //           OilLevel_Life: dataFilter[21] || '',
    //           RClears_SRS_AirBag: dataFilter[22] || '',
    //           Graphing_Record_Playback: dataFilter[23] || '',
    //           BMS_Battery_Initialization: dataFilter[24] || '',
    //           RHybrid_Battery_Voltage: dataFilter[25] || '',
    //           All_Network_Scan: dataFilter[26] || '',
    //           Brake_Pad_Life: dataFilter[27] || '',
    //           EPB_ABS_Caliper: dataFilter[28] || '',
    //           RTPMS_Tire: dataFilter[29] || '',
    //           RTransmission: dataFilter[30] || '',
    //           Reset_Func_SAS_DPF: dataFilter[31] || '',
    //           Enhanced_Engine_AT_ABS_SRS: dataFilter[32] || '',
    //           Bi_Directional: dataFilter[33] || '',
    //           Active_Tests_compo: dataFilter[34] || '',
    //           DPF_Regen: dataFilter[35] || '',
    //           OBD1_Coverage: dataFilter[36] || '',
    //           Cable: dataFilter[37] || '',
    //           LCD: dataFilter[38] || '',
    //           Pin_Count: dataFilter[39] || '',
    //           WiFi_BT: dataFilter[40] || '',
    //           RepairSolutions_WParts_And_Tool: null,
    //           FixSolutions_Parts: dataFilter[42] || '',
    //           Tool_Repair: dataFilter[43] || '',
    //           Commercial_Repair: dataFilter[44] || '',
    //           Scheduled_Maintenance: dataFilter[45] || '',
    //           Predicted_Failure: dataFilter[46] || '', // 46
    //         };
    //         try {
    //           const saveRes = await this.masterComponentService
    //             .createMasterComp(buildBody)
    //             .toPromise();
    //           // error handling here
    //           if (saveRes.response && saveRes.response.data.error) {
    //             throw saveRes.response.data.error;
    //           }
    //         } catch (err) {
    //           success = false;
    //           window.alert(err);
    //         }
    //       }
    //     }
    //   }

    //   if (success) {
    //     window.alert('Save all success');
    //   }
    //   window.location.reload();
    // };

    const getData = () => {
      const newData = this.state.dataMaster.map((item) => {
        const { __v, _id, ...rest } = item;
        return rest;
      });
      const normalizeData = newData.map((obj) => Object.values(obj));
      const downloadData = this.CSV_ColHeaders.map((header, index) => {
        let newRow = normalizeData.map((row) => {
          return row[index];
        });
        newRow = [header.label, ...newRow];
        return newRow;
      });
      return downloadData;
    };

    const dataToolID = (options: any) => {
      const list = [{ title: 'All', id: 'all' }];
      for (const option of options) {
        if (option.ToolsID) {
          list.push({ title: option.ToolsID, id: option._id });
        }
      }
      return list;
    };

    return (
      <React.Fragment>
        <nav
          className='custom-navbar'
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginBottom: 16,
            paddingLeft: 8,
            paddingRight: 8,
          }}
        >
          <AlertDialog
            open={this.state.openConfirmDeleteMasterComp}
            onClose={this.handleCloseConfirm}
            title='Do you want to delete this Master Component?'
            okText='OK'
            cancelText='Cancel'
            onAccept={this.handleAcceptDeleleteMasterComp}
            onCancel={this.handleCloseConfirm}
          />
          <ResultInfoDialog
            open={this.state.openResultDialog}
            onClose={this.handleCloseResultDialog}
            resultInfo={this.state.resultInfo}
            okText='OK'
            onAccept={this.handleCloseResultDialog}
            isOpSuccess={this.state.isDeleteMasterCompSuccess}
          />
          {userInfo?.role === 'Admin_Role' && (
            <div
              style={{
                display: 'flex',
              }}
            >
              <a
                style={{ fontSize: 16, marginRight: 16 }}
                onClick={handleClickData}
              >
                Add Master Component
              </a>

              {Object.entries(this.state.columnSelectedIndex).filter(
                (array) => array[1]
              ).length > 0 && (
                <a
                  style={{ fontSize: 16, marginRight: 16 }}
                  onClick={this.handleDeleteSelected}
                >
                  Delete selected
                </a>
              )}

              {/* {Object.entries(this.state.columnSelectedIndex).filter(
                (array) => array[1]
              ).length > 0 && (
                <a
                  style={{ fontSize: 16 }}
                  onClick={this.saveAllSelectedColumn}
                >
                  Save all seleted
                </a>
              )} */}
            </div>
          )}

          <div style={{ display: 'flex', width: 'auto', position: 'relative' }}>
            <CSVLink data={getData()} filename={'MasterComponent.csv'}>
              <Tooltip title={'Download CSV'} className='hoverFileName'>
                <IconButton style={{}}>
                  <CloudDownloadIcon />
                </IconButton>
              </Tooltip>
            </CSVLink>
            <Autocomplete
              onChange={this.hangleChangeToolID}
              options={dataToolID(this.state.dataMaster)}
              getOptionLabel={(option) => option.title}
              value={this.state.selectToolId}
              style={{ width: 200, marginLeft: 16 }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label='Filter Tool ID'
                  fullWidth
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: 'off', // disable autocomplete and autofill
                  }}
                  InputLabelProps={{ shrink: true }}
                />
              )}
            />
          </div>
        </nav>
        {this.state.filterTableData.length > 0 &&
        this.state.isLoading == false ? (
          <div
            id='tables-container'
            style={{
              border: '1px solid #ccc',
              boxShadow: '2px 1px 35px -11px rgba(0,0,0,0.75)',
              minHeight: 400,
              maxHeight: 700,
              height: 700,
              overflow: 'auto',
              padding: 16,
              position: 'relative',
            }}
          >
            <TableMasterComponent
              selectedColumns={this.state.columnSelectedIndex}
              history={this.props.history}
              onUpdateSelectedColumn={this.saveAllSelectedColumn}
              onUpdateNewState={this.handleNewState}
              onColumSeleted={this.handleColumnSelected}
              data={this.state.filterTableData}
            />
            <Prompt
              when={
                Object.entries(this.state.columnSelectedIndex).filter(
                  (array) => array[1]
                ).length > 0
              }
              message='You have unsaved selected column, Are you sure want to leave?'
            />
          </div>
        ) : null}
      </React.Fragment>
    );
  }
}
export default connect()(FunctionMasterComp);
