import { catchError } from 'rxjs/operators';
import { WebClientUtil } from '../../util/WebClientUtil';
import { API_ENDPOINT } from '../../../../config';
import { Observable, of } from 'rxjs';
import { StatusBugService } from './StatusBugService';
export class StatusBugServiceImpl implements StatusBugService {
  private baseUrl = API_ENDPOINT.versionTrackingURL;
  getallStatusBug(): Observable<any> {
    const url = `${this.baseUrl}/statusBug`;
    return WebClientUtil.getObject(url);
  }
  checkIfStatusBugExist(body: any): Observable<any> {
    const url = `${this.baseUrl}/statusbug/searchBugs/checkIfStatusBugExist`;
    return WebClientUtil.postObject(url, body);
  }
}
