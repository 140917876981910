import React from 'react';
import MUIDataTable from 'mui-datatables';
import { Tooltip } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import { serviceFactory } from '../../common/config/ServiceFactory';
import AlertDialog from '../../common/component/Dialog/AlertDialog';
import ResultInfoDialog from '../../common/component/Dialog/ResultInfoDialog';
import { ToolService } from '../../common/service/tool/ToolService';
export class FunctionTool extends React.Component<any, any> {
  private toolService = serviceFactory.getToolService();
  constructor(props) {
    super(props);
    this.state = {
      tools: this.props.tools,
      openConfirmDeleteTool: false,
      openResultDialog: false,
      resultInfo: '',
    };
  }
  // private toolService: ToolService;
  private idtool = undefined;
  componentWillReceiveProps(nextProps) {
    this.setState({
      tools: nextProps.tools,
    });
  }
  navigateToUpdate = (idtool) => {
    this.props.history.push(`/tool/update/${idtool}`);
  };
  handleDeleteTool = (idtool) => {
    this.idtool = idtool;
    this.setState({
      openConfirmDeleteTool: true,
    });
  };
  handleCloseConfirm = () => {
    this.setState({
      openConfirmDeleteTool: false,
    });
  };
  handleAcceptDeleleteTool = () => {
    this.setState(
      {
        openConfirmDeleteTool: false,
      },
      () => {
        this.toolService.deleteTool(this.idtool).subscribe((res) => {
          if (res.response && res.response.data.error) {
            this.setState({ error: res.response.data.error });
            console.error(res.response.data.error);
            // window.alert(res.response.data.error);
            this.setState({
              openResultDialog: true,
              isDeleteToolSuccess: false,
              resultInfo: `Can not delete this tool:\n${res.response.data.error}`,
            });
          } else {
            this.props.onDeleteSuccess();
            this.idtool = undefined;
            this.setState({
              openResultDialog: true,
              isDeleteToolSuccess: true,
              resultInfo: `Delete tool successful`,
            });
          }
        });
      }
    );
  };
  handleCloseResultDialog = () => {
    this.setState({
      openResultDialog: false,
    });
  };
  createData(tools: any[]) {
    const displayData = [];
    tools.forEach((tool) => {
      displayData.push([
        tool._id,
        tool.toolName,
        tool.groupId.groupName,
        null,
        null,
      ]);
    });
    return displayData;
  }
  render() {
    const columns = [
      {
        name: 'idtool',
        options: {
          display: 'false',
          filter: false,
          download: false,
        },
      },
      'Tool Name',
      'Group Name',
      {
        name: 'Edit',
        options: {
          filter: false,
          sort: false,
          download: false,
          customBodyRender: (value, tableMeta) => {
            const idtool = tableMeta.rowData && tableMeta.rowData[0];
            return (
              <div className='btn-group'>
                <Tooltip title={'Edit'}>
                  <IconButton onClick={() => this.navigateToUpdate(idtool)}>
                    <EditIcon style={{ color: '#fb8c00' }} />
                  </IconButton>
                </Tooltip>
              </div>
            );
          },
        },
      },
      {
        name: 'Delete',
        options: {
          filter: false,
          sort: false,
          download: false,
          customBodyRender: (value, tableMeta) => {
            const idtool = tableMeta.rowData && tableMeta.rowData[0];
            return (
              <div className='btn-group'>
                <Tooltip title={'Delete'}>
                  <IconButton onClick={() => this.handleDeleteTool(idtool)}>
                    <DeleteIcon style={{ color: '#d93025' }} />
                  </IconButton>
                </Tooltip>
              </div>
            );
          },
        },
      },
    ];

    const options = {
      filterType: 'dropdown',
      responsive: 'scrollMaxHeight',
      disableToolbarSelect: true,
      selectableRowsHeader: false,
      selectableRows: 'none',
      viewColumns: false, // close viewcolumns
      print: false, // close print
    };

    return (
      <>
        <AlertDialog
          open={this.state.openConfirmDeleteTool}
          onClose={this.handleCloseConfirm}
          title='Do you want to delete this tool?'
          okText='OK'
          cancelText='Cancel'
          onAccept={this.handleAcceptDeleleteTool}
          onCancel={this.handleCloseConfirm}
        />
        <ResultInfoDialog
          open={this.state.openResultDialog}
          onClose={this.handleCloseResultDialog}
          resultInfo={this.state.resultInfo}
          okText='OK'
          onAccept={this.handleCloseResultDialog}
          isOpSuccess={this.state.isDeleteToolSuccess}
        />
        <MUIDataTable
          title={'Tools'}
          data={this.createData(this.state.tools)}
          columns={columns}
          options={options}
        />
      </>
    );
  }
}
