import { catchError } from 'rxjs/operators';
import { WebClientUtil } from './../../util/WebClientUtil';
import { API_ENDPOINT } from './../../../../config';
import { GroupModel } from './../../model/GroupSearchModel';
import { Observable, of } from 'rxjs';
import { GroupService } from './GroupService';
export class GroupServiceImpl implements GroupService {
  private baseUrl = API_ENDPOINT.versionTrackingURL;
  getAllGroupTool(): Observable<GroupModel> {
    const url = `${this.baseUrl}/group`;
    return WebClientUtil.getObject(url);
  }
  getAllGroupWithTool(): Observable<GroupModel> {
    const url = `${this.baseUrl}/group/tools`;
    return WebClientUtil.getObject(url);
  }

  createGroup(body: any): Observable<any> {
    const url = `${this.baseUrl}/group`;
    return WebClientUtil.postObject(url, body);
  }

  getOneGroupTool(id: string) {
    const url = `${this.baseUrl}/group/getGroup/${id}`;
    return WebClientUtil.getObject(url);
  }
  // deleteOneGroupTool(id: string) {
  //   throw new Error('Method not implemented.');
  // }
  updateOneGroupTool(id: string, newModel: any) {
    const url = `${this.baseUrl}/group/${id}`;
    return WebClientUtil.putObject(url, newModel);
  }
  deleteGroup(id: string): Observable<any> {
    const url = `${this.baseUrl}/group/${id}`;
    return WebClientUtil.deleteObject(url);
  }
  createOrGetExistingGroup(body: any): Observable<any> {
    const url = `${this.baseUrl}/group/createOrGetExistingGroup`;
    return WebClientUtil.postObject(url, body);
  }
  searchGroup(params: any): Observable<any> {
    let url = `${this.baseUrl}/group/searchGroup`;
    if (params.groupName) {
      url += '?groupName=' + params.groupName;
    }
    return WebClientUtil.getObject(url);
  }
  checkIfGroupExistAndHasTool(body: any): Observable<any> {
    const url = `${this.baseUrl}/group/checkgroupexist`;
    return WebClientUtil.postObject(url, body);
  }
}
