import React, { FunctionComponent } from 'react';
import { Route } from 'react-router-dom';
import PropTypes from 'prop-types';

const Wrapper: FunctionComponent<any> = (props) => {
  const { layout: Layout, component: Component, ...rest } = props;

  return (
    <Route
      {...rest}
      render={(matchProps) => (
        <Layout>
          <Component {...matchProps} />
        </Layout>
      )}
    />
  );
};

Wrapper.propTypes = {
  component: PropTypes.any.isRequired,
  layout: PropTypes.any.isRequired,
  path: PropTypes.string,
};

export default Wrapper;
