import React from 'react';
import MUIDataTable from 'mui-datatables';
import { Link } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import { connect } from 'react-redux';
import { TableCell, CircularProgress } from '@material-ui/core';
import { serviceFactory } from '../../common/config/ServiceFactory';
import { BugsTrackingService } from '../../common/service/bugsTracking/BugsTrackingService';
import EditIcon from '@material-ui/icons/Edit';
import DelIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import AlertDialog from '../../common/component/Dialog/AlertDialog';
import ResultInfoDialog from '../../common/component/Dialog/ResultInfoDialog';
import * as toolActions from '../../../redux/actions/toolActions';
import GetAppIcon from '@material-ui/icons/GetApp';
import { Button } from '@material-ui/core';
import readXlsxFile from 'read-excel-file';
import { sanitizeASCIIString } from '../../../utils/StringUtils';
import DialogMessage from '../../common/component/Dialog/DialogMessage';
class BugTracking extends React.Component<any, any> {
  private toolService = serviceFactory.getToolService();
  private makeService = serviceFactory.getMakeService();
  private statusBugService = serviceFactory.getStatusBugService();
  constructor(props) {
    super(props);
    this.bugsTrackingService = serviceFactory.getBugsTrackingService();
    this.state = {
      bugs: [],
      openConfirmDeleteBug: false,
      openResultDialog: false,
      resultInfo: '',
      isLoading: true, // bat chuoc lam tuong tu cho cac bang import khac
      errorMsg: '',
    };
  }
  private bugsTrackingService: BugsTrackingService;
  private idTool = undefined;
  private idBug = undefined;
  private arrayIdBug: string[] = undefined;
  private excelData: any[];
  async componentDidMount() {
    const toolId = this.props.match.params.id;
    const toolRes = await this.toolService.getOneTool(toolId).toPromise();
    if (toolRes.response && toolRes.response.data.error) {
      // error checking
      alert(toolRes.response.data.error);
    } else {
      this.props.dispatch(
        toolActions.selectTool({
          ...toolRes.data.data,
          title: toolRes.data.data.toolName,
        })
      );
    }
    this.bugsTrackingService.getallbug(toolId).subscribe((res1) => {
      this.setState({
        bugs: res1.data.data,
        isLoading: false, // chu y
      });
    });
  }
  handleEditBug(idTool, idBug: any): void {
    const { history } = this.props;
    history.push(
      `/bug-tracking/edit-bug-tracking/tool/${idTool}/bugs/${idBug}`
    );
  }
  handleDeletetBug(idTool, arrayIdBug: any): void {
    this.arrayIdBug = arrayIdBug;
    this.setState({
      openConfirmDeleteBug: true,
    });
  }
  handleCloseConfirm = () => {
    this.setState({
      openConfirmDeleteBug: false,
    });
  };
  handleCloseResultDialog = () => {
    this.setState(
      {
        openResultDialog: false,
      },
      () => {
        window.location.reload();
      }
    );
  };
  handleAcceptDeleleteBug = () => {
    this.setState(
      {
        openConfirmDeleteBug: false,
      },
      () => {
        this.bugsTrackingService
          .deletemanyBugs(this.arrayIdBug)
          .subscribe(async (res) => {
            if (res.response && res.response.data.error) {
              this.setState({ error: res.response.data.error });
              console.error(res.response.data.error);
              // window.alert(res.response.data.error);
              this.setState({
                openResultDialog: true,
                isDeleteBugSuccess: false,
                resultInfo: `Can not delete this bug:\n${res.response.data.error}`,
              });
            } else {
              // this.props.onDeleteSuccess();
              this.arrayIdBug = undefined;
              this.setState({
                openResultDialog: true,
                isDeleteBugSuccess: true,
                resultInfo: `Delete bug successful`,
              });
              //await this.componentDidMount();
            }
          });
      }
    );
  };
  handleReadExcelFile = async (e) => {
    let errorMsg = '';
    e.stopPropagation();
    const input = e.target;
    if (!input) {
      return;
    }
    if (input.files && input.files.length > 0) {
      this.setState({
        isLoading: true, // chu y
      });
      const rows = await readXlsxFile(input.files[0]);
      this.excelData = rows; // luu du lieu file excel
      this.excelData.splice(0, 1);
      for (const rowitem of this.excelData) {
        // chu y quan trong: De try catch trong vong for vi se skip
        // cac dong bi loi ma van tiep tuc import cac dong khac,
        // con de ngoai vong for thi chi can 1 dong bi loi,
        // nhung dong phia sau se khong duoc chay: sua lai C lam tuong tu co cac bang import khac
        try {
          const bugsModel = {
            toolName:
              typeof rowitem[0] == 'string'
                ? sanitizeASCIIString(rowitem[0])
                : rowitem[0] || 'N/A',
            fw:
              typeof rowitem[1] == 'string'
                ? sanitizeASCIIString(rowitem[1])
                : rowitem[1] || 'N/A',
            makeId:
              typeof rowitem[2] == 'string'
                ? sanitizeASCIIString(rowitem[2])
                : rowitem[2] || 'N/A', // nameMake
            function: rowitem[3] || 'N/A', // add cot function
            description: rowitem[4] || 'N/A',
            link_redmine: rowitem[5] || 'N/A',
            status:
              typeof rowitem[6] == 'string'
                ? sanitizeASCIIString(rowitem[6])
                : rowitem[6] || 'N/A', // status
          };
          //B1: Kiểm tra toolName trong excel có tồn tại trong collection Tool hay không (tool.controllers -> checkIfToolNameExistInTool)
          //B2: Kiểm tra FW trong excel của toolName đó có nằm trong toolName đó không (tool.controllers -> checkIfFWExistInTool)
          //B3: Kiểm tra Make trong excel có tồn tại trong collection Make hay không (make.controllers -> checkIfMakeExist)
          //B4: Kiểm tra statusBug trong excel có tồn tại trong collection statusBug hay không (statusBug.controllers -> checkIfStatusBugExist)
          const tool = await this.toolService
            .checkIfFWExistInTool({
              toolName: sanitizeASCIIString(bugsModel.toolName),
              fw: sanitizeASCIIString(bugsModel.fw),
            })
            .toPromise();
          if (tool.response && tool.response.data.error) {
            // error checking
            throw tool.response.data.error;
          }
          const make = await this.makeService
            .checkIfMakeExist({
              nameMake: sanitizeASCIIString(bugsModel.makeId),
            })
            .toPromise();
          if (make.response && make.response.data.error) {
            // error checking
            throw make.response.data.error;
          }
          // const statusBug = await this.statusBugService
          //   .checkIfStatusBugExist({
          //     name: sanitizeASCIIString(bugsModel.statusBugId),
          //   })
          //   .toPromise();
          // if (statusBug.response && statusBug.response.data.error) {
          //   // error checking
          //   throw statusBug.response.data.error;
          // }
          const toolId = tool.data.data._id;
          const buildBody = {
            fw: bugsModel.fw,
            makeId: make.data.data._id,
            function: bugsModel.function, // add cot function
            description: bugsModel.description,
            link_redmine: bugsModel.link_redmine,
            status: bugsModel.status,
          };
          const newBugs = await this.bugsTrackingService
            .createBugsOnTool(toolId, buildBody)
            .toPromise();
          if (newBugs.response && newBugs.response.data.error) {
            // error checking
            throw newBugs.response.data.error;
          }
        } catch (err) {
          this.excelData = undefined;
          errorMsg += '\n' + err;
          //alert(err);
        } finally {
          input.value = '';
        }
      }
      if (errorMsg) {
        this.setState({ errorMsg });
      }
      this.setState({
        isLoading: false, // chu, y
      });
      await this.componentDidMount(); // de o ngoai
    }
  };
  render() {
    const columns = [
      {
        name: 'ID Bugs',
        options: {
          display: 'false',
          filter: false,
          download: false,
          //viewColumns: false,
        },
      },
      {
        name: 'ID Tool',
        options: {
          display: 'false',
          filter: false,
          download: false,
          //viewColumns: false,
        },
      },
      'FW',
      'Make',
      //Add cot Function
      {
        name: 'Function',
        options: {
          filter: false,
        },
      },
      {
        name: 'Description',
        options: {
          filter: false,
        },
      },
      {
        name: 'Redmine',
        options: {
          filter: false,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <td>
                <a
                  style={{ color: 'blue', textDecoration: 'underline' }}
                  title='Redmine Issue'
                  href={tableMeta.rowData[6]}
                  target='_blank'
                >
                  {tableMeta.rowData[6]}
                </a>
              </td>
            );
          },
        },
      },
      'ToDo',
      {
        name: 'Tool',
        label: 'Tool',
        options: {
          display: 'false',
          filter: false,
        },
      },
    ];
    const MapDataToDataTable = (data1) => {
      //console.log('data1', data1);
      return data1.map((item) => {
        //console.log('item', item.toolId._id);
        return [
          item._id,
          item.toolId._id, // update code backend nen sửa item.toolId= item.toolId._id
          item.fw,
          item.make.nameMake,
          item.function,
          item.description,
          item.link_redmine,
          item.statusBug.value,
          item.toolId.toolName, // update code backend nen thêm
        ];
      });
    };

    const options = {
      onDownload: (buildHead, buildBody, columns, data) => {
        function escapeDangerousCSVCharacters(data) {
          if (typeof data === 'string') {
            return data.replace(/^\+|^\-|^\=|^\@/g, "'$&");
          }
          return data;
        }
        const replaceDoubleQuoteInString = (columnData) =>
          typeof columnData === 'string'
            ? columnData.replace(/\"/g, '""')
            : columnData;
        const buildBody1 = (data) => {
          if (!data.length) return '';
          return data
            .reduce(
              (soFar, row) =>
                soFar +
                '"' +
                row.data
                  .map((columnData) =>
                    escapeDangerousCSVCharacters(
                      replaceDoubleQuoteInString(columnData)
                    )
                  )
                  .join('"' + ',' + '"') +
                '"\r\n',
              ''
            )
            .trim();
        };
        // unshift toolName column (last column) for columns
        const _columns = JSON.parse(JSON.stringify(columns));
        const _data = JSON.parse(JSON.stringify(data));
        const lengthColumns = _columns.length;
        const ToolCol = _columns[lengthColumns - 1];
        _columns.length = lengthColumns - 1;
        _columns.unshift(ToolCol);
        _columns[0]['label'] = 'Tool'; // set custom second header name for group column
        // unshift toolName column (last column) for data
        _columns.splice(1, 2); // remove column index 1, 2
        _data.map((item) => {
          const lengthData = item.data.length;
          const dataToolCol = item.data[lengthData - 1];
          item.data.length = lengthData - 1;
          item.data.unshift(dataToolCol);
          item.data.splice(1, 2); // remove column data index 1, 2
          return item;
        });
        return '\uFEFF' + buildHead(_columns) + buildBody1(_data);
      },
      filterType: 'dropdown',
      responsive: 'scrollMaxHeight',
      viewColumns: false, // close viewcolumns
      print: false, // close print
      //Mấy ô tick dòng ngoài hàng đầu
      selectableRows: (() => {
        const data = localStorage.getItem('userInfo');
        const userInfo = JSON.parse(data);
        let htmlContent;
        if (userInfo?.role === 'QA_Role' || userInfo?.role === 'Manager_Role') {
          htmlContent = 'none';
        } else {
          htmlContent = 'multiple'; // remove ToolBar
        }

        return htmlContent;
      })(),
      disableToolbarSelect: (() => {
        const data = localStorage.getItem('userInfo');
        const userInfo = JSON.parse(data);
        let htmlContent;
        if (userInfo?.role === 'QA_Role' || userInfo?.role === 'Manager_Role') {
          htmlContent = true;
        } else {
          htmlContent = false; // remove ToolBar
        }

        return htmlContent;
      })(),
      customToolbarSelect: (selectedRows, displayData, setSelectedRows) => {
        const currentIndex = selectedRows.data[0].index;
        const idBug = displayData[currentIndex].data[0];
        const idTool = displayData[currentIndex].data[1];
        const currentIndexArr = selectedRows.data.map((item) => item.index);
        const idBugArr = currentIndexArr.map(
          (index) => displayData[index].data[0]
        );
        const data = localStorage.getItem('userInfo');
        const userInfo = JSON.parse(data);
        return (
          <div className='btn-group'>
            {userInfo?.role !== 'Manager_Role' && userInfo?.role !== 'QA_Role' && (
              <Tooltip title={'Edit'}>
                <IconButton onClick={() => this.handleEditBug(idTool, idBug)}>
                  <EditIcon style={{ color: '#fb8c00' }} />
                </IconButton>
              </Tooltip>
            )}
            {userInfo?.role !== 'Manager_Role' && userInfo?.role !== 'QA_Role' && (
              <Tooltip title={'Delete'}>
                <IconButton
                  onClick={() => this.handleDeletetBug(idTool, idBugArr)}
                >
                  <DelIcon style={{ color: '#d93025' }} />
                </IconButton>
              </Tooltip>
            )}
          </div>
        );
      },
      customToolbar: () => {
        const data = localStorage.getItem('userInfo');
        const userInfo = JSON.parse(data);
        const htmlContent =
          (userInfo?.role !== 'QA_Role' &&
            userInfo?.role !== 'Manager_Role' && (
              <>
                <input
                  accept='application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel'
                  id='importReleaseInput'
                  multiple
                  type='file'
                  title={'Import excel'}
                  onChange={(e) => this.handleReadExcelFile(e)}
                  style={{ display: 'none' }}
                />
                <label htmlFor='importReleaseInput'>
                  <Button component={'span'}>
                    <GetAppIcon style={{ transform: 'rotate(-180deg)' }} />
                  </Button>
                </label>
              </>
            )) ||
          null;
        return htmlContent;
      },
      selectableRowsHeader: true,
    };
    return (
      <React.Fragment>
        <AlertDialog
          open={this.state.openConfirmDeleteBug}
          onClose={this.handleCloseConfirm}
          title='Do you want to delete this bugs?'
          okText='OK'
          cancelText='Cancel'
          onAccept={this.handleAcceptDeleleteBug}
          onCancel={this.handleCloseConfirm}
        />
        <ResultInfoDialog
          open={this.state.openResultDialog}
          onClose={this.handleCloseResultDialog}
          resultInfo={this.state.resultInfo}
          okText='OK'
          onAccept={this.handleCloseResultDialog}
          isOpSuccess={this.state.isDeleteBugSuccess}
        />
        <DialogMessage
          open={!!this.state.errorMsg}
          title='Error'
          content={this.state.errorMsg}
        />
        <MUIDataTable
          title={
            <Typography>
              {' '}
              {/* Chu y: */}
              {(this.props.tool && this.props.tool.title) ||
                '' + ' - Bug Tracking'}
              {this.state.isLoading && (
                <CircularProgress
                  size={24}
                  style={{ marginLeft: 15, position: 'relative', top: 4 }}
                />
              )}
            </Typography>
          }
          data={MapDataToDataTable(this.state.bugs)}
          columns={columns}
          options={options}
        />
      </React.Fragment>
    );
  }
}
const mapStateToProps = (state, ownProps) => {
  // state is from store
  return {
    tool: state.tool.currentTool,
  };
};
export default connect(mapStateToProps)(BugTracking);
