import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import './SignUp.scss';
import { serviceFactory } from '../../common/config/ServiceFactory';
import { Link } from 'react-router-dom';
class ResetPass extends React.Component<any, any> {
  private readonly userService = serviceFactory.getUserService();
  constructor(props) {
    super(props);
    this.state = {
      password_repeat: '',
      password: '',
      token:
        (this.props.match &&
          this.props.match.params &&
          this.props.match.params.token) ||
        '',
      loading: false,
      isError: false,
      errorMsg: '',
      isSuccess: false,
    };
  }
  componentDidMount() {
    // check if valid token first on first, if not valid then redirect to 404 or error page
  }

  onSubmit = (e) => {
    e.preventDefault();
    if (!this.state.password) {
      this.setState({
        isError: true,
        errorMsg: 'Please enter your new password',
      });
      return;
    }
    if (!this.state.password_repeat) {
      this.setState({
        isError: true,
        errorMsg: 'Please enter your confirm new password',
      });
      return;
    }
    if (this.state.password !== this.state.password_repeat) {
      this.setState({
        isError: true,
        errorMsg: 'Passwords are different, please check again.',
      });
      return;
    }
    const body = {
      newPassword: this.state.password_repeat,
    };
    // call updatepassword api here
    this.userService.updatepassword(this.state.token, body).subscribe((res) => {
      if (res.response && res.response.data.error) {
        this.setState({
          password_repeat: '',
          password: '',
          isError: true,
          isSuccess: false,
          loading: false,
          errorMsg: res.response.data.error,
        });
      } else {
        this.setState({
          password_repeat: '',
          password: '',
          loading: false,
          isSuccess: true,
          isError: false,
          errorMsg: 'You have successfully changed your password!',
        });
      }
    });
  };
  onChange = (evt) => {
    const value = evt.target.value;
    this.setState({
      ...this.state,
      [evt.target.name]: value,
    });
  };
  render = () => {
    return (
      <Container component='main' maxWidth='xs'>
        <div className='paper'>
          <Avatar className='avatar'>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component='h1' variant='h5'>
            Reset Password
          </Typography>
          <form noValidate className='form' onSubmit={this.onSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  variant='outlined'
                  required
                  fullWidth
                  name='password'
                  label='New Password'
                  type='password'
                  id='password'
                  value={this.state.password}
                  onChange={this.onChange}
                  autoComplete='current-password'
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  variant='outlined'
                  required
                  fullWidth
                  name='password_repeat'
                  label='Confirm Password'
                  type='password'
                  id='password_repeat'
                  value={this.state.password_repeat}
                  onChange={this.onChange}
                  autoComplete='current-password'
                />
              </Grid>
            </Grid>
            {this.state.isError && (
              <p
                style={{
                  fontSize: 14,
                  color: 'red',
                  paddingLeft: 30,
                  paddingTop: 20,
                }}
              >
                {this.state.errorMsg}
              </p>
            )}
            {this.state.isSuccess && (
              <p
                style={{
                  fontSize: 14,
                  color: '#039be5',
                  paddingLeft: 30,
                  paddingTop: 20,
                }}
              >
                {`${this.state.errorMsg}
                Click `}
                <Link to='/login' variant='body2' color='textSecondary'>
                  here
                </Link>{' '}
                to login
              </p>
            )}
            <Button
              type='submit'
              fullWidth
              variant='contained'
              color='secondary'
              className='submitUp'
            >
              Update Password
            </Button>
            <Grid container justify='flex-end'>
              <Grid item>
                <Link to='/' variant='body2' color='textSecondary'>
                  Already have an account? Sign in
                </Link>
              </Grid>
            </Grid>
          </form>
        </div>
      </Container>
    );
  };
}
export default ResetPass;
