import React from 'react';
import MUIDataTable from 'mui-datatables';
import { Tooltip } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import { serviceFactory } from '../../common/config/ServiceFactory';
import { GroupService } from '../../common/service/group/GroupService';
import AlertDialog from '../../common/component/Dialog/AlertDialog';
import ResultInfoDialog from '../../common/component/Dialog/ResultInfoDialog';
export class FunctionGroup extends React.Component<any, any> {
  constructor(props) {
    super(props);
    this.groupService = serviceFactory.getGroupService();
    this.state = {
      groups: this.props.groups,
      openConfirmDeleteGroup: false,
      openResultDialog: false,
      resultInfo: '',
    };
  }
  private groupService: GroupService;
  private id = undefined;
  // state = {};
  componentWillReceiveProps(nextProps) {
    this.setState({
      groups: nextProps.groups,
    });
  }

  navigateToUpdate = (id) => {
    this.props.history.push(`/group/update/${id}`);
  };
  handleDeleteGroup = (id) => {
    this.id = id;
    this.setState({
      openConfirmDeleteGroup: true,
    });
  };
  handleCloseConfirm = () => {
    this.setState({
      openConfirmDeleteGroup: false,
    });
  };
  handleAcceptDeleleteGroup = () => {
    this.setState(
      {
        openConfirmDeleteGroup: false,
      },
      () => {
        this.groupService.deleteGroup(this.id).subscribe((res) => {
          if (res.response && res.response.data.error) {
            this.setState({ error: res.response.data.error });
            console.error(res.response.data.error);
            // window.alert(res.response.data.error);
            this.setState({
              openResultDialog: true,
              isDeleteGroupSuccess: false,
              resultInfo: `Can not delete this group:\n${res.response.data.error}`,
            });
          } else {
            this.props.onDeleteSuccess();
            this.id = undefined;
            this.setState({
              openResultDialog: true,
              isDeleteGroupSuccess: true,
              resultInfo: `Delete group successful`,
            });
          }
        });
      }
    );
  };
  handleCloseResultDialog = () => {
    this.setState({
      openResultDialog: false,
    });
  };
  render() {
    const columns = [
      {
        name: 'id',
        options: {
          display: 'false',
          filter: false,
          download: false,
        },
      },
      'Name',
      {
        name: 'Edit',
        options: {
          filter: false,
          sort: false,
          download: false,
          customBodyRender: (value, tableMeta) => {
            const id = tableMeta.rowData && tableMeta.rowData[0];
            return (
              <div className='btn-group'>
                <Tooltip title={'Edit'}>
                  <IconButton onClick={() => this.navigateToUpdate(id)}>
                    <EditIcon style={{ color: '#fb8c00' }} />
                  </IconButton>
                </Tooltip>
              </div>
            );
          },
        },
      },
      {
        name: 'Delete',
        options: {
          filter: false,
          sort: false,
          download: false,
          customBodyRender: (value, tableMeta) => {
            const id = tableMeta.rowData && tableMeta.rowData[0];
            return (
              <div className='btn-group'>
                <Tooltip title={'Delete'}>
                  <IconButton onClick={() => this.handleDeleteGroup(id)}>
                    <DeleteIcon style={{ color: '#d93025' }} />
                  </IconButton>
                </Tooltip>
              </div>
            );
          },
        },
      },
    ];

    const MapDataToDataTable = (data1) => {
      return data1.map((item) => {
        return [item._id, item.groupName, null, null];
      });
    };

    const options = {
      filterType: 'dropdown',
      responsive: 'scrollMaxHeight',
      viewColumns: false, // close viewcolumns
      print: false, // close print
      disableToolbarSelect: true,
      selectableRowsHeader: false,
      selectableRows: 'none',
    };

    return (
      <>
        <AlertDialog
          open={this.state.openConfirmDeleteGroup}
          onClose={this.handleCloseConfirm}
          title='Do you want to delete this group?'
          okText='OK'
          cancelText='Cancel'
          onAccept={this.handleAcceptDeleleteGroup}
          onCancel={this.handleCloseConfirm}
        />
        <ResultInfoDialog
          open={this.state.openResultDialog}
          onClose={this.handleCloseResultDialog}
          resultInfo={this.state.resultInfo}
          okText='OK'
          onAccept={this.handleCloseResultDialog}
          isOpSuccess={this.state.isDeleteGroupSuccess}
        />
        <MUIDataTable
          title={'Groups'}
          data={MapDataToDataTable(this.state.groups)}
          columns={columns}
          options={options}
        />
      </>
    );
  }
}
