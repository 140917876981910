import React from 'react';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { Grid, Paper } from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';
import { FunctionRole } from './functionRole';
import { serviceFactory } from '../../common/config/ServiceFactory';
import { connect } from 'react-redux';
import * as toolActions from '../../../redux/actions/toolActions';
import * as groupActions from '../../../redux/actions/groupActions';
import { sanitizeASCIIString } from '../../../utils/StringUtils';
class NewRole extends React.Component<any, any> {
  private readonly roleService = serviceFactory.getRoleService();
  constructor(props) {
    super(props);
    this.state = {
      roles: [],
      nameRole: '',
    };
  }
  componentDidMount() {
    this.props.dispatch(toolActions.selectTool(null));
    this.props.dispatch(groupActions.selectGroup(null));
    this.roleService.GetallRole().subscribe((res) => {
      this.setState({ roles: res.data.data });
    });
  }
  onChange = (e) => {
    this.setState({ nameRole: e.target.value });
  };
  handleDeleteRoleSuccess = () => {
    this.componentDidMount();
  };
  onSubmit = (e) => {
    e.preventDefault();
    const body = {
      nameRole: sanitizeASCIIString(this.state.nameRole),
    };
    this.roleService.createRole(body).subscribe((res) => {
      this.setState({ nameRole: '' });
      this.roleService.GetallRole().subscribe((res) => {
        this.setState({ roles: res.data.data });
      });
    });
    // cancel
    this.onClear();
  };

  onClear = () => {
    this.setState({ nameRole: '' });
  };
  render = () => {
    return (
      <React.Fragment>
        <form noValidate autoComplete='off' onSubmit={this.onSubmit}>
          <div className='custom-buttoncreate'>
            <Typography variant='h6' component='h3'>
              New Role
            </Typography>
            <Grid container spacing={4} alignItems='center'>
              <Grid item xs={6} justify='flex-end'>
                <TextField
                  required
                  id='outlined-required'
                  label='Role name'
                  margin='normal'
                  variant='outlined'
                  name='nameRole'
                  value={this.state.nameRole}
                  onChange={this.onChange}
                  fullWidth
                  size='small'
                  style={{ marginTop: 16, marginBottom: 16 }}
                  placeholder='Please enter Role name'
                />
              </Grid>
              <Grid item xs={6}>
                <Button
                  variant='contained'
                  color='secondary'
                  size='medium'
                  type='submit'
                  startIcon={<SaveIcon />}
                >
                  Add
                </Button>
              </Grid>
            </Grid>
          </div>
        </form>

        <FunctionRole
          {...this.props}
          roles={this.state.roles}
          onDeleteSuccess={this.handleDeleteRoleSuccess}
        />
      </React.Fragment>
    );
  };
}
export default connect()(NewRole);
