import React from 'react';
import { Switch } from 'react-router-dom';
import Wrapper from './components/wrapper/Wrapper';
import FullPageLayout from './components/layouts/FullPageLayout';
import Login from './components/views/Authentication/Login';
import MainLayout from './components/layouts/MainLayout';
import NewTool from './components/views/NewTool/NewTool';
import ReleaseScheduleComponent from './components/views/ReleaseSchedule/ReleaseSchedule';
import ReleaseProductionComponent from './components/views/ReleaseToolProduction/ReleaseProduction';
import InputVersionTracking from './components/views/VersionTracking/InputVersionTracking';
import InputSchedule from './components/views/ReleaseSchedule/InputSchedule';
import Documents from './components/views/Documents/Documents';
import NewGroup from './components/views/NewGroup/NewGroup';
import EditGroup from './components/views/NewGroup/EditGroup';
import EditTool from './components/views/NewTool/EditTool';
import GroupVersionTrackingComponent from './components/views/VersionTracking/GroupVersionTracking';
import EditVersionTracking from './components/views/VersionTracking/EditVersionTracking';
import BugTracking from './components/views/Bug/BugTracking';
import InputBugTracking from './components/views/Bug/InputBugTracking';
import EditInputSchedule from './components/views/ReleaseSchedule/EditInputSchedule';
import EditBugTracking from './components/views/Bug/EditBugTracking';
import SignUp from './components/views/Authentication/SignUp';
import ForgotPass from './components/views/Authentication/ForgotPass';
import ResetPass from './components/views/Authentication/ResetPass';
import NewRole from './components/views/Role/NewRole';
import AuthRole from './components/views/Role/AuthRole';
import NewGroupUser from './components/views/GroupUser/NewGroupUser';
import EditGroupUser from './components/views/GroupUser/EditGroupUser';
import EditRole from './components/views/Role/EditRole';
import NewUseronGroup from './components/views/User/NewUseronGroup';
import FunctionToolComp from './components/views/ToolComponent/ToolComp';
import InputToolComp from './components/views/ToolComponent/InputToolComp';
import EditToolComp from './components/views/ToolComponent/EditToolComp';
import FunctionMasterComp from './components/views/MasterComponent/MasterComponent';

const AppRoutes = () => {
  return (
    <Switch>
      <Wrapper component={Login} exact layout={FullPageLayout} path='/' />
      <Wrapper component={Login} layout={FullPageLayout} path='/login' />
      <Wrapper component={SignUp} layout={FullPageLayout} path='/signup' />
      <Wrapper
        component={ForgotPass}
        layout={FullPageLayout}
        path='/forgotpass'
      />
      <Wrapper
        component={ResetPass}
        layout={FullPageLayout}
        path='/resetpass/:token'
      />
      <Wrapper
        path='/tool/add-newtool'
        component={NewTool}
        layout={MainLayout}
      />
      <Wrapper
        path='/componentTool'
        component={FunctionToolComp}
        layout={MainLayout}
        exact
      />
      <Wrapper
        path='/componentTool/input-toolcomponent'
        component={InputToolComp}
        layout={MainLayout}
      />
      <Wrapper
        path='/componentTool/update-toolComponent/:id'
        component={EditToolComp}
        layout={MainLayout}
      />
      <Wrapper
        path='/mastercomponent'
        component={FunctionMasterComp}
        layout={MainLayout}
        exact
      />
      <Wrapper
        path='/tool/update/:id'
        component={EditTool}
        layout={MainLayout}
      />
      <Wrapper
        path='/group/add-newgroup'
        component={NewGroup}
        layout={MainLayout}
      />
      <Wrapper
        path='/group/update/:id'
        component={EditGroup}
        layout={MainLayout}
      />
      <Wrapper
        path='/role/add-newrole'
        component={NewRole}
        layout={MainLayout}
      />
      <Wrapper path='/role/auth/:id' component={AuthRole} layout={MainLayout} />
      <Wrapper
        path='/role/updateRoleName/:id'
        component={EditRole}
        layout={MainLayout}
      />
      <Wrapper
        path='/groupUser/add-newgroupuser'
        component={NewGroupUser}
        layout={MainLayout}
      />
      <Wrapper
        path='/groupUser/update/:id'
        component={EditGroupUser}
        layout={MainLayout}
      />
      <Wrapper
        path='/user/addonGroupUser'
        component={NewUseronGroup}
        layout={MainLayout}
      />
      {/* <Wrapper
        path='/release-schedule'
        component={ReleaseScheduleComponent}
        layout={MainLayout}
        exact
      /> */}
      <Wrapper
        path='/release-schedule'
        component={ReleaseProductionComponent}
        layout={MainLayout}
        exact
      />
      <Wrapper
        path='/release-schedule/input-schedule'
        component={InputSchedule}
        layout={MainLayout}
      />
      <Wrapper
        path='/update-schedule/:id'
        component={EditInputSchedule}
        layout={MainLayout}
      />
      <Wrapper
        path='/input-version-tracking/tool/:id'
        component={InputVersionTracking}
        layout={MainLayout}
      />
      <Wrapper
        path='/bug-tracking/tool/:id'
        component={BugTracking}
        layout={MainLayout}
      />
      <Wrapper
        path='/bug-tracking/edit-bug-tracking/tool/:idTool/bugs/:id'
        component={EditBugTracking}
        layout={MainLayout}
      />
      <Wrapper
        path='/input-bugs/tool/:id'
        component={InputBugTracking}
        layout={MainLayout}
      />
      <Wrapper
        path='/documents/tool/:id'
        component={Documents}
        layout={MainLayout}
      />
      <Wrapper
        path='/version-tracking/group/:id'
        component={GroupVersionTrackingComponent}
        layout={MainLayout}
      />
      <Wrapper
        path='/version-tracking/tool/:id'
        component={GroupVersionTrackingComponent}
        layout={MainLayout}
      />
      <Wrapper
        path='/version-tracking/edit-version-tracking/tool/:idTool/version/:id'
        component={EditVersionTracking}
        layout={MainLayout}
      />
    </Switch>
  );
};

export default AppRoutes;
