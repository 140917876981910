import React from 'react';
import MUIDataTable from 'mui-datatables';
import Typography from '@material-ui/core/Typography';
import { serviceFactory } from '../../common/config/ServiceFactory';
import * as toolActions from '../../../redux/actions/toolActions';
import * as groupActions from '../../../redux/actions/groupActions';
import { connect } from 'react-redux';
import { Button, CircularProgress, styled } from '@material-ui/core';
import { ToolService } from '../../common/service/tool/ToolService';
import './releaseTrackingTool.scss';
import { TableRow, TableCell } from '@material-ui/core';
import { NavLink } from 'react-router-dom';
import { Link } from 'react-router-dom';
class ReleaseProductionComponent extends React.Component<any, any> {
  constructor(props) {
    super(props);
    this.toolService = serviceFactory.getToolService();
    this.state = {
      versionProduction: [],
      isLoading: true,
    };
  }
  private toolService: ToolService;
  async componentDidMount() {
    this.props.dispatch(toolActions.selectTool(null));
    this.props.dispatch(groupActions.selectGroup(null));
    this.toolService.getToolProductionRDVNVersion().subscribe((res) => {
      if (res.response && res.response.status != 200) {
        this.setState({
          isLoading: false,
        });
      } else {
        this.setState({
          versionProduction: res.data.data,
          isLoading: false,
        });
      }
    });
  }
  handleEditRelease(idRelease) {
    const { history } = this.props;
    history.push(`/update-schedule/${idRelease}`);
  }
  render() {
    const columns = [
      {
        name: 'idTool',
        options: {
          display: 'false',
          filter: false,
          download: false,
        },
      },
      'Group Tool',
      'Tool',
      //'FW',
      {
        name: 'FW',
        label: 'FW',
        options: {
          filter: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <TableRow className='custom-body-tablerow'>
                <TableCell className='custom-body-tablecell-RD'>
                  {' '}
                  <Link
                    to={{
                      pathname: `/version-tracking/tool/${tableMeta.rowData[0]}`,
                      state: { FW: tableMeta.rowData[3] },
                    }}
                    color='textSecondary'
                  >
                    {tableMeta.rowData[3]}
                  </Link>
                </TableCell>
              </TableRow>
            );
          },
        },
      },
      //'Status',
      {
        name: 'Status',
        label: 'Status',
        options: {
          filter: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            let mau;
            if (tableMeta.rowData[4] === 'Production version') {
              mau = 'custom-body-tablecell-production';
            } else if (tableMeta.rowData[4] === 'Release to TD') {
              mau = 'custom-body-tablecell-release';
            } else {
              mau = 'custom-body-tablecell-RD';
            }
            return (
              <TableRow className='custom-body-tablerow'>
                <TableCell className={mau}>{tableMeta.rowData[4]}</TableCell>
              </TableRow>
            );
          },
        },
      },
      //'User Interface version',
      {
        name: 'userinterface_version',
        label: 'User Interface version',
        options: {
          filter: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <TableRow className='custom-body-tablerow'>
                <TableCell className='custom-body-tablecell-RD'>
                  {' '}
                  <Link
                    to={{
                      pathname: `/documents/tool/${tableMeta.rowData[0]}`,
                      state: { userinterface_version: tableMeta.rowData[5] },
                    }}
                    color='textSecondary'
                  >
                    {tableMeta.rowData[5]}
                  </Link>
                </TableCell>
              </TableRow>
            );
          },
        },
      }, //'Manual version',
      {
        name: 'manual_version',
        label: 'Manual version',
        options: {
          filter: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <TableRow className='custom-body-tablerow'>
                <TableCell className='custom-body-tablecell-RD'>
                  {' '}
                  <Link
                    to={{
                      pathname: `/documents/tool/${tableMeta.rowData[0]}`,
                      state: { manual_version: tableMeta.rowData[6] },
                    }}
                    color='textSecondary'
                  >
                    {tableMeta.rowData[6]}
                  </Link>
                </TableCell>
              </TableRow>
            );
          },
        },
      },
      'Date',
    ];
    const data = localStorage.getItem('userInfo');
    const userInfo = JSON.parse(data);
    const MapDataToDataTable = (data1) => {
      let dataversion = [];
      data1.forEach((item) => {
        const arrTool = [];
        const arr = [item._id, item.groupId.groupName, item.toolName];
        item.version.forEach((fw) => {
          arrTool.push([
            ...arr,
            fw.fw,
            fw.statusversion.name,
            fw.userinterface_version || '',
            fw.manual_version || '',
            new Date(fw.date).toLocaleDateString('en-US'),
          ]);
        });
        dataversion = dataversion.concat(arrTool);
      });
      return dataversion;
    };

    const options = {
      filterType: 'dropdown',
      responsive: 'scrollMaxHeight',
      viewColumns: false, // close viewcolumns
      print: false, // close print
      selectableRowsHeader: false,
      selectableRows: 'none',
      disableToolbarSelect: (() => {
        let htmlContent;
        if (userInfo?.role === 'Manager_Role') {
          htmlContent = true;
        } else {
          htmlContent = false; // remove ToolBar
        }

        return htmlContent;
      })(),
    };
    return (
      <React.Fragment>
        <MUIDataTable
          className='custom-table-release'
          title={
            <Typography>
              {' '}
              {/* Chu y: */}
              {'Release Tracking'}
              {this.state.isLoading && (
                <CircularProgress
                  size={24}
                  style={{ marginLeft: 15, position: 'relative', top: 4 }}
                />
              )}
            </Typography>
          }
          data={MapDataToDataTable(this.state.versionProduction)}
          columns={columns}
          options={options}
        />
      </React.Fragment>
    );
  }
}
export default connect()(ReleaseProductionComponent);
