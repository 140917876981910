import { catchError } from 'rxjs/operators';
import { WebClientUtil } from '../../util/WebClientUtil';
import { API_ENDPOINT } from '../../../../config';
import { Observable, of } from 'rxjs';
import { StatusReleasesService } from './StatusReleasesService';
export class StatusReleasesServiceImpl implements StatusReleasesService {
  private baseUrl = API_ENDPOINT.versionTrackingURL;
  getallStatusReleases(): Observable<any> {
    const url = `${this.baseUrl}/statusreleases`;
    return WebClientUtil.getObject(url);
  }
  checkIfStatusExist(body: any): Observable<any> {
    const url = `${this.baseUrl}/statusreleases/checkIfStatusExist`;
    return WebClientUtil.postObject(url, body);
  }
}
