import { catchError } from 'rxjs/operators';
import { WebClientUtil } from '../../util/WebClientUtil';
import { API_ENDPOINT } from '../../../../config';
import { Observable, of } from 'rxjs';
import { DocumentService } from './DocumentService';
export class DocumentServiceImpl implements DocumentService {
  private baseUrl = API_ENDPOINT.versionTrackingURL;
  createdocument(body: any): Observable<any> {
    const url = `${this.baseUrl}/documents`;
    return WebClientUtil.postObject(url, body);
  }
  uploadFile(body: any): Observable<any> {
    const url = `${this.baseUrl}/documents/file/upload`;
    return WebClientUtil.postObject(url, body);
  }
  getallDocs(toolId: string): Observable<any> {
    const url = `${this.baseUrl}/documents/${toolId}`;
    return WebClientUtil.getObject(url);
  }
  // getallDocs(): Observable<any> {
  //   const url = `${this.baseUrl}/documents`;
  //   return WebClientUtil.getObject(url);
  // }
  saveAllDocs(body: any): Observable<any> {
    const url = `${this.baseUrl}/documents/saveAllDocs`;
    return WebClientUtil.postObject(url, body);
  }
  deleteDocument(idDocument: string): Observable<any> {
    const url = `${this.baseUrl}/documents/${idDocument}`;
    return WebClientUtil.deleteObject(url);
  }
  removeAllOrphanDocuments(): Observable<any> {
    const url = `${this.baseUrl}/documents/delete/document/removeAllOrphanDocuments`;
    return WebClientUtil.deleteObject(url);
  }
  deleteFileByName(body: any): Observable<any> {
    const url = `${this.baseUrl}/documents/deleteFile/deleteFileByName`;
    return WebClientUtil.postObject(url, body);
  }
  updateStateDocument(idDocument: string, newModel: any) {
    const url = `${this.baseUrl}/documents/update/state/${idDocument}`;
    return WebClientUtil.putObject(url, newModel);
  }
  getOneDocsByToolDocs(idDocument: string) {
    const url = `${this.baseUrl}/documents/getOneDocument/${idDocument}`;
    return WebClientUtil.getObject(url);
  }
  sendMailDoc(body: any): Observable<any>{
    const url = `https://vehs.innovavietnam.com/tool-data/email`;
    return WebClientUtil.postObject(url, body, false);
  }


}
