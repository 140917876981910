import React from 'react';
import MUIDataTable from 'mui-datatables';
import { Tooltip } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import { serviceFactory } from '../../common/config/ServiceFactory';
import AlertDialog from '../../common/component/Dialog/AlertDialog';
import ResultInfoDialog from '../../common/component/Dialog/ResultInfoDialog';
import { UserService } from '../../common/service/user/userService';
export class FunctionUserandGroup extends React.Component<any, any> {
  private userService = serviceFactory.getUserService();
  constructor(props) {
    super(props);
    this.state = {
      usergrouparr: this.props.usergrouparr,
      openConfirmDeleteUser: false,
      openResultDialog: false,
      resultInfo: '',
      openConfirmDeleteUserContinue: false,
      openResultDialogContinue: false,
    };
  }
  //private userService: UserService;
  private id = undefined;
  private email = undefined;
  componentWillReceiveProps(nextProps) {
    this.setState({
      usergrouparr: nextProps.usergrouparr,
    });
  }

  handleDeleteUser = (id) => {
    this.id = id;
    this.setState({
      openConfirmDeleteUser: true,
    });
  };
  handleCloseConfirm = () => {
    this.setState({
      openConfirmDeleteUser: false,
    });
  };
  handleCloseConfirmContinue = () => {
    this.setState({
      openConfirmDeleteUserContinue: false,
    });
  };
  handleAcceptDeleleteUser = () => {
    this.setState(
      {
        openConfirmDeleteUser: false,
      },
      () => {
        this.userService.deleteGroupFromUser(this.id).subscribe((res) => {
          if (res.response && res.response.data.error) {
            this.setState({ error: res.response.data.error });
            // console.error(res.response.data.error);
            // window.alert(res.response.data.error);
            this.id = undefined;
            this.setState({
              // openResultDialog: true, // khong hien thong bao cu
              isDeleteGroupUserSuccess: false,
              resultInfo: `${res.response.data.error}`,
              openConfirmDeleteUserContinue: false, // continue ask to delete user
            });
          } else {
            this.props.onDeleteSuccess();
            this.setState({
              // openResultDialog: true,
              isDeleteGroupUserSuccess: true,
              resultInfo: `Delete user from the group successful`,
              openConfirmDeleteUserContinue: true,
            });
          }
        });
      }
    );
  };
  handleAcceptDeleleteUserContinue = () => {
    this.setState(
      {
        openConfirmDeleteUserContinue: false,
      },
      () => {
        this.userService.deleteUser(this.id).subscribe((res) => {
          if (res && res.status === 200) {
            this.setState({
              isDeleteUserSuccess: true,
              openResultDialogContinue: true,
              resultInfoContinue: 'Delete user success',
            });
          } else {
            this.setState(
              {
                isDeleteUserSuccess: false,
                openResultDialogContinue: true,
                resultInfoContinue: 'Error delete user!',
              },
              () => {}
            );
          }
        });
      }
    );
  };
  handleCloseResultDialog = () => {
    this.setState(
      {
        openResultDialog: false,
      },
      () => {
        window.location.reload();
      }
    );
  };
  handleCloseResultDialogContinue = () => {
    this.setState(
      {
        openResultDialogContinue: false,
      },
      () => {
        window.location.reload();
      }
    );
  };
  render() {
    const columns = [
      {
        name: 'iduser',
        options: {
          display: 'false',
          filter: false,
          download: false,
          //viewColumns: false,
        },
      },
      'Group User',
      'Email',
      {
        name: 'Delete',
        options: {
          filter: false,
          sort: false,
          download: false,
          //viewColumns: false,
          customBodyRender: (value, tableMeta) => {
            const id = tableMeta.rowData && tableMeta.rowData[0];
            return (
              <div className='btn-group'>
                <Tooltip title={'Delete'}>
                  <IconButton onClick={() => this.handleDeleteUser(id)}>
                    <DeleteIcon style={{ color: '#d93025' }} />
                  </IconButton>
                </Tooltip>
              </div>
            );
          },
        },
      },
    ];
    const MapDataToDataTable = (data1) => {
      return data1.map((item) => {
        return [
          item._id,
          item.groupUserId ? item.groupUserId.nameGroup : '-',
          item.email,
          null,
        ];
      });
    };
    const options = {
      filterType: 'dropdown',
      responsive: 'scrollMaxHeight',
      disableToolbarSelect: true,
      selectableRowsHeader: false,
      selectableRows: 'none',
      viewColumns: false, // close viewcolumns
      print: false, // close print
    };
    const data = [['1', 'MX Role', 'Yonkers', null]];
    return (
      <>
        <AlertDialog
          open={this.state.openConfirmDeleteUser}
          onClose={this.handleCloseConfirm}
          title='Do you want to remove this user from this group?'
          okText='OK'
          cancelText='Cancel'
          onAccept={this.handleAcceptDeleleteUser}
          onCancel={this.handleCloseConfirm}
        />
        <ResultInfoDialog
          open={this.state.openResultDialog}
          onClose={this.handleCloseResultDialog}
          resultInfo={this.state.resultInfo}
          okText='OK'
          onAccept={this.handleCloseResultDialog}
          isOpSuccess={this.state.isDeleteGroupUserSuccess}
        />

        <AlertDialog
          open={this.state.openConfirmDeleteUserContinue}
          onClose={this.handleCloseConfirmContinue}
          title='Do you want to delete this user completely?'
          okText='OK'
          cancelText='Cancel'
          onAccept={this.handleAcceptDeleleteUserContinue}
          onCancel={this.handleCloseConfirmContinue}
        />
        <ResultInfoDialog
          open={this.state.openResultDialogContinue}
          onClose={this.handleCloseResultDialogContinue}
          resultInfo={this.state.resultInfoContinue}
          okText='OK'
          onAccept={this.handleCloseResultDialogContinue}
          isOpSuccess={this.state.isDeleteUserSuccess}
        />
        <MUIDataTable
          title={'Manage group and users'}
          data={MapDataToDataTable(this.state.usergrouparr)}
          columns={columns}
          options={options}
        />
      </>
    );
  }
}
