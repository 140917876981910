import React from 'react';
import { AppBar, Toolbar, Typography, Paper } from '@material-ui/core';
import HorizontalNav from '../common/component/HorizontalNavbar';
import Grid from '@material-ui/core/Grid';
import MenuVertical from '../common/component/MenuVertical';
import { Switch, Route, withRouter } from 'react-router-dom';
import IconButton from '@material-ui/core/IconButton';
import AccountCircle from '@material-ui/icons/AccountCircle';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import './MainLayout.css';
import ChangePassword from '../views/Authentication/ChangePassword';
class MainLayout extends React.Component<any, any> {
  constructor(props) {
    super(props);
    this.state = {
      anchorEl: null,
    };
  }
  handleMenu = (event) => {
    this.setState({
      anchorEl: event.currentTarget,
    });
  };
  handleClose = () => {
    this.setState({
      anchorEl: null,
    });
  };
  handleLogout = () => {
    localStorage.clear();
    this.props.history.push('/login');
    window.location.reload();
    this.handleClose();
  };
  commonDetailLayout = (children) => {
    return (
      <React.Fragment>
        <Grid item xs={2}>
          {/* Vertical Nav */}
          <MenuVertical {...this.props} />
        </Grid>
        <Grid item xs={10}>
          <main>{children}</main>
        </Grid>
      </React.Fragment>
    );
  };

  render() {
    const { children } = this.props;
    const data = localStorage.getItem('userInfo');
    const userInfo = JSON.parse(data);
    return (
      <React.Fragment>
        <div className={'pageHeader'}>
          <AppBar
            style={{
              height: 45,
              display: 'flex',
              justifyContent: 'center',
              position: 'unset',
            }}
          >
            <Toolbar
              disableGutters
              style={{
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <Typography
                variant='h6'
                component='h2'
                style={{ paddingLeft: 15 }}
              >
                Innova FW & DB Released Management
              </Typography>
              <div>
                <IconButton
                  aria-label='account of current user'
                  aria-controls='menu-appbar'
                  aria-haspopup='true'
                  onClick={this.handleMenu}
                  title='Menu Account'
                  size='medium'
                  style={{ color: 'white' }}
                >
                  <AccountCircle style={{ fontSize: 36 }} />
                </IconButton>
                <Menu
                  title='Menu Account'
                  id='menu-appbar'
                  anchorEl={this.state.anchorEl}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  open={Boolean(this.state.anchorEl)}
                  onClose={this.handleClose}
                >
                  <MenuItem onClick={this.handleClose}>
                    Email: {(userInfo && userInfo.email) || ''}
                  </MenuItem>
                  <MenuItem onClick={this.handleClose}>
                    Group User: {(userInfo && userInfo.groupName) || ''}
                  </MenuItem>
                  <MenuItem onClick={this.handleClose} style={{ padding: 0 }}>
                    <ChangePassword />
                  </MenuItem>
                  <MenuItem onClick={this.handleLogout}>Logout</MenuItem>
                </Menu>
              </div>
            </Toolbar>
          </AppBar>

          {/* Horizontal Nav */}
          <HorizontalNav {...this.props} />
        </div>

        <Paper elevation={4} style={{ padding: 16 }}>
          <Grid container spacing={2}>
            <Switch>
              <Route path='/version-tracking/tool'>
                {this.commonDetailLayout(children)}
              </Route>
              <Route path='/version-tracking/edit-version-tracking/tool'>
                {this.commonDetailLayout(children)}
              </Route>
              <Route path='/input-version-tracking'>
                {this.commonDetailLayout(children)}
              </Route>
              <Route path='/bug-tracking'>
                {this.commonDetailLayout(children)}
              </Route>
              <Route path='/input-bugs'>
                {this.commonDetailLayout(children)}
              </Route>
              <Route path='/documents'>
                {this.commonDetailLayout(children)}
              </Route>
              <Route path='*'>
                <Grid item xs={12}>
                  <main>{children}</main>
                </Grid>
              </Route>
            </Switch>
          </Grid>
        </Paper>
      </React.Fragment>
    );
  }
}
export default withRouter(MainLayout);
