import { StatusReleasesServiceImpl } from './../service/statusReleases/StatusReleasesServiceImpl';
import { StatusServiceImpl } from './../service/status/StatusServiceImpl';
import { VersionTrackingService } from './../service/version-tracking/VersionTrackingService';
import { ToolServiceImpl } from './../service/tool/ToolServiceImpl';
import { ToolService } from './../service/tool/ToolService';
import { GroupService } from '../service/group/GroupService';
import { GroupServiceImpl } from '../service/group/GroupServiceImpl';
import { VersionTrackingImpl } from '../service/version-tracking/VersionTrackingServiceImpl';
import { StatusService } from '../service/status/StatusService';
import { ReleasesService } from '../service/releasesSchedule/ReleasesService';
import { ReleasesServiceImpl } from '../service/releasesSchedule/ReleasesServiceImpl';
import { StatusReleasesService } from '../service/statusReleases/StatusReleasesService';
import { StatusBugService } from '../service/Statusbug/StatusBugService';
import { StatusBugServiceImpl } from '../service/Statusbug/StatusBugServiceImpl';
import { MakeService } from '../service/make/MakeService';
import { MakeServiceImpl } from '../service/make//MakeServiceImpl';
import { BugsTrackingService } from '../service/bugsTracking/BugsTrackingService';
import { BugsTrackingServiceImpl } from '../service/bugsTracking/BugsTrackingServiceImpl';
import { CategoryService } from '../service/categoryDocument/CategoryService';
import { CategoryServiceImpl } from '../service/categoryDocument/CategoryServiceImpl';
import { DocumentService } from '../service/documents/DocumentService';
import { DocumentServiceImpl } from '../service/documents/DocumentServiceImpl';
import { UserService } from '../service/user/userService';
import { UserServiceImpl } from '../service/user/userServicelmpl';
import { RoleService } from '../service/role/RoleService';
import { RoleServiceImpl } from '../service/role/RoleServiceImpl';
import { GroupUserService } from '../service/groupUser/GroupUserService';
import { GroupUserServiceImpl } from '../service/groupUser/GroupUserServiceImpl';
import { ToolComponentService } from '../service/toolComponent/ToolComponentService';
import { ToolComponentServiceImpl } from '../service/toolComponent/ToolComponentServiceImpl';
import { MasterComponentService } from '../service/masterComponent/MasterComponentService';
import { MasterComponentServiceImpl } from '../service/masterComponent/MasterComponentServiceImpl';
export class ServiceFactory {
  private groupService: GroupService;
  private toolService: ToolService;
  private vsService: VersionTrackingService;
  private statusVSService: StatusService;
  private releasesService: ReleasesService;
  private statusReleasesService: StatusReleasesService;
  private statusBugService: StatusBugService;
  private makeService: MakeService;
  private bugsTrackingService: BugsTrackingService;
  private categoryService: CategoryService;
  private documentService: DocumentService;
  private userService: UserService;
  private roleService: RoleService;
  private groupUserService: GroupUserService;
  private toolComponentService: ToolComponentService;
  private masterComponentService: MasterComponentService;
  constructor() {
    this.groupService = new GroupServiceImpl();
    this.toolService = new ToolServiceImpl();
    this.vsService = new VersionTrackingImpl();
    this.statusVSService = new StatusServiceImpl();
    this.releasesService = new ReleasesServiceImpl();
    this.statusReleasesService = new StatusReleasesServiceImpl();
    this.statusBugService = new StatusBugServiceImpl();
    this.makeService = new MakeServiceImpl();
    this.bugsTrackingService = new BugsTrackingServiceImpl();
    this.categoryService = new CategoryServiceImpl();
    this.documentService = new DocumentServiceImpl();
    this.userService = new UserServiceImpl();
    this.roleService = new RoleServiceImpl();
    this.groupUserService = new GroupUserServiceImpl();
    this.toolComponentService = new ToolComponentServiceImpl();
    this.masterComponentService = new MasterComponentServiceImpl();
  }

  getMasterComponentService() {
    if (this.masterComponentService) {
      return this.masterComponentService;
    } else {
      return new MasterComponentServiceImpl();
    }
  }

  getToolComponentService() {
    if (this.toolComponentService) {
      return this.toolComponentService;
    } else {
      return new ToolComponentServiceImpl();
    }
  }

  getGroupService() {
    if (this.groupService) {
      return this.groupService;
    } else {
      return new GroupServiceImpl();
    }
  }

  getToolService() {
    if (this.toolService) {
      return this.toolService;
    } else {
      return new ToolServiceImpl();
    }
  }

  getVSTrackingService() {
    if (this.vsService) {
      return this.vsService;
    } else {
      return new VersionTrackingImpl();
    }
  }

  getStatusVSTrackingService() {
    if (this.statusVSService) {
      return this.statusVSService;
    } else {
      return new StatusServiceImpl();
    }
  }
  getReleasesService() {
    if (this.releasesService) {
      return this.releasesService;
    } else {
      return new ReleasesServiceImpl();
    }
  }
  getStatusReleasesService() {
    if (this.statusReleasesService) {
      return this.statusReleasesService;
    } else {
      return new StatusReleasesServiceImpl();
    }
  }
  getStatusBugService() {
    if (this.statusBugService) {
      return this.statusBugService;
    } else {
      return new StatusBugServiceImpl();
    }
  }
  getMakeService() {
    if (this.makeService) {
      return this.makeService;
    } else {
      return new MakeServiceImpl();
    }
  }
  getBugsTrackingService() {
    if (this.bugsTrackingService) {
      return this.bugsTrackingService;
    } else {
      return new BugsTrackingServiceImpl();
    }
  }
  getCategorygService() {
    if (this.categoryService) {
      return this.categoryService;
    } else {
      return new CategoryServiceImpl();
    }
  }
  getDocumentService() {
    if (this.documentService) {
      return this.documentService;
    } else {
      return new DocumentServiceImpl();
    }
  }
  getUserService() {
    if (this.userService) {
      return this.userService;
    } else {
      return new UserServiceImpl();
    }
  }
  getRoleService() {
    if (this.roleService) {
      return this.roleService;
    } else {
      return new RoleServiceImpl();
    }
  }
  getGroupUserService() {
    if (this.groupUserService) {
      return this.groupUserService;
    } else {
      return new GroupUserServiceImpl();
    }
  }
}
export const serviceFactory = new ServiceFactory();
