import React from 'react';
import Button from '@material-ui/core/Button';
import { AppBar, Toolbar, Typography } from '@material-ui/core';
import { Grid, Paper } from '@material-ui/core';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import SaveIcon from '@material-ui/icons/Save';
import TextField from '@material-ui/core/TextField';
import moment from 'moment';
import { serviceFactory } from '../../common/config/ServiceFactory';
import { connect } from 'react-redux';
class EditToolComp extends React.Component<any, any> {
  private readonly toolComponentService = serviceFactory.getToolComponentService();
  constructor(props) {
    super(props);
    this.state = {
      tools: '',
      PCB: '',
      RDProjects: '',
      ASIC: '',
      CPU: '',
      MRP_CPU: '',
      Memory: '',
      MRP_Memory: '',
      notes: '',
      date: new Date(),
    };
  }
  async componentDidMount() {
    //release get by id release
    if (this.props.match.params && this.props.match.params.id) {
      const idToolComp = this.props.match.params.id;
      this.toolComponentService
        .getOneToolCompById(idToolComp)
        .subscribe((res) => {
          if (res.data.data) {
            this.setState({
              tools: res.data.data.tools,
              PCB: res.data.data.PCB,
              RDProjects: res.data.data.RDProjects,
              ASIC: res.data.data.ASIC,
              CPU: res.data.data.CPU,
              MRP_CPU: res.data.data.MRP_CPU,
              Memory: res.data.data.Memory,
              MRP_Memory: res.data.data.MRP_Memory,
              notes: res.data.data.notes,
              date: res.data.data.date,
            });
          }
        });
    }
  }
  onChange = (evt) => {
    const value = evt.target.value;
    this.setState({
      ...this.state,
      [evt.target.name]: value,
    });
  };
  onSubmit = (e) => {
    e.preventDefault();
    const body = {
      tools: this.state.tools,
      PCB: this.state.PCB,
      RDProjects: this.state.RDProjects,
      ASIC: this.state.ASIC,
      CPU: this.state.CPU,
      MRP_CPU: this.state.MRP_CPU,
      Memory: this.state.Memory,
      MRP_Memory: this.state.MRP_Memory,
      notes: this.state.notes,
      date: new Date(this.state.date),
    };
    const idToolComp = this.props.match.params.id;
    this.toolComponentService
      .updateToolComp(idToolComp, body)
      .subscribe((res) => {
        if (res.response && res.response.data.error) {
          this.setState({ error: res.response.data.error });
          window.alert(res.response.data.error);
        } else {
          this.setState({
            tools: '',
            PCB: '',
            RDProjects: '',
            ASIC: '',
            CPU: '',
            MRP_CPU: '',
            Memory: '',
            MRP_Memory: '',
            notes: '',
            date: new Date(),
          });
          this.props.history.push('/componentTool');
        }
      });
  };
  handleDateChange = (date) => {
    this.setState({ date });
  };
  render = () => {
    return (
      <div>
        <Paper elevation={4} style={{ marginBottom: 16, padding: 16 }}>
          <form noValidate autoComplete='off' onSubmit={this.onSubmit}>
            <div className='custom-buttoncreate'>
              <Typography variant='h4' component='h2' gutterBottom>
                Update Tool Component:
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={3}>
                  <TextField
                    required
                    fullWidth
                    id='tools'
                    label='Tools'
                    name='tools'
                    autoComplete='tools'
                    InputLabelProps={{ shrink: true }}
                    value={this.state.tools}
                    onChange={this.onChange}
                    inputProps={{
                      autoComplete: 'off', // disable autocomplete and autofill
                    }}
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    required
                    fullWidth
                    id='PCB'
                    label='PCB'
                    name='PCB'
                    autoComplete='PCB'
                    InputLabelProps={{ shrink: true }}
                    value={this.state.PCB}
                    onChange={this.onChange}
                    inputProps={{
                      autoComplete: 'off', // disable autocomplete and autofill
                    }}
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    required
                    fullWidth
                    id='RDProjects'
                    label='R&D Projects'
                    name='RDProjects'
                    autoComplete='RDProjects'
                    InputLabelProps={{ shrink: true }}
                    value={this.state.RDProjects}
                    onChange={this.onChange}
                    inputProps={{
                      autoComplete: 'off', // disable autocomplete and autofill
                    }}
                  />
                </Grid>

                <Grid item xs={3}>
                  <TextField
                    required
                    fullWidth
                    id='ASIC'
                    label='ASIC'
                    name='ASIC'
                    autoComplete='ASIC'
                    InputLabelProps={{ shrink: true }}
                    value={this.state.ASIC}
                    onChange={this.onChange}
                    inputProps={{
                      autoComplete: 'off', // disable autocomplete and autofill
                    }}
                  />
                </Grid>

                <Grid item xs={3}>
                  <TextField
                    required
                    fullWidth
                    id='CPU'
                    label='CPU'
                    name='CPU'
                    autoComplete='CPU'
                    InputLabelProps={{ shrink: true }}
                    value={this.state.CPU}
                    onChange={this.onChange}
                    inputProps={{
                      autoComplete: 'off', // disable autocomplete and autofill
                    }}
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    required
                    fullWidth
                    id='MRP_CPU'
                    label='MRP#'
                    name='MRP_CPU'
                    autoComplete='email'
                    InputLabelProps={{ shrink: true }}
                    value={this.state.MRP_CPU}
                    onChange={this.onChange}
                    inputProps={{
                      autoComplete: 'off', // disable autocomplete and autofill
                    }}
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    required
                    fullWidth
                    id='Memory'
                    label='Memory'
                    name='Memory'
                    autoComplete='email'
                    InputLabelProps={{ shrink: true }}
                    value={this.state.Memory}
                    onChange={this.onChange}
                    inputProps={{
                      autoComplete: 'off', // disable autocomplete and autofill
                    }}
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    required
                    fullWidth
                    id='MRP_Memory'
                    label='MRP#'
                    name='MRP_Memory'
                    autoComplete='email'
                    InputLabelProps={{ shrink: true }}
                    value={this.state.MRP_Memory}
                    onChange={this.onChange}
                    inputProps={{
                      autoComplete: 'off', // disable autocomplete and autofill
                    }}
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    required
                    fullWidth
                    id='notes'
                    label='Notes'
                    name='notes'
                    autoComplete='email'
                    InputLabelProps={{ shrink: true }}
                    value={this.state.notes}
                    onChange={this.onChange}
                    inputProps={{
                      autoComplete: 'off', // disable autocomplete and autofill
                    }}
                  />
                </Grid>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <Grid item xs={3}>
                    <KeyboardDatePicker
                      disableToolbar
                      variant='inline'
                      format='MM/dd/yyyy'
                      id='date'
                      name='date'
                      label='Date'
                      value={moment(this.state.date).toDate()}
                      KeyboardButtonProps={{
                        'aria-label': 'change date',
                      }}
                      fullWidth
                      autoOk
                      InputLabelProps={{ shrink: true }}
                      onChange={this.handleDateChange}
                      inputProps={{
                        autoComplete: 'off', // disable autocomplete and autofill
                      }}
                    />
                  </Grid>
                </MuiPickersUtilsProvider>

                <Grid item xs={12}>
                  <Button
                    variant='contained'
                    color='secondary'
                    size='medium'
                    type='submit'
                    startIcon={<SaveIcon />}
                  >
                    Update
                  </Button>
                </Grid>
              </Grid>
            </div>
          </form>
        </Paper>
      </div>
    );
  };
}

export default connect()(EditToolComp);
