import React from 'react';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Button from '@material-ui/core/Button';
import { AppBar, Toolbar, Typography } from '@material-ui/core';
import { Grid, Paper } from '@material-ui/core';
import DateFnsUtils from '@date-io/date-fns';
import { serviceFactory } from '../../common/config/ServiceFactory';
import * as toolActions from '../../../redux/actions/toolActions';
import SaveIcon from '@material-ui/icons/Save';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import ReleaseScheduleComponent from './ReleaseSchedule';
import moment from 'moment';
class InputSchedule extends React.Component<any, any> {
  private readonly toolService = serviceFactory.getToolService();
  private readonly groupService = serviceFactory.getGroupService();
  private readonly releasesService = serviceFactory.getReleasesService();
  private readonly statusReleasesService = serviceFactory.getStatusReleasesService();
  constructor(props) {
    super(props);
    this.state = {
      dateLastFw: new Date(),
      dateNextFw: new Date(),
      toolId: '',
      tools: '',
      tool: null,
      toolName: '',
      findVersion: {},
      releases: [],
      groups: [],
      toolall: [],
      statusReleases: [],
      statusRelease: '',
      statusReleasesId: '',
      groupId: '',
      group: null,
      USreleased: '',
      USapproved: '',
      VNapproved: '',
      VNtesting: '',
      groupName: '',
      lastedfw: '',
      nextVersion: '0 days',
    };
  }
  getNextVersion() {
    const today = moment(new Date()).startOf('day');
    const dateNextFw = moment(this.state.dateNextFw).startOf('day');
    const diff = dateNextFw.diff(today, 'days');
    this.setState({ nextVersion: diff + ' days' });
  }
  handleDateNextChange = (dateNextFw) => {
    this.setState({ dateNextFw }, () => {
      this.getNextVersion();
    });
  };
  handleDateLastFwChange = (dateLastFw) => {
    this.setState({ dateLastFw });
  };
  handleChangeTool = (event, value) => {
    if (value) {
      this.setState({
        toolId: value.id,
        tool: { title: value.title, ...value },
      });
    }
  };
  handleChangeGroup = (event, value) => {
    if (value) {
      this.setState(
        {
          groupId: value.id,
          group: { title: value.title, ...value },
          groupName: value.groupName,
        },
        () => {
          // set tool combobox
          //combobox tool by idGroup
          this.toolService
            .getAllToolsByGroupId(this.state.groupId)
            .subscribe((res) => {
              // check error
              this.setState({
                toolall: res.data.data,
              });
            });
        }
      );
    }
  };

  handleChangeStatus = (event, value) => {
    if (value) {
      this.setState({
        statusReleasesId: value.id,
        statusRelease: { title: value.title, ...value },
      });
    }
  };

  async componentDidMount() {
    // combobox
    this.toolService.getToolWidthProductionVersion().subscribe((res) => {
      this.setState({
        tools: res.data.data,
      });
    });
    this.releasesService.getallrelease().subscribe((res) => {
      this.setState({ releases: res.data.data });
    });
    //comboboxgroup
    this.groupService.getAllGroupTool().subscribe((res) => {
      this.setState({
        groups: res.data.data,
      });
    });
    //combobox status Releases
    this.statusReleasesService.getallStatusReleases().subscribe((res) => {
      this.setState({
        statusReleases: res.data.data,
      });
    });
  }
  onSubmit = (e) => {
    e.preventDefault();
    const body = {
      toolId: this.state.toolId,
      toolName: this.state.tool.title,
      groupName: this.state.group.title,
      lastedfw: this.state.lastedfw || 'N/A',
      dateLastFw: new Date(this.state.dateLastFw),
      statusReleasesId: this.state.statusReleasesId,
      USreleased: this.state.USreleased || 'N/A',
      USapproved: this.state.USapproved || 'N/A',
      VNapproved: this.state.VNapproved || 'N/A',
      VNtesting: this.state.VNtesting || 'N/A',
      dateNextFw: new Date(this.state.dateNextFw),
    };

    this.releasesService.createRelease(body).subscribe((res) => {
      if (res.response && res.response.data.error) {
        this.setState({ error: res.response.data.error });
        window.alert(res.response.data.error);
      } else {
        this.setState({
          toolId: '',
          toolName: null,
          groupName: null,
          lastedfw: '',
          dateLastFw: new Date(),
          statusReleasesId: null,
          USreleased: '',
          USapproved: '',
          VNapproved: '',
          VNtesting: '',
          dateNextFw: new Date(),
        });
        this.props.history.push('/release-schedule');
      }
    });
  };

  onClear = () => {
    this.setState({});
  };
  onChange = (evt) => {
    const value = evt.target.value;
    this.setState({
      ...this.state,
      [evt.target.name]: value,
    });
  };
  render = () => {
    return (
      <div>
        <Paper elevation={4} style={{ marginBottom: 16, padding: 16 }}>
          <form noValidate autoComplete='off' onSubmit={this.onSubmit}>
            <div className='custom-buttoncreate'>
              <Typography variant='h4' component='h2' gutterBottom>
                Add Release Schedule:
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={3}>
                  <Autocomplete
                    id='combo-box-demo'
                    onChange={this.handleChangeGroup}
                    options={dataGroup(this.state.groups)}
                    getOptionLabel={(option) => option.title}
                    value={this.state.group}
                    // style={{ width: 200 }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label='Group Tool'
                        fullWidth
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: 'off', // disable autocomplete and autofill
                        }}
                        InputLabelProps={{ shrink: true }}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={3}>
                  <Autocomplete
                    id='combo-box-demo'
                    onChange={this.handleChangeTool}
                    options={dataToolName(this.state.toolall)}
                    getOptionLabel={(option) => option.title}
                    value={this.state.tool}
                    // style={{ width: 200 }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label='Tool name'
                        fullWidth
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: 'off', // disable autocomplete and autofill
                        }}
                        InputLabelProps={{ shrink: true }}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    required
                    fullWidth
                    id='lastedfw'
                    label='Latest FW'
                    name='lastedfw'
                    autoComplete='lastedfw'
                    InputLabelProps={{ shrink: true }}
                    value={this.state.lastedfw}
                    onChange={this.onChange}
                    inputProps={{
                      autoComplete: 'off', // disable autocomplete and autofill
                    }}
                  />
                </Grid>

                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <Grid item xs={3}>
                    <KeyboardDatePicker
                      disableToolbar
                      variant='inline'
                      format='MM/dd/yyyy'
                      id='dateLastFw'
                      name='dateLastFw'
                      label='Date of the latest FW Production'
                      value={moment(this.state.dateLastFw).toDate()}
                      KeyboardButtonProps={{
                        'aria-label': 'change date',
                      }}
                      fullWidth
                      autoOk
                      InputLabelProps={{ shrink: true }}
                      onChange={this.handleDateLastFwChange}
                      inputProps={{
                        autoComplete: 'off', // disable autocomplete and autofill
                      }}
                    />
                  </Grid>
                </MuiPickersUtilsProvider>
                <Grid item xs={3}>
                  <Autocomplete
                    id='combo-box-demo'
                    onChange={this.handleChangeStatus}
                    options={dataStatusReleases(this.state.statusReleases)}
                    getOptionLabel={(option) => option.title}
                    value={this.state.statusRelease}
                    // style={{ width: 200 }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label='Status'
                        fullWidth
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: 'off', // disable autocomplete and autofill
                        }}
                        InputLabelProps={{ shrink: true }}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    required
                    fullWidth
                    id='USreleased'
                    label='US R&D Latest released'
                    name='USreleased'
                    autoComplete='email'
                    InputLabelProps={{ shrink: true }}
                    value={this.state.USreleased}
                    onChange={this.onChange}
                    inputProps={{
                      autoComplete: 'off', // disable autocomplete and autofill
                    }}
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    required
                    fullWidth
                    id='USapproved'
                    label='US R&D Latest approved'
                    name='USapproved'
                    autoComplete='email'
                    InputLabelProps={{ shrink: true }}
                    value={this.state.USapproved}
                    onChange={this.onChange}
                    inputProps={{
                      autoComplete: 'off', // disable autocomplete and autofill
                    }}
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    required
                    fullWidth
                    id='VNapproved'
                    label='VN R&D Approved'
                    name='VNapproved'
                    autoComplete='email'
                    InputLabelProps={{ shrink: true }}
                    value={this.state.VNapproved}
                    onChange={this.onChange}
                    inputProps={{
                      autoComplete: 'off', // disable autocomplete and autofill
                    }}
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    required
                    fullWidth
                    id='VNtesting'
                    label='VN R&D Testing'
                    name='VNtesting'
                    autoComplete='email'
                    InputLabelProps={{ shrink: true }}
                    value={this.state.VNtesting}
                    onChange={this.onChange}
                    inputProps={{
                      autoComplete: 'off', // disable autocomplete and autofill
                    }}
                  />
                </Grid>

                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <Grid item xs={3}>
                    <KeyboardDatePicker
                      disableToolbar
                      variant='inline'
                      format='MM/dd/yyyy'
                      id='dateNextFw'
                      name='dateNextFw'
                      label='Date of the Next FW Production'
                      value={this.state.dateNextFw}
                      onChange={this.handleDateNextChange}
                      KeyboardButtonProps={{
                        'aria-label': 'change date',
                      }}
                      fullWidth
                      autoOk
                      InputLabelProps={{ shrink: true }}
                      inputProps={{
                        autoComplete: 'off', // disable autocomplete and autofill
                      }}
                    />
                  </Grid>
                </MuiPickersUtilsProvider>
                <Grid item xs={3}>
                  <TextField
                    required
                    fullWidth
                    id='nextVersion'
                    label='Next version'
                    name='nextVersion'
                    autoComplete='email'
                    InputLabelProps={{ shrink: true }}
                    value={this.state.nextVersion}
                    inputProps={{ readOnly: true, autoComplete: 'off' }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Button
                    variant='contained'
                    color='secondary'
                    size='medium'
                    type='submit'
                    startIcon={<SaveIcon />}
                  >
                    Add
                  </Button>
                </Grid>
              </Grid>
            </div>
          </form>
        </Paper>
      </div>
    );
  };
}

const dataStatusReleases = (options: any) => {
  let list = [];
  for (const option of options) {
    if (option.name) {
      list.push({ title: option.name, id: option._id });
    }
  }
  return list;
};
const dataGroup = (options: any) => {
  let list = [];
  for (const option of options) {
    if (option.groupName) {
      list.push({ title: option.groupName, id: option._id });
    }
  }
  return list;
};
const dataToolName = (options: any) => {
  let list = [];
  for (const option of options) {
    if (option.toolName) {
      list.push({ title: option.toolName, id: option._id });
    }
  }
  return list;
};

export default InputSchedule;
