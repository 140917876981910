import React from 'react';
import MUIDataTable from 'mui-datatables';
import { serviceFactory } from '../../common/config/ServiceFactory';
import { RoleService } from '../../common/service/role/RoleService';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import SaveIcon from '@material-ui/icons/Save';
export class FunctionAuthRole extends React.Component<any, any> {
  constructor(props) {
    super(props);
    this.roleService = serviceFactory.getRoleService();
    this.state = {
      toolofRoleArr: this.props.toolofRoleArr,
    };
  }
  private roleService: RoleService;
  private id = undefined;
  componentWillReceiveProps(nextProps) {
    this.setState({
      toolofRoleArr: nextProps.toolofRoleArr,
    });
  }
  navigateToUpdate = (id) => {};
  updateRole = () => {
    const roleid = this.props.match.params.id;
    const selectIds = this.state.toolofRoleArr
      .filter((tool) => tool.isInRole)
      .map((toolFiltered) => toolFiltered._id);
    const buildBody = {
      idRole: roleid,
      toolIdsArray: selectIds,
    };
    this.roleService.updateToolOnOneRole(buildBody).subscribe((res) => {
      if (res && res.status === 200) {
        alert('Update role success!');
        window.location.reload();
      } else {
        alert('Error can not update role!');
      }
    });
  };
  render() {
    const columns = [
      {
        name: 'id',
        options: {
          display: 'false',
          filter: false,
          //viewColumns: false, // close viewcolumns
          download: false,
        },
      },
      'Tool Name',
      'Group Name',
      'Currently In Role(s)',
      {
        name: 'Auth Role',
        options: {
          filter: false,
          sort: false,
          customBodyRender: (value, tableMeta) => {
            return (
              <div className='btn-group'>
                <Checkbox
                  onChange={(e) => {
                    const { toolofRoleArr } = this.state;
                    toolofRoleArr[tableMeta.rowIndex] = {
                      ...toolofRoleArr[tableMeta.rowIndex],
                      isInRole: e.target.checked,
                    };
                    this.setState({
                      toolofRoleArr,
                    });
                  }}
                  checked={tableMeta.rowData[4]}
                  value='inRole'
                  color='primary'
                  inputProps={{ 'aria-label': 'secondary checkbox' }}
                />
              </div>
            );
          },
        },
      },
    ];
    const MapDataToDataTable = (data1) => {
      return data1.map((item) => {
        return [
          item._id,
          item.toolName,
          item.groupId.groupName,
          item.currentlyInRole,
          item.isInRole,
        ];
      });
    };
    const options = {
      filterType: 'dropdown',
      responsive: 'scrollMaxHeight',
      selectableRows: 'none',
      viewColumns: false, // close viewcolumns
      print: false, // close print
    };
    return (
      <>
        <MUIDataTable
          title={'Please select tools for authorization for this role'}
          data={MapDataToDataTable(this.state.toolofRoleArr)}
          columns={columns}
          options={options}
        />
        <Button
          onClick={this.updateRole}
          style={{ marginTop: 20 }}
          variant='contained'
          color='primary'
          size='small'
          type='button'
          startIcon={<SaveIcon />}
        >
          Update Role
        </Button>
      </>
    );
  }
}
