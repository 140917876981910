import React from 'react';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { serviceFactory } from '../../common/config/ServiceFactory';
import { Grid, Paper } from '@material-ui/core';
import { connect } from 'react-redux';
import { fetchGroupAndToolData } from '../../../redux/actions/horizontalNavbarActions';
import SaveIcon from '@material-ui/icons/Save';
import { sanitizeASCIIString } from '../../../utils/StringUtils';

class EditGroup extends React.Component<any, any> {
  private readonly groupService = serviceFactory.getGroupService();
  constructor(props) {
    super(props);
    this.state = {
      groupName: '',
      error: '',
    };
  }
  componentDidMount() {
    if (this.props.match.params && this.props.match.params.id) {
      const groupId = this.props.match.params.id;
      this.groupService.getOneGroupTool(groupId).subscribe((res) => {
        if (res.data.data) {
          this.setState({ groupName: res.data.data.groupName });
        }
      });
    }
  }
  onChange = (e) => {
    this.setState({
      groupName: e.target.value,
    });
  };

  onSubmit = (e) => {
    e.preventDefault();

    const body = {
      groupName: sanitizeASCIIString(this.state.groupName),
    };

    const idGroup = this.props.match.params.id;
    this.groupService.updateOneGroupTool(idGroup, body).subscribe((res) => {
      if (res.response && res.response.data.error) {
        this.setState({ error: res.response.data.error });
        console.error(res.response.data.error);
        window.alert(res.response.data.error);
      } else {
        this.navigateBack();
        this.onClear();
        this.props.dispatch(fetchGroupAndToolData());
      }
    });
  };

  onClear = () => {
    this.setState({
      groupName: '',
    });
  };

  navigateBack = () => {
    this.props.history.goBack();
  };

  render = () => {
    return (
      <form
        noValidate
        autoComplete='off'
        onSubmit={this.onSubmit}
        style={{ height: '100%' }}
      >
        <div className='custom-buttoncreate'>
          <Typography variant='h6' component='h3' style={{ marginBottom: 16 }}>
            Update Group:
          </Typography>
          <Grid container spacing={4} alignItems='center'>
            <Grid item xs={4}>
              <TextField
                required
                id='outlined-required'
                label='Required'
                variant='outlined'
                name='groupName'
                value={this.state.groupName}
                onChange={this.onChange}
                fullWidth
                size='small'
              />
            </Grid>
            <Grid item xs={8}>
              <Button
                variant='contained'
                color='secondary'
                type='submit'
                startIcon={<SaveIcon />}
              >
                {'Update'}
              </Button>
            </Grid>
          </Grid>
        </div>
      </form>
    );
  };
}
export default connect()(EditGroup);
