import React from 'react';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { FunctionGroup } from './functiongroup';
import { serviceFactory } from '../../common/config/ServiceFactory';
import { Grid, Paper } from '@material-ui/core';
import { fetchGroupGroupNameData } from '../../../redux/actions/horizontalNavbarActions';
import { connect } from 'react-redux';
import SaveIcon from '@material-ui/icons/Save';
import * as toolActions from '../../../redux/actions/toolActions';
import * as groupActions from '../../../redux/actions/groupActions';
import { sanitizeASCIIString } from '../../../utils/StringUtils';
class NewGroup extends React.Component<any, any> {
  private readonly groupService = serviceFactory.getGroupService();
  constructor(props) {
    super(props);
    this.state = {
      groupName: '',
      groups: [],
    };
  }
  componentDidMount() {
    this.props.dispatch(toolActions.selectTool(null));
    this.props.dispatch(groupActions.selectGroup(null));
    this.groupService.getAllGroupTool().subscribe((res) => {
      this.setState({ groups: res.data.data });
    });
  }
  onChange = (e) => {
    this.setState({
      groupName: e.target.value,
    });
  };

  onSubmit = (e) => {
    e.preventDefault();

    const body = {
      groupName: sanitizeASCIIString(this.state.groupName),
    };

    this.groupService.createGroup(body).subscribe((res) => {
      this.setState({ groupName: '' });
      this.props.dispatch(fetchGroupGroupNameData());
      this.groupService.getAllGroupTool().subscribe((res1) => {
        // moi lan add xong thi se goi lai api get ds group
        this.setState({ groups: res1.data.data });
      });
    });
    // cancel
    this.onClear();
  };

  onClear = () => {
    this.setState({
      groupName: '',
    });
  };

  handleDeleteGroupSuccess = () => {
    this.componentDidMount();
    this.props.dispatch(fetchGroupGroupNameData());
  };

  render = () => {
    return (
      <React.Fragment>
        <form noValidate autoComplete='off' onSubmit={this.onSubmit}>
          <div className='custom-buttoncreate'>
            <Typography variant='h6' component='h3'>
              New Group
            </Typography>
            <Grid container spacing={4} alignItems='center'>
              <Grid item xs={4}>
                <TextField
                  required
                  label='Group name'
                  variant='outlined'
                  name='groupName'
                  value={this.state.groupName}
                  onChange={this.onChange}
                  fullWidth
                  placeholder='Please enter group name'
                  size='small'
                  style={{ marginTop: 16, marginBottom: 16 }}
                />
              </Grid>
              <Grid item xs={8}>
                <Button
                  variant='contained'
                  color='secondary'
                  type='submit'
                  startIcon={<SaveIcon />}
                >
                  Add
                </Button>
              </Grid>
            </Grid>
          </div>
        </form>
        <FunctionGroup
          onDeleteSuccess={this.handleDeleteGroupSuccess}
          {...this.props}
          groups={this.state.groups}
        />
      </React.Fragment>
    );
  };
}
export default connect()(NewGroup);
