export function trimMiddleSpaces(str) {
  const newString = str.replace(/\s\s+/g, ' ');
  return newString;
}

export function trimSpaces(str) {
  const newString = trimMiddleSpaces(str).trim();
  return newString;
}

export function sanitizeASCIIString(str) {
  const newString = str
    .replace(/\r?\n|\r/g, '') // remove newline char
    .replace(
      /[\u00A0\u1680\u180e\u2000-\u2009\u200a\u200b\u202f\u205f\u3000]/g,
      ' '
    ) // replace unicode spaces to normal spaces
    .replace(/[^\x00-\x7F]/g, ''); // replace non-ascii char to empty

  return trimSpaces(newString); // trim it all again
}

export function sanitizeStringForFolder(str) {
  return str.replace(/[\\/:*?"<>\|]/g, '-');
}
