import { createStore, applyMiddleware, AnyAction, Reducer } from 'redux';
import rootReducer from './reducers';
import reduxImmutableStateInvariant from 'redux-immutable-state-invariant';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
// const persistConfig = {
//   key: 'root',
//   storage,
//   stateReconciler: autoMergeLevel2, // see "Merge Process" section for details.
// };

// const pReducer = persistReducer(
//   persistConfig,
//   rootReducer as Reducer<unknown, AnyAction>
// );

export default function configureStore(initialState = {}) {
  return createStore(
    rootReducer,
    initialState,
    composeWithDevTools(applyMiddleware(thunk, reduxImmutableStateInvariant()))
  );
}
