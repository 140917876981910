import React from 'react';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { serviceFactory } from '../../common/config/ServiceFactory';
import { Grid, Paper } from '@material-ui/core';
import { connect } from 'react-redux';
import { fetchGroupAndToolData } from '../../../redux/actions/horizontalNavbarActions';
import SaveIcon from '@material-ui/icons/Save';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import Checkbox from '@material-ui/core/Checkbox';
import { FunctionUserandGroup } from '../User/functionUserGroup';
import * as toolActions from '../../../redux/actions/toolActions';
import * as groupActions from '../../../redux/actions/groupActions';
class NewUseronGroup extends React.Component<any, any> {
  private readonly userService = serviceFactory.getUserService();
  private readonly groupUserService = serviceFactory.getGroupUserService();
  selectedEmails = {};
  constructor(props) {
    super(props);
    this.state = {
      groupUserarr: [],
      groupuserId: '',
      groupuser: null,
      emailarr: [],
      emailUser: [], // can select multiple emails
      usergrouparr: [],
      email: '',
    };
  }
  componentDidMount() {
    this.props.dispatch(toolActions.selectTool(null));
    this.props.dispatch(groupActions.selectGroup(null));
    // combobox groupUser
    this.groupUserService.getallGroupUser().subscribe((res) => {
      this.setState({
        groupUserarr: res.data.data,
      });
    });
    // combobox User email
    this.userService.GetallUser().subscribe((res) => {
      this.setState({
        emailarr: res.data.data,
      });
    });
    // show all user and groupuser
    this.userService.getallUserwithGroupUser().subscribe((res) => {
      this.setState({
        usergrouparr: res.data.data,
      });
    });
  }
  handleDeleteUserSuccess = () => {
    this.componentDidMount();
  };
  onSubmit = (e) => {
    e.preventDefault();
    const body = {
      userEmails: Object.keys(this.selectedEmails),
      groupUserId: this.state.groupuserId,
    };
    this.userService.updateGroupofUser(body).subscribe((res) => {
      if (res && res.status === 200) {
        window.location.reload();
      }
    });
    this.onClear();
  };

  onClear = () => {
    this.setState({});
  };
  onChange = (e) => {
    this.setState({});
  };
  handleChangeGroupUser = (event, value) => {
    if (value) {
      this.setState({ groupuserId: value.id, groupuser: value });
    }
  };
  handleChangeEmail = (value) => {
    if (value) {
      this.setState({ emailUser: value });
    }
  };

  render = () => {
    return (
      <React.Fragment>
        <form noValidate autoComplete='off' onSubmit={this.onSubmit}>
          <div className='custom-buttoncreate'>
            <Typography variant='h6' component='h3'>
              Update Users And Group Users
            </Typography>
            <Grid container spacing={4} alignItems='center'>
              <Grid item xs={6} justify='flex-end'>
                <Autocomplete
                  size='small'
                  multiple
                  options={dataEmailUser(this.state.emailarr)}
                  disableCloseOnSelect
                  getOptionLabel={(option) => option.title}
                  renderOption={(option, { selected }) => {
                    if (selected) {
                      this.selectedEmails[option.title] = option;
                    } else {
                      delete this.selectedEmails[option.title];
                    }
                    return (
                      <React.Fragment>
                        <Checkbox
                          icon={icon}
                          checkedIcon={checkedIcon}
                          style={{ marginRight: 8 }}
                          checked={selected}
                        />
                        {option.title}
                      </React.Fragment>
                    );
                  }}
                  style={{ width: 500 }}
                  renderInput={(params) => (
                    <TextField
                      size='small'
                      fullWidth
                      {...params}
                      variant='outlined'
                      label='Please select users'
                      placeholder='Please select users'
                      style={{ marginTop: 16, marginBottom: 16 }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={2}>
                <Autocomplete
                  onChange={this.handleChangeGroupUser}
                  options={flattenOptions(this.state.groupUserarr)}
                  getOptionLabel={(option) => option.title}
                  //  style={{ width: 300 }}
                  value={this.state.groupuser}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label='Select Group User'
                      variant='outlined'
                      fullWidth
                      size='small'
                      style={{ marginTop: 16, marginBottom: 16 }}
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: 'off', // disable autocomplete and autofill
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={4}>
                <Button
                  variant='contained'
                  color='secondary'
                  size='medium'
                  type='submit'
                  startIcon={<SaveIcon />}
                >
                  Update
                </Button>
              </Grid>
            </Grid>
          </div>
        </form>

        <FunctionUserandGroup
          usergrouparr={this.state.usergrouparr}
          onDeleteSuccess={this.handleDeleteUserSuccess}
          {...this.props}
        />
      </React.Fragment>
    );
  };
}
const icon = <CheckBoxOutlineBlankIcon fontSize='small' />;
const checkedIcon = <CheckBoxIcon fontSize='small' />;
const flattenOptions = (options: any) => {
  let list = [];
  for (const option of options) {
    if (option.nameGroup) {
      list.push({ title: option.nameGroup, id: option._id });
    }
  }
  return list;
};
const dataEmailUser = (options: any) => {
  let listuser = [];
  for (const option of options) {
    if (option.email) {
      listuser.push({ title: option.email, id: option._id });
    }
  }
  return listuser;
};

export default connect()(NewUseronGroup);
