import React from 'react';
import Button from '@material-ui/core/Button';
import { Typography, Paper, FormLabel } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import { serviceFactory } from '../../common/config/ServiceFactory';
import { connect } from 'react-redux';
import * as toolActions from '../../../redux/actions/toolActions';
import SaveIcon from '@material-ui/icons/Save';
class EditBugTracking extends React.Component<any, any> {
  private statusBugService = serviceFactory.getStatusBugService();
  private makeService = serviceFactory.getMakeService();
  private bugsTrackingService = serviceFactory.getBugsTrackingService();
  private toolService = serviceFactory.getToolService();
  private bugId: string;
  constructor(props) {
    super(props);
    this.bugId = this.props.match.params.id;
    this.state = {
      fw: '',
      description: '',
      redmine: '',
      status: '',
      // statusBug: [],
      // statusBugId: '',
      versionId: '',
      makearr: [],
      makeId: '',
      make: '',
      fwarr: [],
      function: '',
    };
  }
  onChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleDateChange = (date) => {
    this.setState({ date });
  };
  async componentDidMount() {
    // set tool
    // goi redux
    const idTool = this.props.match.params.idTool;
    const toolRes = await this.toolService.getOneTool(idTool).toPromise();
    if (toolRes.response && toolRes.response.data.error) {
      // error checking
      alert(toolRes.response.data.error);
    } else {
      this.props.dispatch(
        toolActions.selectTool({
          ...toolRes.data.data,
          title: toolRes.data.data.toolName,
        })
      );
    }
    //combobox make
    this.makeService.getallMake().subscribe((res) => {
      this.setState({
        makearr: res.data.data,
      });
    });
    //combobox FW
    this.bugsTrackingService
      .getLatest5FWwithOneTool(this.props.match.params.idTool)
      .subscribe((res) => {
        this.setState({
          fwarr: res.data.data.version,
        });
      });
    //Bugs get by id bug
    this.bugsTrackingService.getOneBugById(this.bugId).subscribe((res) => {
      if (res.response && res.response.data.error) {
        this.setState({ error: res.response.data.error });
        console.error(res.response.data.error);
        window.alert(res.response.data.error);
      } else {
        this.setState({
          fw: {
            title: res.data.data.fw,
            id: res.data.data.versionId,
          },
          make: {
            title: res.data.data.make.nameMake,
            id: res.data.data.make._id,
          },
          // add cot function
          function: res.data.data.function || 'N/A',
          description: res.data.data.description || 'N/A',
          redmine: res.data.data.link_redmine || 'N/A',
          status: res.data.data.statusBug.value || 'N/A',
        });
      }
    });
  }
  hangleChangeFW = (event, value) => {
    if (!value) return;
    if (!value.id) {
      this.bugsTrackingService
        .getAfter5FWwithOneTool(this.props.match.params.idTool) // this.props.tool.id => sai,
        .subscribe((res) => {
          this.setState({
            fwarr: res.data.data.version,
          });
        });
    }
    this.setState({
      versionId: value.id,
      fw: { title: value.title, ...value },
    });
  };
  // hangleChangeStatusBug = (event, value) => {
  //   if (value) {
  //     this.setState({
  //       statusBugId: value.id,
  //       status: { title: value.title, ...value },
  //     });
  //   }
  // };
  hangleChangeMake = (event, value) => {
    if (value) {
      this.setState({
        makeId: value.id,
        make: { title: value.title, ...value },
      });
    }
  };
  onSubmit = (e) => {
    e.preventDefault();
    const body = {
      fw: this.state.fw.title,
      makeId: this.state.make.id,
      function: this.state.function || 'N/A', // add cot function
      description: this.state.description || 'N/A',
      link_redmine: this.state.redmine || 'N/A',
      status: this.state.status || 'N/A',
    };
    const idTool = this.props.match.params.idTool;
    const bugId = this.props.match.params.id;
    this.bugsTrackingService.updateBugs(bugId, body).subscribe((res) => {
      if (res.response && res.response.data.error) {
        this.setState({ error: res.response.data.error });
        console.error(res.response.data.error);
        window.alert(res.response.data.error);
      } else {
        this.setState({
          fw: null,
          makeId: null,
          function: '', // add cot function
          description: '',
          link_redmine: '',
          statusBugId: null,
        });
        const { history } = this.props;
        history.push(`/bug-tracking/tool/${idTool}`);
      }
    });
  };
  onClear = () => {
    this.setState({
      fw: '',
    });
  };

  render = () => {
    return (
      <Paper elevation={4} style={{ padding: 16 }}>
        <header>
          <Typography variant='h6' component='h3' style={{ color: '#000000' }}>
            {(this.props.tool && this.props.tool.title) || ''} - Update Bugs
          </Typography>
        </header>
        <form noValidate autoComplete='off' onSubmit={this.onSubmit}>
          <div className='custom-buttoncreate'>
            <Grid container spacing={2} xs={12}>
              <Grid item xs={4}>
                <Autocomplete
                  id='combo-box-demo'
                  onChange={this.hangleChangeFW}
                  options={dataFW(this.state.fwarr)}
                  getOptionLabel={(option) => option.title}
                  value={this.state.fw}
                  freeSolo={false}
                  disableCloseOnSelect
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label='Fw / Version'
                      fullWidth
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: 'off', // disable autocomplete and autofill
                      }}
                      InputLabelProps={{ shrink: true }}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={4}>
                <Autocomplete
                  id='combo-box-demo'
                  onChange={this.hangleChangeMake}
                  options={dataMake(this.state.makearr)}
                  getOptionLabel={(option) => option.title}
                  value={this.state.make}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label='Make'
                      fullWidth
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: 'off', // disable autocomplete and autofill
                      }}
                      InputLabelProps={{ shrink: true }}
                    />
                  )}
                />
              </Grid>
              {/* add them cot Function */}
              <Grid item xs={4}>
                <TextField
                  id='function'
                  label='Function'
                  fullWidth
                  name='function'
                  value={this.state.function}
                  onChange={this.onChange}
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  id='description'
                  label='Description'
                  fullWidth
                  name='description'
                  value={this.state.description}
                  onChange={this.onChange}
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>

              <Grid item xs={4}>
                <TextField
                  id='redmine'
                  label='Redmine'
                  fullWidth
                  name='redmine'
                  value={this.state.redmine}
                  onChange={this.onChange}
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>

              {/*status sua ten thanh TODO*/}
              <Grid item xs={4}>
                <TextField
                  id='status'
                  label='ToDo'
                  fullWidth
                  name='status'
                  value={this.state.status}
                  onChange={this.onChange}
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
              {/* <Grid item xs={4}>
                <Autocomplete
                  options={dataStatusBug(this.state.statusBug)}
                  getOptionLabel={(option) => option.title}
                  value={this.state.status}
                  onChange={this.hangleChangeStatusBug}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label='ToDo' //'Tool Bug Status'
                      fullWidth
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: 'off', // disable autocomplete and autofill
                      }}
                      InputLabelProps={{ shrink: true }}
                    />
                  )}
                />
              </Grid> */}
              <Grid item xs={12}>
                <Button
                  type='submit'
                  variant='contained'
                  color='secondary'
                  startIcon={<SaveIcon />}
                >
                  UPDATE
                </Button>
              </Grid>
            </Grid>
          </div>
        </form>
      </Paper>
    );
  };
}
//const fw = [{ title: 'V18.01.02', year: 1994 }];
const dataStatusBug = (options: any) => {
  let list = [];
  for (const option of options) {
    if (option.name) {
      list.push({ title: option.name, id: option._id });
    }
  }
  return list;
};
const dataFW = (options: any) => {
  let list = [];
  for (const option of options) {
    if (option.fw) {
      list.push({ title: option.fw, id: option._id });
    }
  }
  if (options.length <= 5) {
    list.push({ title: 'Show more...', id: null });
  }
  return list;
};
const dataMake = (options: any) => {
  let list = [];
  for (const option of options) {
    if (option.nameMake) {
      list.push({ title: option.nameMake, id: option._id });
    }
  }
  return list;
};

const mapStateToProps = (state, ownProps) => {
  // state is from store
  return {
    tool: state.tool.currentTool,
  };
};
export default connect(mapStateToProps)(EditBugTracking);
