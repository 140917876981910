import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Typography from '@material-ui/core/Typography';
import { DialogTitle } from '@material-ui/core';
class DialogMessage extends React.Component<any, any> {
  constructor(props) {
    super(props);
    this.state = {
      open: this.props.open,
    };
  }
  componentWillReceiveProps(nextProps) {
  // You don't have to do this check first, but it can help prevent an unneeded render
    if (nextProps.open !== this.state.open) {
      this.setState({ open: nextProps.open });
    }
  }
  handleClose = () => {
    this.setState({
      open: false,
    });
  };
  downloadTxtFile = () => {
    const { content } = this.props;
    const element = document.createElement('a');
    const file = new Blob([content], {
      type: 'text/plain',
    });
    element.href = URL.createObjectURL(file);
    element.download = 'error.txt';
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
  };
  render() {
    const { title, content } = this.props;
    return (
      <Dialog
        open={this.state.open}
        onClose={this.handleClose}
        maxWidth='xs'
        aria-labelledby='form-dialog-title'
      >
        <DialogTitle id='alert-dialog-title'>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Typography style={{ color: '#000', whiteSpace: 'pre-line' }}>
              {title}
            </Typography>
          </div>
        </DialogTitle>
        <DialogContent>
          <Typography style={{ color: '#000', whiteSpace: 'pre-line' }}>
            {content}
          </Typography>
        </DialogContent>
        <DialogActions style={{ marginTop: 20 }}>
          <Button onClick={this.handleClose} color='primary'>
            Close
          </Button>
          <Button onClick={this.downloadTxtFile} color='primary'>
            Download
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}
export default DialogMessage;
