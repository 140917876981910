import { WebClientUtil } from './../../util/WebClientUtil';
import { API_ENDPOINT } from './../../../../config';
import { Observable, of, throwError } from 'rxjs';
import { VersionTrackingService } from './VersionTrackingService';
import { catchError } from 'rxjs/operators';
export class VersionTrackingImpl implements VersionTrackingService {
  private baseUrl = API_ENDPOINT.versionTrackingURL;
  createVersionOfTool(id: string, body: any): Observable<any> {
    const url = `${this.baseUrl}/version/${id}`;
    return WebClientUtil.postObject(url, body);
  }
  updateversion(id: string, body: any): Observable<any> {
    const url = `${this.baseUrl}/version/${id}`;
    return WebClientUtil.putObject(url, body);
  }
  getallStatusVersion(): Observable<any> {
    const url = `${this.baseUrl}/status`;
    return WebClientUtil.getObject(url);
  }
  getOneVersionById(id: string): Observable<any> {
    const url = `${this.baseUrl}/version/${id}`;
    return WebClientUtil.getObject(url);
  }
  deleteVersion(id: string): Observable<any> {
    const url = `${this.baseUrl}/version/${id}`;
    return WebClientUtil.deleteObject(url);
  }
  deleteVersions(arrayId: string[]): Observable<any> {
    const url = `${this.baseUrl}/version/delete/DeleteMultiple`;
    return WebClientUtil.postObject(url, arrayId);
  }
}
