import { catchError } from 'rxjs/operators';
import { WebClientUtil } from '../../util/WebClientUtil';
import { API_ENDPOINT } from '../../../../config';
import { Observable, of } from 'rxjs';
import { BugsTrackingService } from './BugsTrackingService';
export class BugsTrackingServiceImpl implements BugsTrackingService {
  private baseUrl = API_ENDPOINT.versionTrackingURL;
  createBugsOnTool(id: string, body: any): Observable<any> {
    const url = `${this.baseUrl}/bugs/${id}`;
    return WebClientUtil.postObject(url, body);
  }
  getallbug(id: string): Observable<any> {
    const url = `${this.baseUrl}/bugs/oneTool/${id}`;
    return WebClientUtil.getObject(url);
  }
  getLatest5FWwithOneTool(id: string): Observable<any> {
    const url = `${this.baseUrl}/bugs/${id}`;
    return WebClientUtil.getObject(url);
  }
  getAfter5FWwithOneTool(id: string): Observable<any> {
    const url = `${this.baseUrl}/bugs/after5lastedfw/${id}`;
    return WebClientUtil.getObject(url);
  }
  deleteBugs(id: string): Observable<any> {
    const url = `${this.baseUrl}/bugs/${id}`;
    return WebClientUtil.deleteObject(url);
  }
  getOneBugById(id: string): Observable<any> {
    const url = `${this.baseUrl}/bugs/getonebugs/${id}`;
    return WebClientUtil.getObject(url);
  }
  updateBugs(id: string, body: any): Observable<any> {
    const url = `${this.baseUrl}/bugs/${id}`;
    return WebClientUtil.putObject(url, body);
  }
  deletemanyBugs(arrayId: string[]): Observable<any> {
    const url = `${this.baseUrl}/bugs/delete/DeleteMultiple`;
    return WebClientUtil.postObject(url, arrayId);
  }
}
