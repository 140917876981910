const defaultState = {
  currentTool: null
}

export default function toolReducer(state = defaultState, action) {
  switch (action.type) {
    case 'SELECT_TOOL_FROM_SEARCH_BAR':
      return { ...state, currentTool: action.tool };
    default:
      return state;
  }
}
