import React from 'react';
import MUIDataTable from 'mui-datatables';
import { Tooltip } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import { serviceFactory } from '../../common/config/ServiceFactory';
import AlertDialog from '../../common/component/Dialog/AlertDialog';
import ResultInfoDialog from '../../common/component/Dialog/ResultInfoDialog';
import { GroupUserService } from '../../common/service/groupUser/GroupUserService';

export class FunctionGroupUser extends React.Component<any, any> {
  constructor(props) {
    super(props);
    this.groupUserService = serviceFactory.getGroupUserService();
    this.state = {
      groupUser: this.props.groupUser,
      openConfirmDeleteGroupUser: false,
      openResultDialog: false,
      resultInfo: '',
    };
  }
  private groupUserService: GroupUserService;
  private id = undefined;

  componentWillReceiveProps(nextProps) {
    this.setState({
      groupUser: nextProps.groupUser,
    });
  }
  navigateToUpdate = (idGroupUser) => {
    this.props.history.push(`/groupUser/update/${idGroupUser}`);
  };
  createData(groupUser: any[]) {
    const displayData = [];
    groupUser.forEach((groupuser) => {
      displayData.push([
        groupuser._id,
        groupuser.nameGroup,
        groupuser.roleId.nameRole,
        null,
        null,
      ]);
    });
    return displayData;
  }
  handleDeleteGroupUser = (id) => {
    this.id = id;
    this.setState({
      openConfirmDeleteGroupUser: true,
    });
  };
  handleCloseConfirm = () => {
    this.setState({
      openConfirmDeleteGroupUser: false,
    });
  };
  handleAcceptDeleleteGroupUser = () => {
    this.setState(
      {
        openConfirmDeleteGroupUser: false,
      },
      () => {
        this.groupUserService.deleteGroupUser(this.id).subscribe((res) => {
          if (res.response && res.response.data.error) {
            this.setState({ error: res.response.data.error });
            console.error(res.response.data.error);
            // window.alert(res.response.data.error);
            this.setState({
              openResultDialog: true,
              isDeleteGroupUserSuccess: false,
              resultInfo: `Can not delete this Role :\n${res.response.data.error}`,
            });
          } else {
            this.props.onDeleteSuccess();
            this.id = undefined;
            this.setState({
              openResultDialog: true,
              isDeleteGroupUserSuccess: true,
              resultInfo: `Delete Role successful`,
            });
          }
        });
      }
    );
  };
  handleCloseResultDialog = () => {
    this.setState({
      openResultDialog: false,
    });
  };
  render() {
    const columns = [
      {
        name: 'idgroupuser',
        options: {
          display: 'false',
          filter: false,
          download: false,
          //viewColumns: false,
        },
      },
      'Group User',
      'Role Name',
      {
        name: 'Edit',
        options: {
          filter: false,
          sort: false,
          download: false,
          //viewColumns: false,
          customBodyRender: (value, tableMeta) => {
            const idGroupUser = tableMeta.rowData && tableMeta.rowData[0];
            return (
              <div className='btn-group'>
                <Tooltip title={'Edit'}>
                  <IconButton
                    onClick={() => this.navigateToUpdate(idGroupUser)}
                  >
                    <EditIcon style={{ color: '#fb8c00' }} />
                  </IconButton>
                </Tooltip>
              </div>
            );
          },
        },
      },
      {
        name: 'Delete',
        options: {
          filter: false,
          sort: false,
          download: false,
          //viewColumns: false,
          customBodyRender: (value, tableMeta) => {
            const id = tableMeta.rowData && tableMeta.rowData[0];
            return (
              <div className='btn-group'>
                <Tooltip title={'Delete'}>
                  <IconButton onClick={() => this.handleDeleteGroupUser(id)}>
                    <DeleteIcon style={{ color: '#d93025' }} />
                  </IconButton>
                </Tooltip>
              </div>
            );
          },
        },
      },
    ];

    const options = {
      filterType: 'dropdown',
      responsive: 'scrollMaxHeight',
      disableToolbarSelect: true,
      selectableRowsHeader: false,
      selectableRows: 'none',
      viewColumns: false, // close viewcolumns
      print: false, // close print
    };

    return (
      <>
        <AlertDialog
          open={this.state.openConfirmDeleteGroupUser}
          onClose={this.handleCloseConfirm}
          title='Do you want to delete this GroupUser?'
          okText='OK'
          cancelText='Cancel'
          onAccept={this.handleAcceptDeleleteGroupUser}
          onCancel={this.handleCloseConfirm}
        />
        <ResultInfoDialog
          open={this.state.openResultDialog}
          onClose={this.handleCloseResultDialog}
          resultInfo={this.state.resultInfo}
          okText='OK'
          onAccept={this.handleCloseResultDialog}
          isOpSuccess={this.state.isDeleteGroupUserSuccess}
        />
        <MUIDataTable
          title={'Group User'}
          data={this.createData(this.state.groupUser)}
          columns={columns}
          options={options}
        />
      </>
    );
  }
}
