import React, { Component } from 'react';
// import './App.scss';
import './assets/scss/main.scss';
import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import AppRoutes from './AppRoutes';
import configureStore from './redux/configureStore';
import { Provider as ReduxProvider } from 'react-redux';
import { PersistGate } from 'redux-persist/lib/integration/react';
import { persistStore, persistReducer } from 'redux-persist';
const store = configureStore();
const persistor = persistStore(store);
const browserHistory = createBrowserHistory();
const Loading = () => <h1>App is loading, please wait</h1>;
class App extends Component {
  render() {
    return (
      <ReduxProvider store={store}>
        {/* <PersistGate loading={<Loading />} persistor={persistor}> */}
        <Router history={browserHistory}>
          <AppRoutes />
        </Router>
        {/* </PersistGate> */}
      </ReduxProvider>
    );
  }
}

export default App;
