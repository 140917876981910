import React from 'react';
import MUIDataTable from 'mui-datatables';
import { Tooltip } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import EditIcon from '@material-ui/icons/Edit';
import { serviceFactory } from '../../common/config/ServiceFactory';
import { RoleService } from '../../common/service/role/RoleService';
import AlertDialog from '../../common/component/Dialog/AlertDialog';
import ResultInfoDialog from '../../common/component/Dialog/ResultInfoDialog';
export class FunctionRole extends React.Component<any, any> {
  constructor(props) {
    super(props);
    this.roleService = serviceFactory.getRoleService();
    this.state = {
      roles: this.props.roles,
      openConfirmDeleteRole: false,
      openResultDialog: false,
      resultInfo: '',
    };
  }
  private roleService: RoleService;
  private id = undefined;
  componentWillReceiveProps(nextProps) {
    this.setState({ roles: nextProps.roles });
  }
  navigateToUpdateAuth = (id) => {
    this.props.history.push(`/role/auth/${id}`);
  };
  navigateToUpdate = (id) => {
    this.props.history.push(`/role/updateRoleName/${id}`);
  };
  handleDeleteRole = (id) => {
    this.id = id;
    this.setState({
      openConfirmDeleteRole: true,
    });
  };
  handleCloseConfirm = () => {
    this.setState({
      openConfirmDeleteRole: false,
    });
  };
  handleAcceptDeleleteRole = () => {
    this.setState(
      {
        openConfirmDeleteRole: false,
      },
      () => {
        this.roleService.deleteRoleName(this.id).subscribe((res) => {
          if (res.response && res.response.data.error) {
            this.setState({ error: res.response.data.error });
            console.error(res.response.data.error);
            // window.alert(res.response.data.error);
            this.setState({
              openResultDialog: true,
              isDeleteRoleSuccess: false,
              resultInfo: `Can not delete this Role :\n${res.response.data.error}`,
            });
          } else {
            this.props.onDeleteSuccess();
            this.id = undefined;
            this.setState({
              openResultDialog: true,
              isDeleteRoleSuccess: true,
              resultInfo: `Delete Role successful`,
            });
          }
        });
      }
    );
  };
  handleCloseResultDialog = () => {
    this.setState({
      openResultDialog: false,
    });
  };
  render() {
    const columns = [
      {
        name: 'id',
        options: {
          display: 'false',
          filter: false,
          download: false,
        },
      },
      'Name',
      {
        name: 'Edit',
        options: {
          filter: false,
          sort: false,
          download: false,
          customBodyRender: (value, tableMeta) => {
            const id = tableMeta.rowData && tableMeta.rowData[0];
            return (
              <div className='btn-role'>
                <Tooltip title={'Edit'}>
                  <IconButton onClick={() => this.navigateToUpdate(id)}>
                    <EditIcon style={{ color: '#fb8c00' }} />
                  </IconButton>
                </Tooltip>
              </div>
            );
          },
        },
      },
      {
        name: 'Delete',
        options: {
          filter: false,
          sort: false,
          download: false,
          customBodyRender: (value, tableMeta) => {
            const id = tableMeta.rowData && tableMeta.rowData[0];
            return (
              <div className='btn-role'>
                <Tooltip title={'Delete'}>
                  <IconButton onClick={() => this.handleDeleteRole(id)}>
                    <DeleteIcon style={{ color: '#d93025' }} />
                  </IconButton>
                </Tooltip>
              </div>
            );
          },
        },
      },
      {
        name: 'Authorize Tools',
        options: {
          filter: false,
          sort: false,
          download: false,
          customBodyRender: (value, tableMeta) => {
            const id = tableMeta.rowData && tableMeta.rowData[0];
            return (
              <div className='btn-group'>
                <Tooltip title={'Role'}>
                  <IconButton onClick={() => this.navigateToUpdateAuth(id)}>
                    <AddCircleIcon style={{ color: '#0372e5' }} />
                  </IconButton>
                </Tooltip>
              </div>
            );
          },
        },
      },
    ];
    const MapDataToDataTable = (data1) => {
      return data1.map((item) => {
        return [item._id, item.nameRole, null, null];
      });
    };
    const options = {
      filterType: 'dropdown',
      responsive: 'scrollMaxHeight',
      disableToolbarSelect: true,
      selectableRowsHeader: false,
      selectableRows: 'none',
      viewColumns: false, // close viewcolumns
      print: false, // close print
    };
    const data = [
      ['1', 'MX Role', 'Yonkers', 'NY'],
      ['2', 'Admin Role', 'Hartford', 'CT'],
      ['3', 'QA_Role', 'Tampa', 'FL'],
      ['4', 'Manager_Role', 'Dallas', 'TX'],
    ];
    return (
      <>
        <AlertDialog
          open={this.state.openConfirmDeleteRole}
          onClose={this.handleCloseConfirm}
          title='Do you want to delete this role?'
          okText='OK'
          cancelText='Cancel'
          onAccept={this.handleAcceptDeleleteRole}
          onCancel={this.handleCloseConfirm}
        />
        <ResultInfoDialog
          open={this.state.openResultDialog}
          onClose={this.handleCloseResultDialog}
          resultInfo={this.state.resultInfo}
          okText='OK'
          onAccept={this.handleCloseResultDialog}
          isOpSuccess={this.state.isDeleteGroupSuccess}
        />
        <MUIDataTable
          title={'Role'}
          data={MapDataToDataTable(this.state.roles)}
          columns={columns}
          options={options}
        />
      </>
    );
  }
}
