import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import './SignUp.scss';
import { Link } from 'react-router-dom';
import { serviceFactory } from '../../common/config/ServiceFactory';
class SignUp extends React.Component<any, any> {
  private readonly userService = serviceFactory.getUserService();
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      password: '',
      confirm_password: '',
      isError: false,
      errorMsg: '',
    };
  }
  onSubmit = (e) => {
    e.preventDefault();
    if (!this.state.email) {
      this.setState({ isError: true, errorMsg: 'Please enter your email' });
      return;
    }
    if (!this.state.password) {
      this.setState({ isError: true, errorMsg: 'Please enter your password' });
      return;
    }
    if (!this.state.confirm_password) {
      this.setState({
        isError: true,
        errorMsg: 'Please enter your confirm password',
      });
      return;
    }
    if (this.state.confirm_password !== this.state.password) {
      this.setState({
        isError: true,
        errorMsg: 'Passwords are different, please check again.',
      });
      return;
    }
    const body = {
      email: this.state.email,
      password: this.state.password,
    };
    this.userService.signup(body).subscribe((res) => {
      this.props.history.push('/');
    });
  };
  onChange = (evt) => {
    const value = evt.target.value;
    this.setState({
      ...this.state,
      [evt.target.name]: value,
    });
  };
  render = () => {
    return (
      <Container
        component='main'
        maxWidth='xs'
        style={{
          background: '#fff',
          borderRadius: 5,
        }}
      >
        <div
          className='paper'
          style={{
            paddingTop: 16,
            paddingBottom: 32,
            paddingLeft: 8,
            paddingRight: 8,
          }}
        >
          <Avatar className='avatar'>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component='h1' variant='h5'>
            Sign up
          </Typography>
          <form noValidate className='form' onSubmit={this.onSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  variant='outlined'
                  required
                  fullWidth
                  id='email'
                  label='Email Address'
                  name='email'
                  value={this.state.email}
                  onChange={this.onChange}
                  autoComplete='email'
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  variant='outlined'
                  required
                  fullWidth
                  name='password'
                  label='Password'
                  type='password'
                  id='password'
                  value={this.state.password}
                  onChange={this.onChange}
                  autoComplete='current-password'
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  variant='outlined'
                  required
                  fullWidth
                  name='confirm_password'
                  label='Confirm Password'
                  type='password'
                  id='confirm_password'
                  value={this.state.confirm_password}
                  onChange={this.onChange}
                  autoComplete='current-password'
                />
              </Grid>
            </Grid>
            {this.state.isError && (
              <p style={{ fontSize: 14, color: 'red' }}>
                {this.state.errorMsg}
              </p>
            )}
            <Button
              type='submit'
              fullWidth
              variant='contained'
              color='secondary'
              className='submitUp'
            >
              Sign Up
            </Button>
            <Grid container justify='flex-end'>
              <Grid item>
                <Link to='/' variant='body2' color='textSecondary'>
                  Already have an account? Sign in
                </Link>
              </Grid>
            </Grid>
          </form>
        </div>
      </Container>
    );
  };
}
export default SignUp;
