import { colors } from '@material-ui/core';

const white = '#FFFFFF';
const black = '#000000';
const backGroundColor = '#E5E5E5';
export default {
  black,
  white,
  backGroundColor,
  primary: {
    contrastText: black,
    dark: '#006db3',
    main: '#039be5',
    light: '#63ccff',
  },
  secondary: {
    contrastText: white,
    dark: '#78002e',
    main: '#ad1457',
    light: '#e35183',
  },
  success: {
    contrastText: white,
    dark: colors.green[900],
    main: colors.green[600],
    light: colors.green[400],
  },
  info: {
    contrastText: white,
    dark: colors.blue[900],
    main: colors.blue[600],
    light: colors.blue[400],
  },
  warning: {
    contrastText: white,
    dark: colors.orange[900],
    main: colors.orange[600],
    light: colors.orange[400],
  },
  error: {
    contrastText: white,
    dark: '#9f0000',
    main: '#d93025',
    light: '#ff674f',
  },
  text: {
    primary: '#000',
    secondary: 'rgba(34, 34, 34, 0.6)',
    link: '#009ADA',
    white: '#fff',
  },
  background: {
    default: backGroundColor,
    paper: white,
  },
  icon: '#009ADA',
  divider: '#C8C8C8',
};
