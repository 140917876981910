export function isValidDate(date) {
  if (Object.prototype.toString.call(date) === '[object Date]') {
    // it is a date
    if (isNaN(date.getTime())) {
      // d.valueOf() could also work
      return false;
    } else {
      return true;
    }
  }
  return false;
}
