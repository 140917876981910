import { catchError } from 'rxjs/operators';
import { WebClientUtil } from '../../util/WebClientUtil';
import { API_ENDPOINT } from '../../../../config';
import { Observable, of } from 'rxjs';
import { UserService } from './userService';
export class UserServiceImpl implements UserService {
  private baseUrl = API_ENDPOINT.versionTrackingURLSignInUp;
  signup(body: any): Observable<any> {
    const url = `${this.baseUrl}/signup`;
    return WebClientUtil.postObject(url, body);
  }
  signin(body: any): Observable<any> {
    const url = `${this.baseUrl}/signin`;
    return WebClientUtil.postObject(url, body);
  }
  GetallUser(): Observable<any> {
    const url = `${this.baseUrl}/api/user`; // dont forget to add /api
    return WebClientUtil.getObject(url);
  }
  getallUserwithGroupUser(): Observable<any> {
    const url = `${this.baseUrl}/api/user/getalluserwithgroup`; // dont forget to add /api
    return WebClientUtil.getObject(url);
  }
  updateGroupofUser(body: any): Observable<any> {
    const url = `${this.baseUrl}/api/user/updateGroupofUser`;
    return WebClientUtil.postObject(url, body);
  }
  deleteGroupFromUser(id: string): Observable<any> {
    const url = `${this.baseUrl}/api/user/deleteGroupFromUser/${id}`;
    return WebClientUtil.deleteObject(url);
  }
  deleteUser(id: string): Observable<any> {
    const url = `${this.baseUrl}/api/user/deleteUser/${id}`;
    return WebClientUtil.deleteObject(url);
  }
  changePassword(body: any): Observable<any> {
    const url = `${this.baseUrl}/api/user/changePassword`;
    return WebClientUtil.postObject(url, body);
  }
  forgotPassword(body: any): Observable<any> {
    const url = `${this.baseUrl}/password/forgot`;
    return WebClientUtil.postObject(url, body);
  }
  updatepassword(token: string, body: any): Observable<any> {
    const url = `${this.baseUrl}/update/updatepassword/${token}`;
    return WebClientUtil.postObject(url, body);
  }
  getEmailUserHasToolId(idtool: string): Observable<any> {
    const url = `${this.baseUrl}/api/user/tool/${idtool}`; 
    return WebClientUtil.getObject(url);
  }
}
