import React from 'react';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { Grid, Paper } from '@material-ui/core';
import { serviceFactory } from '../../common/config/ServiceFactory';
import { FunctionAuthRole } from './functionAuthRole';
import { connect } from 'react-redux';
class AuthRole extends React.Component<any, any> {
  private readonly roleService = serviceFactory.getRoleService();
  constructor(props) {
    super(props);
    this.state = {
      nameRole: '',
      toolofRoleArr: [],
    };
  }
  componentDidMount() {
    if (this.props.match.params && this.props.match.params.id) {
      const roleid = this.props.match.params.id;
      this.roleService.getOneRole(roleid).subscribe(res => {
        if (res.data.data) {
          this.setState({ nameRole: res.data.data.nameRole });
        }
      });
      this.roleService.getallToolGroup(roleid).subscribe(res => {
        this.setState({ toolofRoleArr: res.data.data });
      });
    }
  }
  onChange = e => {
    this.setState({});
  };

  onSubmit = e => {
    e.preventDefault();
  };

  onClear = () => {
    this.setState({});
  };

  navigateBack = () => {
    this.props.history.goBack();
  };

  render = () => {
    return (
      <div>
        <Paper elevation={4} style={{ marginBottom: 16, padding: 16 }}>
          <form noValidate autoComplete='off' onSubmit={this.onSubmit}>
            <div className='custom-buttoncreate'>
              <Typography variant='h4' gutterBottom>
                Update Role - {this.state.nameRole}
              </Typography>
            </div>
          </form>
        </Paper>
        <FunctionAuthRole
          {...this.props}
          toolofRoleArr={this.state.toolofRoleArr}
        />
      </div>
    );
  };
}
export default connect()(AuthRole);
