import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import './SignUp.scss';
import { serviceFactory } from '../../common/config/ServiceFactory';
import { CircularProgress } from '@material-ui/core';
class ForgotPass extends React.Component<any, any> {
  private readonly userService = serviceFactory.getUserService();
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      loading: false,
      isError: false,
      errorMsg: '',
      isSuccess: false,
    };
  }
  onSubmit = (e) => {
    e.preventDefault();
    if (!this.state.email) {
      this.setState({
        isError: true,
        errorMsg: 'Please enter your email',
      });
      return;
    }
    this.setState({
      loading: true,
    });
    const body = {
      email: this.state.email,
    };
    // call forgotPassword api here
    this.userService.forgotPassword(body).subscribe((res) => {
      if (res.response && res.response.data.error) {
        this.setState({
          isError: true,
          isSuccess: false,
          loading: false,
          errorMsg: res.response.data.error,
        });
      } else {
        this.setState({
          email: '',
          loading: false,
          isSuccess: true,
          isError: false,
          errorMsg:
            'Please check your email inbox, including SPAM and junk folders for a "Reset Password".',
        });
      }
    });
  };
  onChange = (evt) => {
    const value = evt.target.value;
    this.setState({
      ...this.state,
      [evt.target.name]: value,
    });
  };
  render = () => {
    return (
      <Container component='main' maxWidth='xs'>
        <div className='paper'>
          <Avatar className='avatar'>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component='h1' variant='h5'>
            Forgot Password
          </Typography>
          <form noValidate className='form' onSubmit={this.onSubmit}>
            {this.state.loading && (
              <CircularProgress
                size={24}
                style={{ marginLeft: 15, position: 'relative', top: 4 }}
              />
            )}
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  variant='outlined'
                  required
                  fullWidth
                  id='email'
                  label='Email Address'
                  name='email'
                  value={this.state.email}
                  onChange={this.onChange}
                  autoComplete='email'
                />
              </Grid>
            </Grid>
            {this.state.isError && (
              <p
                style={{
                  fontSize: 14,
                  color: 'red',
                  paddingLeft: 30,
                  paddingTop: 20,
                }}
              >
                {this.state.errorMsg}
              </p>
            )}
            {this.state.isSuccess && (
              <p
                style={{
                  fontSize: 14,
                  color: '#039be5',
                  paddingLeft: 30,
                  paddingTop: 20,
                }}
              >
                {this.state.errorMsg}
              </p>
            )}
            <Button
              type='submit'
              fullWidth
              variant='contained'
              color='secondary'
              className='submitUp'
            >
              Reset Password
            </Button>
          </form>
        </div>
      </Container>
    );
  };
}
export default ForgotPass;
