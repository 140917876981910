import { catchError } from 'rxjs/operators';
import { WebClientUtil } from '../../util/WebClientUtil';
import { API_ENDPOINT } from '../../../../config';
import { Observable, of } from 'rxjs';
import { GroupUserService } from './GroupUserService';
export class GroupUserServiceImpl implements GroupUserService {
  private baseUrl = API_ENDPOINT.versionTrackingURL;
  createGroupUser(body: any): Observable<any> {
    const url = `${this.baseUrl}/groupUser`;
    return WebClientUtil.postObject(url, body);
  }
  getallGroupUser(): Observable<any> {
    const url = `${this.baseUrl}/groupUser`;
    return WebClientUtil.getObject(url);
  }
  updateGroupUser(id: string, newModel: any) {
    const url = `${this.baseUrl}/groupUser/update/${id}`;
    return WebClientUtil.putObject(url, newModel);
  }
  getOneGroupUser(id: string) {
    const url = `${this.baseUrl}/groupUser/${id}`;
    return WebClientUtil.getObject(url);
  }
  deleteGroupUser(id: string): Observable<any> {
    const url = `${this.baseUrl}/groupUser/${id}`;
    return WebClientUtil.deleteObject(url);
  }
}
