import { catchError } from 'rxjs/operators';
import { WebClientUtil } from '../../util/WebClientUtil';
import { API_ENDPOINT } from '../../../../config';
import { Observable, of } from 'rxjs';
import { ToolComponentService } from './ToolComponentService';
export class ToolComponentServiceImpl implements ToolComponentService {
  private baseUrl = API_ENDPOINT.versionTrackingURL;
  createToolComp(body: any): Observable<any> {
    const url = `${this.baseUrl}/componentTool`;
    return WebClientUtil.postObject(url, body);
  }
  getallToolComp(): Observable<any> {
    const url = `${this.baseUrl}/componentTool`;
    return WebClientUtil.getObject(url);
  }
  deleteToolComp(id: string): Observable<any> {
    const url = `${this.baseUrl}/componentTool/${id}`;
    return WebClientUtil.deleteObject(url);
  }
  getOneToolCompById(id: string): Observable<any> {
    const url = `${this.baseUrl}/componentTool/${id}`;
    return WebClientUtil.getObject(url);
  }
  deleteMultipleToolComp(arrayId: string[]): Observable<any> {
    const url = `${this.baseUrl}/componentTool/delete/DeleteMultiple`;
    return WebClientUtil.postObject(url, arrayId);
  }
  updateToolComp(id: string, newModel: any) {
    const url = `${this.baseUrl}/componentTool/${id}`;
    return WebClientUtil.putObject(url, newModel);
  }
}
