import { combineReducers } from 'redux';
import tool from './toolReducer';
import navBar from './horizontalNavbarReducer';
import group from './groupReducer';
const rootReducer = combineReducers({
  group,
  tool,
  navBar,
});

export default rootReducer;
